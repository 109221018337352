import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useMemo, useState } from "react"
import { DashboardMap, MailBundleWithRelations, Plan, ReportMap, UserMap } from "../../types/reportingtypes";
import { LoginSession, OrgInfo, User } from "../../types/transfertypes";
//@ts-ignore
import Select from "react-select"
import { getMailBundleHTML, getMailBundleStats } from "../../helpers/OrganizationAdministrationHelpers";
import { formatUnix } from "../../helpers/TimeHelpers";
import { RiDashboard2Line } from "react-icons/ri";
import { GoGraph } from "react-icons/go";
import { FaCalculator, FaFile, FaLink, FaTable, FaTh, FaUser, FaUsers, FaWindowMaximize } from "react-icons/fa";
import { batchDeleteMailbundles, deleteMailBundle, setActiveMultipleMailbundles, toggleMailBundleActive } from "../../actions/MailBundleActions";
import "../../css/organizationAdministration.css"
import { OrgItems, OrgItemsDashboard, OrgItemsReport } from "../../types/organizationtypes";
import MailBundleModal from "./MailBundleModal";
import GenericDialog from "../Dialogs/GenericDialog";
import ReactTooltip from "react-tooltip";
import MultiSelectBar from "../MultiSelectBar";
import { notifySuccess } from "../../helpers/NotificationManager";
import { copyToClipboard } from "../../helpers/GeneralHelpers";
import { MdOutlinePlayArrow, MdOutlinePlayDisabled } from "react-icons/md";
import AlertBanner from "../Alert/AlertBanner";

interface ReportingAdministrationProps {
    mailbundles: MailBundleWithRelations[];
    plans: Plan[];
    users: User[];
    consultantMap: {[key:number]:boolean}
    orgId: number;
    org: OrgInfo;
    dispatch: any;
    orgItems: OrgItems;
    user: LoginSession;
}
/* Mail bundles are split up into two categories/types, bundles and subscriptions.
    they are differentiated by the "self_subscription" property on the mail bundle object.
    Subscriptions are those that are created when a user chooses to subscribe via the subscribe button in the Dashboard/Insight view
*/
function ReportingAdministration(props:ReportingAdministrationProps){    
    const [dashboards, setDashboards] = useState<OrgItemsDashboard[]>([]);
    const [reports, setReports] = useState<OrgItemsReport[]>([])
    
    const [showMailBundleModal, setShowMailBundleModal] = useState<boolean>(false);
    const [selectedMailBundle, setSelectedMailBundle] = useState<MailBundleWithRelations | undefined>();
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState<boolean>(false);
    const [showInfoModal, setShowInfoModal] = useState<boolean>(false);
    const [showMultiDeleteConfirmation, setShowMultiDeleteConfirmation] = useState<boolean>(false);
    const [initialEditTab, setInitialEditTab] = useState<0 | 1 | 2 | 3>(0)

    const [sortConfig, setSortConfig] = useState<{direction: "asc" | "desc", key: keyof MailBundleWithRelations | "items" | "recipients"}>({direction: "desc", key: "id"})
    const [shownMailBundles, setShownMailBundles] = useState<MailBundleWithRelations[]>([])

    const [searchText, setSearchText] = useState<string>("");
    const [filteredMailBundles, setFilteredMailBundles] = useState<MailBundleWithRelations[]>([]);

    const [planFilter, setPlanFilter] = useState<"D" | "W" | "M" | "A">("A")
    const [userFilter, setUserFilter] = useState<number|undefined>()
    const [subscriptionTypeFilter, setSubscriptionTypeFilter] = useState<"BUNDLE" | "SUBSCRIPTION">(props.mailbundles.some(mb => !mb.self_subscription) ? "BUNDLE" : "SUBSCRIPTION")

    const [checkedRows, setCheckedRows] = useState<number[]>([])
    const [selectAll, setSelectAll] = useState<boolean>(false);
    let checkedBundleText = checkedRows.length > 1 ? "bundles" : "bundle"
    const multiSelectOptions = [
        {
            label: (
                <span className="text-danger">
                    <i className="fa fa-trash h5 mb-0 mr-1" aria-hidden="true" title="Delete bundle"></i>
                    Delete <strong>{checkedRows.length}</strong> {checkedBundleText}
                </span>
            ),
            onClick: () => setShowMultiDeleteConfirmation(true),
        },
    ]
    if(shownMailBundles.filter(mb => checkedRows.includes(mb.id)).filter(mb => mb.active).length > 0){
        multiSelectOptions.splice(0, 0, {
            label: (
                <span>
                    <MdOutlinePlayDisabled className="inline-block mr-1"/>
                    Deactivate <strong>{shownMailBundles.filter(mb => checkedRows.includes(mb.id)).filter(mb => mb.active).length}</strong> {checkedBundleText}
                </span>
            ),
            onClick: () => setActiveMultiple(checkedRows, false),
        })
    }
    if(shownMailBundles.filter(mb => checkedRows.includes(mb.id)).filter(mb => !mb.active).length > 0){
        multiSelectOptions.splice(0, 0, {
            label: (
                <span>
                    <MdOutlinePlayArrow className="inline-block mr-1"/>
                    Activate <strong>{shownMailBundles.filter(mb => checkedRows.includes(mb.id)).filter(mb => !mb.active).length}</strong> {checkedBundleText}
                </span>
            ),
            onClick: () => setActiveMultiple(checkedRows, true),
        })
    }

    const reportMap = useMemo(() => 
        props.orgItems.reports.reduce((acc: ReportMap, cur) => { acc[cur.report_id] = cur; return acc }, {})
    , [props.orgItems])
    const userMap = useMemo(() => 
        props.users.reduce((acc:UserMap, cur) => { acc[cur.id] = cur; return acc}, {})
    , [props.users])
    const dashboardMap = useMemo(() => 
        props.orgItems.dashboards.reduce((acc: DashboardMap, cur) => { acc[cur.id] = cur; return acc }, {})
    , [props.orgItems])

    const insightViewMap = useMemo(() => {
        const map = new Map<string, OrgItemsReport[]>()
        const keys = ["trend", "matrix", "kpi", "sum", "profit"]
        if(!selectedMailBundle) return map;
        keys.forEach(k => {
            map.set(k, selectedMailBundle.insight_views.filter(iv => iv.type === k).map(iv => reportMap[iv.report_id]))
        })
        return map
    }, [reportMap, selectedMailBundle])
    
    const sortedTableData = useMemo(() => {
        let sortableTableData = [...shownMailBundles].filter(mb => !mb.user_ids.every(id => props.consultantMap[id]))
        if (sortConfig !== null) {
            sortableTableData.sort((a, b) => {
                let result = 0
                if(sortConfig.key === "items"){
                    result = getTotalItemsAmount(a) - getTotalItemsAmount(b)
                } else if (sortConfig.key === "recipients"){
                    result = a.user_ids.length - b.user_ids.length
                } else if (typeof a[sortConfig.key] === "number" && typeof b[sortConfig.key] === "number") {
                    result = Number(a[sortConfig.key]) - Number(b[sortConfig.key])
                } else if (a[sortConfig.key] === ""){
                    result = 1
                } else if (b[sortConfig.key] === ""){
                    result = -1
                } else {
                    result = String(a[sortConfig.key]).localeCompare(String(b[sortConfig.key]), undefined, { numeric: true, sensitivity: "base" })
                }
                return sortConfig.direction === "asc" ? result : -result
            })
        }
        return sortableTableData
    }, [sortConfig, shownMailBundles])

    useEffect(() => {
        setCheckedRows([])
        setSelectAll(false)
        setShownMailBundles(props.mailbundles)
        setFilteredMailBundles(props.mailbundles)
        if(!props.mailbundles.some(mb => mb.self_subscription)){
            setSubscriptionTypeFilter("BUNDLE")
        } else if(!props.mailbundles.some(mb => !mb.self_subscription)){
            setSubscriptionTypeFilter("SUBSCRIPTION")
        }
    }, [props.mailbundles])

    useEffect(() => {
        search(searchText)
    }, [filteredMailBundles, searchText])

    useEffect(() => {
        setCheckedRows([])
        setSelectAll(false)
        filterMailBundles(props.mailbundles)
    }, [planFilter, userFilter, subscriptionTypeFilter, props.mailbundles])

    useEffect(() => {
        setDashboards(props.orgItems.dashboards)        
        setReports(props.orgItems.reports)
    }, [props.orgItems])

    function filterMailBundles(mailBundles : MailBundleWithRelations[]){
        if(mailBundles.length === 0) return;

        let filtered = [...mailBundles]
        switch(planFilter){
            case "D":
            case "W":
            case "M":
                filtered = filtered.filter(m => m.plan.startsWith(planFilter))
                setFilteredMailBundles(filtered)
                break;
            case "A":
            default:
                // do nothing
        }
        switch(subscriptionTypeFilter){
            case "BUNDLE":
                filtered = filtered.filter(m => !m.self_subscription)
                break;
            case "SUBSCRIPTION":
                filtered = filtered.filter(m => m.self_subscription)
                break;
            default:
                //do nothing
        }
        if(userFilter){
            filtered = filtered.filter(m => m.user_ids.includes(userFilter))
        }
        setFilteredMailBundles(filtered)
        if(!searchText) setShownMailBundles(filtered)
    }

    const onEdit = (m : MailBundleWithRelations, initialTab?:0|1|2|3) => {
        if(initialTab){
            setInitialEditTab(initialTab)
        } else{
            setInitialEditTab(0)
        }
        setSelectedMailBundle(m)
        setShowMailBundleModal(true);
    }

    const requestSort = (key: keyof MailBundleWithRelations | "items" | "recipients") => {
        let direction : "asc" | "desc" = "asc"
        if (sortConfig && sortConfig.key === key && sortConfig.direction === "asc") {
            direction = "desc"
        }
        setSortConfig({ key, direction })
    }

    const getSortDirectionIcon = (key: keyof MailBundleWithRelations | "items" | "recipients") => {
        if (sortConfig && sortConfig.key === key) {
            return sortConfig.direction === "asc" ? "fa fa-sort-asc ml-1" : "fa fa-sort-desc ml-1"
        }
        return ""
    }

    const handleCheck = (event:ChangeEvent<HTMLInputElement>, mb: MailBundleWithRelations) => {
        if (event.target.checked) {
            setCheckedRows([...checkedRows, mb.id])
        } else {
            setCheckedRows(checkedRows.filter((id) => id !== mb.id))
        }
    }
    const handleSelectAll = (event:ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            setCheckedRows(shownMailBundles.map((mb) => mb.id))
            setSelectAll(true)
        } else {
            setCheckedRows([])
            setSelectAll(false)
        }
    }

    const search = (query:string) => {
        if (!query || query.length < 1) {
            setShownMailBundles(filteredMailBundles)
            return
        }

        const filtered = filteredMailBundles.filter(searchFilterFunc)
        setShownMailBundles(filtered)
    }

    const searchFilterFunc = (m: MailBundleWithRelations) => {
        const nameMatch = m.name.toLowerCase().includes(searchText.toLowerCase())
        let recipientsMatch = m.user_ids
            .map(id => props.users.find(u => u.id === id))
            .filter(u => !!u && !props.consultantMap[u.id])
            .some(u => (u?.firstname + " " + u?.lastname).toLowerCase().includes(searchText.toLowerCase()))
        
        let reportsMatch = false;
        let dashboardsMatch = false;
        if(!!reportMap && !!dashboardMap){
            reportsMatch = [...m.insights.map(i => i.report_id), ...m.insight_views.map(iv => iv.report_id)]
                .map(id => reportMap[id])
                .filter(r => !!r)
                .some(r => r?.name.toLowerCase().includes(searchText.toLowerCase()))
            
            dashboardsMatch = m.dashboards
                .map(d => dashboardMap[d.dashboard_id])
                .filter(d => !!d)
                .some(d => d.title.toLowerCase().includes(searchText.toLowerCase()))
        }       
        return nameMatch || recipientsMatch || reportsMatch || dashboardsMatch
    }

    const onToggleActive = (m: MailBundleWithRelations) => {
        props.dispatch(toggleMailBundleActive(m))
    }

    const setActiveMultiple = (ids: number[], activate: boolean) => {
        props.dispatch(setActiveMultipleMailbundles(ids, activate, props.orgId))
    }

    const getPlan = (plan_id: number): string => {
        var plan = props.plans.find(x => x.id === plan_id);
        if(plan !== undefined)
            return plan.description.substring(4).replace("on the", "")
        else
            return "??"
    }

    const onDelete = (mb: MailBundleWithRelations) => {
        props.dispatch(deleteMailBundle(mb))
    }

    const multiDelete = (mbs: MailBundleWithRelations[]) => {
        const ids = mbs.map(m => m.id)
        props.dispatch(batchDeleteMailbundles(ids, props.orgId))
        setCheckedRows([])
    }

    function getTotalItemsAmount(mb: MailBundleWithRelations) {
        return mb.dashboards.length + mb.insight_views.length + mb.insights.length
    }

    function copyBundlesToClipboard() {
        const bundlesToCopy = checkedRows.length > 0 ? sortedTableData.filter(mb => checkedRows.includes(mb.id)) : sortedTableData;
        copyToClipboard(getMailBundleHTML(bundlesToCopy, userMap, dashboardMap, reportMap, props.plans, subscriptionTypeFilter === "SUBSCRIPTION"));
        notifySuccess(bundlesToCopy.length + ' users has been copied to the clipboard')
    }

    return (
        <div className="org-admin-wrapper w-100 mb-5">
            <ReportingFilterBar
                items={props.mailbundles.filter(mb => !mb.user_ids.every(id => props.consultantMap[id]))}
                shownItems={sortedTableData}
                users={props.users}
                searchText={searchText}
                setSearchText={setSearchText}
                selectedPlan={planFilter}
                setSelectedPlan={setPlanFilter}
                selectedType={subscriptionTypeFilter}
                setSelectedType={setSubscriptionTypeFilter}
                userFilter={userFilter}
                setUserFilter={setUserFilter}
                setShowAddModal={setShowMailBundleModal}
                stats={getMailBundleStats(props.mailbundles.filter(m => subscriptionTypeFilter === "BUNDLE" ? !m.self_subscription : m.self_subscription), props.plans)}
                copyToClipboard={copyBundlesToClipboard}
                checkedRowsCount={checkedRows.length}
                org={props.org}
            />               
            <MultiSelectBar 
                selected={checkedRows} 
                options={multiSelectOptions} 
                type="mailbundle" 
                onDeselectClick={() => {setCheckedRows([]); setSelectAll(false)}}
            />
            <table className="org-mailbundle-table">
                <thead>
                    <tr className="org-mailbundle-tr">
                        <th className="org-mailbundle-th text-center">
                            <label className="org-admin-checkbox">
                                <input 
                                    className="org-admin-input" 
                                    type="checkbox" 
                                    checked={selectAll} 
                                    onChange={(event) => handleSelectAll(event)} 
                                />
                                <span></span>
                            </label>
                        </th>
                        <th className="org-mailbundle-th" onClick={() => requestSort("name")}>
                            {subscriptionTypeFilter === "BUNDLE" ? "Bundle Name" : "Subscription Name"}
                            <i className={getSortDirectionIcon("name")}></i>
                        </th>
                        <th className="org-mailbundle-th">{/* INFO BUTTON COLUMN */}</th>
                        <th className="org-mailbundle-th" onClick={() => requestSort("recipients")} style={{width: 125}}>
                            Recipients
                            <i className={getSortDirectionIcon("recipients")}></i>
                        </th>
                        <th className="org-mailbundle-th" onClick={() => requestSort("plan")}>
                            Plan
                            <i className={getSortDirectionIcon("plan")}></i>
                        </th>
                        <th className="org-mailbundle-th" onClick={() => requestSort("last_sent")} style={{width:150}}>
                            Last sent
                            <i className={getSortDirectionIcon("last_sent")}></i>
                        </th>
                        <th className="org-mailbundle-th text-center" onClick={() => requestSort("active")} style={{width: 125}}>
                            Status
                            <i className={getSortDirectionIcon("active")}></i>
                        </th>
                        <th className="org-mailbundle-th px-0" style={{width: 50}}>{/* DELETE BUTTON COLUMN */}</th>
                    </tr>
                </thead>
                <tbody className="">
                    {sortedTableData.length === 0 && <tr>
                            <td className="org-mailbundle-td text-center text-secondary font-italic" colSpan={8}>No {subscriptionTypeFilter === "BUNDLE" ? "bundles" : "subscriptions"} found</td>
                        </tr>
                    }
                    {userMap && sortedTableData.map((m, i) => {
                        return <tr key={i} className={`${m.active ? "" : "org-mailbundle-inactive"} org-mailbundle-tr`}>
                            <td className="org-mailbundle-td text-center">
                                <label className="org-admin-checkbox">
                                    <input 
                                        className="org-admin-input" 
                                        type="checkbox" 
                                        checked={checkedRows.includes(m.id)} 
                                        onChange={(event) => handleCheck(event, m)} 
                                    />
                                    <span></span>
                                </label>
                            </td>
                            <td className="org-mailbundle-td"  onClick={() => onEdit(m)} style={{textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden"}} title={m.name}>{m.name}</td>
                            <td className="org-mailbundle-td text-center">
                                <i className="fa fa-info-circle" aria-hidden="true" title="Click to see more details" onClick={() => {setSelectedMailBundle(m); setShowInfoModal(true)}}></i>
                            </td>
                            <td className="org-mailbundle-td text-center" data-tip data-for={"mailbundleUsers"+m.id} onClick={() => onEdit(m, 2)}>
                                <span className="font-weight-bold mr-1">{m.user_ids.filter(id => !props.consultantMap[id]).length}</span>
                                <FaUser className="text-primary"/>
                                <ReactTooltip id={"mailbundleUsers"+m.id} place="bottom">
                                    {userMap && m.user_ids.filter(id => !props.consultantMap[id]).slice(0, 11).map((id, i) => {
                                        const u = userMap[id]
                                        if(i >= 10){
                                            return (<p key={i} className="font-italic font-weight-bold text-left">And {m.user_ids.length - 10} more...</p>)
                                        }
                                        return (
                                            <p
                                                key={i}
                                                className="text-left"
                                            >
                                                {u.firstname + " " + u.lastname}
                                            </p>
                                        )
                                    })}
                                </ReactTooltip>
                            </td>
                            <td className="org-mailbundle-td"  onClick={() => onEdit(m, 1)}>{getPlan(m.plan_id ?? -1)}</td>
                            <td className="org-mailbundle-td"  onClick={() => onEdit(m)}>{m.last_sent !== 0 ? formatUnix(m.last_sent) : "-"}</td>
                            <td className="text-center org-mailbundle-td">
                                <div className="d-flex w-100 justify-content-center">
                                    <div  
                                        title={`${m.active ? "Deactivate" : "Activate"}`} 
                                        onClick={() => onToggleActive(m)}
                                        className={`${m.active ? "btn-primary" : "btn-secondary"} border rounded-pill w-100`}
                                    >
                                        {m.active ? "Activated" : "Activate"}
                                    </div>
                                </div>
                            </td>
                            <td className="org-mailbundle-td text-center">
                                <i className="fa fa-trash h5 mb-0" aria-hidden="true" title="Delete bundle" onClick={() => {setSelectedMailBundle(m); setShowDeleteConfirmation(true)}}></i>
                            </td>
                        </tr>
                    })}
                </tbody>
            </table>
            {showMailBundleModal &&
                <MailBundleModal 
                    orgId={props.orgId} 
                    users={props.users}
                    consultantMap={props.consultantMap}
                    dashboards={dashboards} 
                    reports={reports} 
                    onClose={() => {setShowMailBundleModal(false); setSelectedMailBundle(undefined)}}
                    open={showMailBundleModal}
                    plans={props.plans}
                    selectedMailBundle={selectedMailBundle}
                    use_access_control={props.org?.use_access_control ?? false}
                    location_enabled={true}
                    dispatch={props.dispatch}
                    initialPage={selectedMailBundle ? initialEditTab : 0}
                    user={props.user}
                    setSubscriptionTypeFilter={setSubscriptionTypeFilter}
                    dashboardsMap={dashboardMap}
                    reportMap={reportMap}
                />
            }
            {showDeleteConfirmation && selectedMailBundle &&
                <DeleteConfirmation
                    mailBundle={selectedMailBundle}
                    onClose={() => {setShowDeleteConfirmation(false); setSelectedMailBundle(undefined)}}
                    onDelete={onDelete}
                />
            }
            {showInfoModal && selectedMailBundle && dashboardMap && reportMap && insightViewMap &&
                <MailBundleInfoModal
                    mailBundle={selectedMailBundle}
                    onClose={() => {setShowInfoModal(false); setSelectedMailBundle(undefined)}}
                    show={showInfoModal}
                    dashboards={selectedMailBundle.dashboards.map(d => dashboardMap[d.dashboard_id])}
                    reports={selectedMailBundle.insights.map(ins => reportMap[ins.report_id])}
                    insightViewMap={insightViewMap}
                />
            }
            {showMultiDeleteConfirmation && checkedRows.length > 0 &&
                <MultiDeleteConfirmation
                    mailBundles={props.mailbundles.filter(m => checkedRows.includes(m.id))}
                    onClose={() => setShowMultiDeleteConfirmation(false)}
                    onDelete={multiDelete}
                />
            }
        </div>)
}

interface ReportingFilterBarProps{
    items: MailBundleWithRelations[]
    stats: ReportingStats
    selectedPlan: "D" | "W" | "M" | "A"
    setSelectedPlan: Dispatch<SetStateAction<"D" | "W" | "M" | "A">>
    selectedType: "BUNDLE" | "SUBSCRIPTION";
    setSelectedType: Dispatch<SetStateAction<"BUNDLE" | "SUBSCRIPTION">>
    users: User[]
    userFilter: number | undefined
    setUserFilter: Dispatch<SetStateAction<number |undefined>>
    setShowAddModal: Dispatch<SetStateAction<boolean>>
    shownItems: MailBundleWithRelations[]
    searchText: string
    setSearchText: Dispatch<SetStateAction<string>>
    buttonColor?: "dashboard" | "dashboardtable" |"trendview" | "report";
    copyToClipboard: () => void;
    checkedRowsCount: number
    org: OrgInfo
}

function ReportingFilterBar(props:ReportingFilterBarProps) {
    const subscriptionCount = props.items.filter(m => m.self_subscription).length
    const bundleCount = props.items.filter(m => !m.self_subscription).length
    const subscriptionShownCount = props.shownItems.filter(m => m.self_subscription).length
    const bundleShownCount = props.shownItems.filter(m => !m.self_subscription).length
    return (
        <div className="d-flex justify-content-between align-items-end mb-2 w-100">
            <div className="d-flex flex-column">
                {(subscriptionCount > 0 && props.org.self_subscription_enabled && bundleCount > 0) && 
                    <div className="d-flex flex-row align-items-end">
                        <SubscriptionTypeTabs
                            bundleCount={bundleCount}
                            subscriptionCount={subscriptionCount}
                            selectedType={props.selectedType}
                            setSelectedType={props.setSelectedType}
                            />
                    </div>
                }
                {!props.org.self_subscription_enabled && props.selectedType === "SUBSCRIPTION" && <AlertBanner
                    config={{
                        text : <span><br/>
                            Users can not sign up for new personal subscriptions through <strong>Inact Now</strong>. <br/>
                            To enable personal subscription, contact <a href="mailto:support@inact.io">support@inact.io</a>
                        </span>,
                        title : "Personal subscription is disabled for your organization",
                        type: "warning"
                    }}
                />} 
                <div className="d-flex flex-row align-items-center mb-2">
                    <span className="font-weight-bold">
                        Filters:
                    </span>
                    <div className="d-flex flex-row ml-4">
                        <ReportingItemStats
                            stats={props.stats}
                            selectedPlan={props.selectedPlan}
                            setSelectedPlan={props.setSelectedPlan}
                        />
                    </div>
                    <div className="d-flex align-items-center ml-4">
                        <div style={{width: 200}}>
                            <Select
                                isClearable={true}
                                value={
                                    props.users
                                        .filter(u => !u.deleted)
                                        .map(u => ({
                                            value: u.id,
                                            label: u.firstname + " " + u.lastname
                                        }))
                                        .find(o => o.value === props.userFilter)
                                }
                                options={props.users
                                    .filter(u => !u.deleted)
                                    .sort((a, b) => (a.firstname + " " + a.lastname).localeCompare(b.firstname + " " + b.lastname))
                                    .map(u => ({
                                        value: u.id ?? -1,
                                        label: u.firstname + " " + u.lastname
                                    }))
                                }
                                onChange={(o: { label: string, value: any }) => props.setUserFilter(o?.value)}
                                isSearchable={true}
                                placeholder="Select recipient"
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex flex-column align-items-end">
                <div className="d-flex align-items-center">
                    <button 
                        className="btn btn-default mb-2 mr-2" 
                        onClick={() => props.copyToClipboard()} 
                        title={props.checkedRowsCount > 0 ? ` Copy ${props.checkedRowsCount} ${props.selectedType === "BUNDLE" ? "bundles" : "subscriptions"} to clipboard`:"Copy to clipboard"}
                    >
                        <i className="fa fa-copy"></i>
                        {props.checkedRowsCount > 0 ? ` (${props.checkedRowsCount})`:""}
                    </button>
                    <button className="btn btn-primary mb-2" onClick={() => props.setShowAddModal(true)}>
                        <i className="fa fa-plus"></i> New Mail Bundle
                    </button>
                </div>
                <div className="d-flex align-items-center">
                    <div className="text-muted mr-2 w-50">
                        {props.selectedType === "BUNDLE" && <span>Showing {bundleShownCount} of {bundleCount}</span>}
                        {props.selectedType === "SUBSCRIPTION" && <span>Showing {subscriptionShownCount} of {subscriptionCount}</span>}
                    </div>
                    <label htmlFor="org-mailbundle-search-input" className="org-admin-label d-flex">
                        <input
                            id="org-mailbundle-search-input"
                            className="org-admin-search-field org-admin-text-input"
                            type="search"
                            placeholder="Search by Name, Recipient or Insight"
                            value={props.searchText}
                            onChange={e => props.setSearchText(e.target.value)}
                            style={{width: 285}}
                        />
                        <span className="btn org-admin-search-button">
                            <i className="fa fa-search"></i>
                        </span>
                    </label>
                </div>
            </div>
        </div>
    );
}

interface ReportingStats {
    daily: number;
    weekly: number;
    monthly: number;
}

interface ReportingItemStatsProps{
    stats: ReportingStats,
    selectedPlan?: "D" | "W" | "M" | "A"
    setSelectedPlan?: Dispatch<SetStateAction<"D" | "W" | "M" | "A">>
}

function ReportingItemStats({ stats, selectedPlan, setSelectedPlan }:ReportingItemStatsProps){
    return (
        <div className="d-flex flex-row align-items-center">
            <div className="d-flex flex-row border-left border-right border-secondary px-4">
                <div 
                    title={`${selectedPlan ? selectedPlan === "D" ? "Show All" : "Show Daily" : ""}`}
                    className={`${selectedPlan ? selectedPlan === "D" ? "bg-primary text-white" : "text-primary" : "text-primary"} h6 border border-primary rounded-pill py-1 px-2 mr-1 mb-0`}
                    onClick={() => setSelectedPlan ? selectedPlan === "D" ? setSelectedPlan("A") : setSelectedPlan("D") : null}
                    style={{ cursor: 'pointer' }}
                >
                    Daily: <span className={selectedPlan === "D" ? "" : "text-primary"}>{stats.daily}</span>
                </div>
                <div 
                    title={`${selectedPlan ? selectedPlan === "W" ? "Show All" : "Show Weekly" : ""}`}
                    className={`${selectedPlan ? selectedPlan === "W" ? "bg-success text-white" : "text-success" : "text-success"} h6 border border-success rounded-pill py-1 px-2 mr-1 mb-0`}
                    onClick={() => setSelectedPlan ? selectedPlan === "W" ? setSelectedPlan("A") : setSelectedPlan("W") : null}    
                    style={{ cursor: 'pointer' }}
                >
                    Weekly: <span className={selectedPlan === "W" ? "" : "text-success"}>{stats.weekly}</span>
                </div>
                <div 
                    title={`${selectedPlan ? selectedPlan === "M" ? "Show All" : "Show Monthly" : ""}`}
                    className={`${selectedPlan ? selectedPlan === "M" ? "bg-danger text-white" : "text-danger" : "text-danger"} h6 border border-danger rounded-pill py-1 px-2 mb-0`}
                    onClick={() => setSelectedPlan ? selectedPlan === "M" ? setSelectedPlan("A") : setSelectedPlan("M") : null}
                    style={{ cursor: 'pointer' }}
                >
                    Monthly: <span className={selectedPlan === "M" ? "" : "text-danger"}>{stats.monthly}</span>
                </div>
            </div>
        </div> 
    )
}

interface SubscriptionTypeTabsProps {
    selectedType: "BUNDLE" | "SUBSCRIPTION";
    setSelectedType: Dispatch<SetStateAction<"BUNDLE" | "SUBSCRIPTION">>
    bundleCount: number;
    subscriptionCount: number;
}

function SubscriptionTypeTabs(props:SubscriptionTypeTabsProps) {
    return (
        <div className="d-flex flex-row align-items-center mb-3 mt-2" style={{width: 450}}>
            <div className="d-flex flex-row border-secondary w-100">
                <div 
                    className={`${props.selectedType === "BUNDLE" ? "text-primary border-primary font-weight-bold" : "text-secondary"} h6 border-bottom py-1 w-100 mb-0 text-center pb-2`}
                    onClick={() => props.setSelectedType("BUNDLE")}
                    style={{ cursor: 'default', userSelect: "none" }}
                >
                    <FaUsers className="mr-1"/>
                    Bundles ({props.bundleCount})
                </div>
                <div 
                    className={`${props.selectedType === "SUBSCRIPTION" ? "text-primary border-primary font-weight-bold" : "text-secondary"} h6 border-bottom py-1 w-100 mb-0 text-center pb-2`}
                    onClick={() => props.setSelectedType("SUBSCRIPTION")}    
                    style={{ cursor: 'default', userSelect: "none" }}
                >
                    <FaUser className="mr-1 h6 mb-0"/>
                    Personal ({props.subscriptionCount})
                </div>
            </div>
        </div>
    )
}



interface DeleteConfirmationProps{
    mailBundle: MailBundleWithRelations
    onClose: () => void
    onDelete: (m: MailBundleWithRelations) => void
}

function DeleteConfirmation(props:DeleteConfirmationProps){
    return (<>
        <div 
            className="position-absolute bottom-0 left-0 right-0 mx-auto mt-5 z-40  rounded-lg shadow-lg border border-placeholder bg-white border-secondary p-4 d-flex flex-column justify-content-between" 
            style={{maxWidth: "800px",minHeight: '200px', maxHeight: "400px", zIndex: 9999, top: "25%"}}
        >
            <div className="d-flex align-items-center justify-content-center h5 mt-5" style={{whiteSpace: "nowrap"}}>
                <span>Are you sure you want to delete</span> <span className="font-weight-bold ml-2" style={{overflow: "hidden", textOverflow: "ellipsis"}} title={props.mailBundle.name}>{props.mailBundle.name}</span>?
            </div>
            <div className="d-flex w-100 align-items-end justify-content-end mt-4">
                <button className="btn btn-outline-secondary mr-2" onClick={props.onClose}>
                    Cancel
                </button>
                <button className="btn btn-danger mr-2" onClick={() => {props.onDelete(props.mailBundle); props.onClose()}}>
                    Delete
                </button>
            </div>
        </div>
        <div className="fade modal-backdrop show"></div>  
    </>)
}
interface MultiDeleteConfirmationProps{
    mailBundles: MailBundleWithRelations[]
    onClose: () => void
    onDelete: (ms: MailBundleWithRelations[]) => void
}

function MultiDeleteConfirmation(props:MultiDeleteConfirmationProps){
    return (<>
        <div 
            className="position-absolute bottom-0 left-0 right-0 mx-auto mt-5 z-40  rounded-lg shadow-lg border border-placeholder bg-white border-secondary p-4 d-flex flex-column justify-content-between" 
            style={{ width: '500px', minHeight: '200px', zIndex: 9999, top: "25%"}}
        >
            <div className="d-flex align-items-center justify-content-center h5 font-weight-bold">
                You are about to delete the following mail bundles
            </div>
            <div>
                {props.mailBundles.map((m, i) => (
                    <div
                        key={i}
                        style={{overflow: "hidden", textOverflow: "ellipsis", whiteSpace: "nowrap"}}
                        title={m.name}
                    >
                        {m.name}
                    </div>
                ))}
            </div>
            <div className="d-flex w-100 align-items-end justify-content-end mt-4">
                <button className="btn btn-outline-secondary mr-2" onClick={props.onClose}>
                    Cancel
                </button>
                <button className="btn btn-danger mr-2" onClick={() => {props.onDelete(props.mailBundles); props.onClose()}}>
                    Delete
                </button>
            </div>
        </div>
        <div className="fade modal-backdrop show"></div>  
    </>)
}


interface MailBundleInfoModalProps{
    show: boolean
    mailBundle: MailBundleWithRelations
    dashboards: OrgItemsDashboard[]
    reports: OrgItemsReport[]
    insightViewMap: Map<string, OrgItemsReport[]>
    onClose: () => void
}

function MailBundleInfoModal(props:MailBundleInfoModalProps){
    const trend = props.insightViewMap.get("trend")
    const matrix = props.insightViewMap.get("matrix")
    const kpi = props.insightViewMap.get("kpi")
    const sum = props.insightViewMap.get("sum")
    const profit = props.insightViewMap.get("profit")
    
    return (
        <GenericDialog 
            title={props.mailBundle.name} 
            show={props.show} 
            onClose={props.onClose} 
            getButtons={() => <div><button onClick={props.onClose} type="button" className="btn btn-default margin-right-10px" >Close</button></div>} 
            style={{minWidth: 400, maxWidth: 800}}
        >
            <div style={{maxHeight: 600, overflowY: "auto"}}>
                {props.dashboards.length > 0 && <div className="mb-4">
                    <span className="font-weight-bold p-1">
                        <RiDashboard2Line className="h5 mb-1 mr-1 text-primary" />
                        Dashboards
                    </span>
                    {props.dashboards.map((d, i) =>{
                        const reportingDashboard = props.mailBundle.dashboards.find(rd => rd.dashboard_id === d.id)
                        const include_widgets = reportingDashboard?.include_widgets
                        const include_table = reportingDashboard?.include_table
                        return (<div
                            key={i}
                            className="d-flex w-100 border-bottom p-1"
                        >
                            <span className="w-100">{d.title}</span>
                            {!include_widgets && <span className="font-italic text-muted w-100">Table only</span>}
                            {!include_table && <span className="font-italic text-muted w-100">Widgets only</span>}
                        </div>)
                    })}
                </div>}
                {props.reports.length > 0 && <div className="mb-4">
                    <span className="font-weight-bold p-1">
                        <FaFile className="mr-1 mb-1 text-primary"/>
                        Insights
                    </span>
                    {props.reports.map((r, i) =>
                        <div
                            key={i}
                            className="border-bottom p-1"
                        >
                            {r?.name ?? "Private Insight"}
                        </div>
                    )}
                </div>}
                {!!trend && trend.length > 0 && <div className="mb-4">
                    <span className="font-weight-bold p-1">
                        <GoGraph className="h5 mb-1 mr-1 text-primary"/>
                        Trend
                    </span>
                    {trend.map((r, i) =>
                        <div
                            key={i}
                            className="border-bottom p-1"
                        >
                            {r?.name ?? "Private Insight"}
                        </div>
                    )}
                </div>}
                {!!matrix && matrix.length > 0 && <div className="mb-4">
                    <span className="font-weight-bold p-1">
                        <FaTh className="h6 mb-1 mr-1 text-primary"/>
                        Matrix
                    </span>
                    {matrix.map((r, i) =>
                        <div
                            key={i}
                            className="border-bottom p-1"
                        >
                            {r?.name ?? "Private Insight"}
                        </div>
                    )}
                </div>}
                {!!kpi && kpi.length > 0 && <div className="mb-4">
                    <span className="font-weight-bold p-1">
                        <FaWindowMaximize className="h6 mb-1 mr-1 text-primary"/>
                        KPI
                    </span>
                    {kpi.map((r, i) =>
                        <div
                            key={i}
                            className="border-bottom p-1"
                        >
                            {r?.name ?? "Private Insight"}
                        </div>
                    )}
                </div>}
                {!!sum && sum.length > 0 && <div className="mb-4">
                    <span className="font-weight-bold p-1">
                        <FaTable className="h6 mb-1 mr-1 text-primary"/>
                        Sum
                    </span>
                    {sum.map((r, i) =>
                        <div
                            key={i}
                            className="border-bottom p-1"
                        >
                            {r?.name ?? "Private Insight"}
                        </div>
                    )}
                </div>}
                {!!profit && profit.length > 0 && <div className="mb-4">
                    <span className="font-weight-bold p-1">
                        <FaCalculator className="h6 mb-1 mr-1 text-primary"/>
                        Profit
                    </span>
                    {profit.map((r, i) =>
                        <div
                            key={i}
                            className="border-bottom p-1"
                        >
                            {r?.name ?? "Private Insight"}
                        </div>
                    )}
                </div>}
            </div>
            <div className="">
                <FaLink className="mr-1 text-primary"/>
                {props.mailBundle.link && <span>Links to Now included in mail</span>} 
                {!props.mailBundle.link && <span> Links to Now <strong>not</strong> included in mail</span>}
            </div>
        </GenericDialog>
    )
}


export default ReportingAdministration