import React, { useEffect, useMemo, useState } from "react"
import GenericDialog from "../Dialogs/GenericDialog"
import { Checklist, ChecklistColumn, ChecklistStatus, Column, DataQuery } from "../../types/transfertypes";
//@ts-ignore
import Select from "react-select"
import ShowIf from "../Generic/ShowIf";
import { mapColumnTypes, SelectOption } from "../../helpers/TypeHelpers";
import { MissingColumn } from "../../types/types";

interface FixActionDialogProps {
    show: boolean;
    actionStatus: ChecklistStatus
    onClose: () => void
    onSave: (action: Checklist, update: boolean) => void
    allColumns: Column[]
    duringCreation?: boolean
}

function FixActionDialog(props:FixActionDialogProps) {
    const [tmpAction, setTmpAction] = useState<Checklist>(structuredClone(props.actionStatus.list))
    const [removedMissingColumns, setRemovedMissingColumns] = useState<string[]>([])
    const [selectedReplacementColumnMap, setSelectedReplacementColumnMap] = useState<{[key:string]:Column}>({})
    const [replacedMissingColumns, setReplacedMissingColumns] = useState<string[]>([])

    const alreadySelectedReplacements = useMemo(() => {
        return Object.values(selectedReplacementColumnMap).map(c => c.name)
    }, [selectedReplacementColumnMap])

    const shownMissingColumns = useMemo(() => 
        props.actionStatus.missing_columns.filter(c => !removedMissingColumns.includes(c.name) && !replacedMissingColumns.includes(c.name)) ?? []
    , [removedMissingColumns, replacedMissingColumns, props.actionStatus.missing_columns])

    // Converting data_columns to array of strings as that is how api wants update data
    useEffect(() => {
        const tmp = structuredClone(props.actionStatus.list)
        const dataColumns:string[] = JSON.parse(tmp.data_columns).map((dc:ChecklistColumn) => dc.Name)
        tmp.data_columns = JSON.stringify(dataColumns)
        setTmpAction(tmp)
    }, [props.actionStatus])
    
    
    const columns = useMemo(() => {
        const actionDataColumns:ChecklistColumn[] = JSON.parse(props.actionStatus.list.data_columns)
        const actionColumnNames = actionDataColumns.map(c => c.Name)
        return props.allColumns.filter(c => !actionColumnNames.includes(c.name))
    }, [props.allColumns, props.actionStatus])
    
    const disableSave = useMemo(() => {
        return props.actionStatus.missing_columns.some(c => {
            const dataColumns: string[] = JSON.parse(tmpAction.data_columns)
            const shownColumns: string[] = JSON.parse(tmpAction.shown_columns)
            const query: DataQuery = JSON.parse(tmpAction.filter)
            
            if(tmpAction.sort_column === c.name) return true;
            if(tmpAction.selected_kpi === c.name) return true;
            if(dataColumns.includes(c.name)) return true;
            if(shownColumns.includes(c.name)) return true;
            if(query.column_filter.includes(c.name)) return true;
            if(query.sort_column === c.name) return true;
            if(query.filter.some(f => f.column === c.name)) return true;
            if(query.filter.some(f => f.is_column && f.target_values?.length > 0 && f.target_values[0] === c.name)) return true;
            return false
        })
    }, [tmpAction, props.actionStatus])

    const handleRemoveColumn = (c: MissingColumn) => {
        const updated = structuredClone(tmpAction)

        const dataColumns: string[] = JSON.parse(updated.data_columns)
        updated.data_columns = JSON.stringify(dataColumns.filter(col => col !== c.name))

        const shownColumns: string[] = JSON.parse(updated.shown_columns)
        updated.shown_columns = JSON.stringify(shownColumns.filter(col => col !== c.name))

        const query: DataQuery = JSON.parse(updated.filter)
        query.column_filter = query.column_filter.filter(col => col !== c.name)
        updated.filter = JSON.stringify(query)

        updated.selected_kpi = updated.selected_kpi === c.name ? "" : updated.selected_kpi

        setTmpAction(updated)
        setRemovedMissingColumns([...removedMissingColumns, c.name])
    }

    const handleReplaceColumn = (c: MissingColumn, replacement: Column | undefined) => {
        if(!replacement) return;

        const updated = structuredClone(tmpAction)
        const dataColumns: string[] = JSON.parse(updated.data_columns)
        updated.data_columns = JSON.stringify(dataColumns.map(col => col === c.name ? replacement.name : col ))

        const shownColumns: string[] = JSON.parse(updated.shown_columns)
        updated.shown_columns = JSON.stringify(shownColumns.map(col => col === c.name ? replacement.name : col))
        
        const query: DataQuery = JSON.parse(updated.filter)
        query.column_filter = query.column_filter.map(col => col === c.name ? replacement.name : col)
        query.sort_column = query.sort_column === c.name ? replacement.name : query.sort_column
        query.filter = query.filter.map(crit => {
            let tmpCrit = structuredClone(crit)
            if(crit.column === c.name){
                tmpCrit.column = replacement.name
            }
            if(crit.is_column && crit.target_values?.length > 0 && crit.target_values[0] === c.name){
                crit.target_values[0] = replacement.name
            }
            return tmpCrit
        })
        updated.filter = JSON.stringify(query)

        updated.sort_column = updated.sort_column === c.name ? replacement.name : updated.sort_column
        updated.selected_kpi = updated.selected_kpi === c.name ? replacement.name : updated.selected_kpi

        setTmpAction(updated)
        setReplacedMissingColumns([...replacedMissingColumns, c.name])
    }
    
    const handleReset = () => {
        setTmpAction(structuredClone(props.actionStatus.list))
        setSelectedReplacementColumnMap({})
        setRemovedMissingColumns([])
        setReplacedMissingColumns([])
    }

    const handleSave = (update: boolean) => {
        props.onSave(tmpAction, update)
    }
    
    return (
        <GenericDialog 
            show={props.show}
            title={`Fix ${props.actionStatus.list.name}`}
            onClose={props.onClose}
            getButtons={() => (
                <div>
                    <button onClick={() => props.onClose()} className='btn btn-default mr-2'>{props.duringCreation ? "Abort" : "Close"}</button>
                    {!props.duringCreation && <button onClick={() => handleSave(false)} disabled={disableSave} className='btn btn-success mr-2'>Save</button>}
                    <button onClick={() => handleSave(true)} disabled={disableSave} className='btn btn-success mr-2'>{props.duringCreation ? "Create" : "Save & Refresh"}</button>
                </div>
            )}
            style={{width: 800}}
        >
            {shownMissingColumns.length > 0 && <table className="w-100 table table-striped">
                <thead>
                    <tr>
                        <th>Missing Column</th>
                        <th>Replacement Column</th>
                        <th></th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>

                    {shownMissingColumns.map((c, i) => (
                        <tr key={i}>
                            <td  className="align-middle">
                                {c.name}
                                {c.in_filter && <i className='d-inline-block fa fa-warning text-danger ml-2' aria-hidden="true" title="This column is part of the slicer for this Action"></i>}    
                            </td>
                            <td>
                                <ShowIf if={columns.length > 0 || c.in_filter}>
                                    <Select
                                        className="w-100"
                                        isSearchable
                                        options={ c.in_filter 
                                            ? props.allColumns
                                                .filter(col => col.type === mapColumnTypes(c.column_type))
                                                .map(col => ({label: col.name, value: col}))
                                                .sort((a, b) => a.label.localeCompare(b.label))
                                            : columns
                                                .filter(col => !alreadySelectedReplacements.includes(col.name))
                                                .map(col => ({label: col.name, value: col}))
                                                .sort((a, b) => a.label.localeCompare(b.label))}
                                        value={selectedReplacementColumnMap[c.name] 
                                            ? {label: selectedReplacementColumnMap[c.name].name, value: selectedReplacementColumnMap[c.name]}
                                            : null
                                        }
                                        //@ts-ignore
                                        onChange={(o: SelectOption<Column>) => {
                                            const tmpReplacementColumnMap = structuredClone(selectedReplacementColumnMap)
                                            tmpReplacementColumnMap[c.name] = o.value
                                            setSelectedReplacementColumnMap(tmpReplacementColumnMap)
                                        }}
                                        placeholder={"Select column..."}
                                        />
                                </ShowIf>
                                <ShowIf if={columns.length === 0 && !c.in_filter}>
                                    <div className="font-italic text-secondary p-2">No columns to replace with</div>
                                </ShowIf>
                            </td>
                            <td className="align-middle text-right">
                                <button 
                                    className="btn-outline-secondary btn px-1 py-0" 
                                    title={"Replace missing column with selected replacement column."} 
                                    disabled={!selectedReplacementColumnMap[c.name]}
                                    onClick={() => handleReplaceColumn(c, selectedReplacementColumnMap[c.name])}
                                >
                                    Replace
                                </button>
                            </td>
                            <td className="align-middle text-right">
                                <button 
                                    className="btn-danger btn px-1 py-0" 
                                    title={c.in_filter ? "Column is used in slicer, has to be replaced." : ""} 
                                    disabled={c.in_filter}
                                    onClick={() => handleRemoveColumn(c)}
                                >
                                    Remove
                                </button>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>}
            {(replacedMissingColumns.length > 0 || removedMissingColumns.length > 0) && <div className="d-flex justify-content-between border-top pt-2">
                <div>
                    {replacedMissingColumns.length > 0 && <> 
                        <div className="font-weight-bold">Replaced columns</div>
                        {replacedMissingColumns.map((c, i) => (
                            <div key={i} className="font-italic text-secondary">{c} replaced with <b>{selectedReplacementColumnMap[c]?.name}</b></div>
                        ))}
                    </>}
                    {removedMissingColumns.length > 0 && <> 
                        <div className="font-weight-bold">Removed columns</div>
                        {removedMissingColumns.map((c, i) => (
                            <div key={i} className="font-italic text-secondary">{c}</div>
                        ))}
                    </>}
                </div>
                <div>
                    <button 
                        className="btn btn-outline-secondary px-3 py-0 mr-2" 
                        disabled={removedMissingColumns.length === 0 && replacedMissingColumns.length === 0}
                        onClick={handleReset}
                        >
                        Undo
                    </button>
                </div>
            </div>}
        </GenericDialog>
    )
}

export default FixActionDialog