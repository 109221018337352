import { End2EndSettings, getCatName, CxmSetup } from "../types/cxmtypes";
import React, { Fragment } from "react";
import { SingleKeyMap } from "./Collections";
import { DataBucket, QueryCriterion } from "../types/transfertypes";
import { CompareType } from "../types/slicertypes";


export const getUniqueDescription = (settings: End2EndSettings, buckets: SingleKeyMap<DataBucket>, setup: CxmSetup) => {
    return <div>
        {
            Object.keys(settings.buckets).map(b => {
                const bucket = buckets.get(b)
                const column = setup.columns.find(c => c.bucket == b)
                if (bucket == undefined || column == undefined || settings.buckets[b].filter.length == 0) return <Fragment key={b} />
                const otherBuckets = Object.keys(settings.buckets).filter(v => v != b).map(v => buckets.get(v)).filter(v => v != undefined) as DataBucket[]
                let filters = structuredClone(settings.buckets[b].filter)
                const i = filters.findIndex(f => f.column == getCatName(settings.buckets[b].use_second_category, column) && f.compare_type == CompareType.EQ)
                let mainFilter: QueryCriterion | undefined  = undefined
                if (i != -1) {
                    mainFilter = filters.splice(i, 1)[0]
                }
                const mainStr = combineStringsWithAnd(mainFilter?.target_values ?? [])
                const filterStr = combineStringsWithAnd(filters.map(f => `${f.column} (${f.target_values.length})`))
                return <div key={b}>
                    {combineStringsWithAnd(otherBuckets.map(b => b.info.name))} contain items that have a unique relation to {mainStr}{filterStr != "" && mainStr != "" ? <> with filters </> : filterStr != "" ? <> filters </> : ''}{filterStr}
                </div>
            })
        }
    </div>
}

export const getUniqueDescriptionGrid = (settings: End2EndSettings, buckets: SingleKeyMap<DataBucket>, setup: CxmSetup, currentBucketId: string) => {
    return <div>
        {
            Object.keys(settings.buckets).filter(b => b != currentBucketId).map(b => {
                const bucket = buckets.get(b)
                const column = setup.columns.find(c => c.bucket == b)
                if (bucket == undefined || column == undefined || settings.buckets[b].filter.length == 0) return <Fragment key={b} />
                let filters = structuredClone(settings.buckets[b].filter)
                const i = filters.findIndex(f => f.column == getCatName(settings.buckets[b].use_second_category, column) && f.compare_type == CompareType.EQ)
                let mainFilter: QueryCriterion | undefined  = undefined
                if (i != -1) {
                    mainFilter = filters.splice(i, 1)[0]
                }
                const mainStr = combineStringsWithAnd(mainFilter?.target_values ?? [])
                const filterStr = combineStringsWithAnd(filters.map(f => `${f.column} (${f.target_values.length})`))
                return <div key={b}>
                    Items shown have a unique relation to {mainStr}{filterStr != "" && mainStr != "" ? <> with filters </> : filterStr != "" ? <> filters </> : ''}{filterStr} in <b>{bucket.info.name}</b>
                </div>
            })
        }
    </div>
}

function combineStringsWithAnd(arr: string[]) {
    if (arr.length === 0) return "";
    if (arr.length === 1) return <b>{arr[0]}</b>;
    if (arr.length === 2) return <><b>{arr[0]}</b> and <b>{arr[1]}</b></>;
    
    const allButLast = arr.slice(0, -1).join(", ");
    const last = arr[arr.length - 1];
    return <><b>{allButLast}</b>, and <b>{last}</b></>;
}
