import moment from "moment"
import { DashboardKPI, DashboardKPITargets, DashboardKPIMultipleTargets, DashboardReport, DashboardKPISimpleTargets, DashboardTableData, DashboardTarget, ParsedDashboardData, DashboardRow, RowType, DashboardReportRow, LibraryReport } from "../types/transfertypes"
import type { DecoratedDashboardReport, DecoratedDashboardTarget } from "../types/types"
import { formatValue } from '../helpers/ReportHelpers'

export const getArrowValues = (first:number, last:number, trend:number) => {
    let res = { direction: 0, isPositive: false }
    if (first > last && trend === 0) { res.isPositive = false; res.direction = -1; }
    if (first < last && trend === 0) { res.isPositive = false; res.direction = 1; }

    if (first > last && trend === 1) { res.isPositive = false; res.direction = -1; }
    if (first > last && trend === -1) { res.isPositive = true; res.direction = -1; }

    if (first < last && trend === 1) { res.isPositive = true; res.direction = 1; }
    if (first < last && trend === -1) { res.isPositive = false; res.direction = 1; }
    
    return res
}

export const getTargetColor = (val:number, target:number, trend:number, found:boolean) => {
    if (trend === 0 || !found) return "no-target"
    if (val > target && trend === -1) return "red-target"
    if (val >= target && trend === 1) return "green-target"
    if (val < target && trend === 1) return "red-target"
    if (val <= target && trend === -1) return "green-target"
}

export const bigWidgetOptions = () => {
    return {
    spanGaps: false,
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
   
    scales: {
        yAxes: [{
            scaleShowGridLines: true,
            gridLines: {  
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false,
            },
            scaleLabel: {
                display: true,
                padding: -12,
            }
        }],
        xAxes: [{
            gridLines: {
                offsetGridLines: false,
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false,
            },
            scaleLabel: {
                display: true,
                padding: -12,
            },
        }],

    },
    elements: {
        offset: true,
        point: {
            radius: 0
        },
        line: {
            tension: 0
        }
    },
    layout: {
        padding: {
            top: 10,
            bottom: 4,
        }
    },
    tooltips: {
        responsive: "false",
        filter: (tooltipItem: any) => {
            let tooltipI = tooltipItem.datasetIndex === 0
            return tooltipI
        },
        mode: 'point',
        callbacks: {
            title : tooltipTitle,
            label: tooltipContent,
        },

        position: "nearest",
        displayColors: false,
    },
    }
}

const tooltipContent = (tooltipItem: any, data: any) => {
    return formatValue(true, tooltipItem.label, false)
} 
const tooltipTitle = (tooltipItem: any, data: any) => {
    return data.datasets[2].data[tooltipItem[0].index]
} 
export const homeAddWidgetOptions = {
    spanGaps: true,
    animation: false,
    responsive: true,
    maintainAspectRatio: false,
    legend: {
        display: false,
    },
    scales: {
        yAxes: [{
            gridLines: {
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false,
            },
            scaleLabel: {
                display: true,
                padding: -12,
            }
        }],
        xAxes: [{
            gridLines: {
                offsetGridLines: false,
                display: false,
                drawBorder: false,
            },
            ticks: {
                display: false,
            },
            scaleLabel: {
                display: true,
                padding: -12,
            },
        }],

    },
    elements: {
        point: {
            radius: 0
        },
        line: {
            tension: 0
        }
    },
    layout: {
        padding: {
            top: 0,
            bottom: 0,
        }
    },
    tooltips: {
        enabled: false
    },
}

export const getAllTargets = (simpleTargets:DashboardKPISimpleTargets, multipleTargets:DashboardKPIMultipleTargets, 
    kpiTargets:DashboardKPITargets,
    removeOverdueTargets = true, showAllNonOverdue = false,
    tableData: DashboardTableData
    ) => {
    let targets:{[key:string]:DecoratedDashboardTarget[]} = {}

    for(let kpiKey in multipleTargets) {
        if(!multipleTargets.hasOwnProperty(kpiKey)) continue
        if(!targets[kpiKey]) targets[kpiKey] = []
        let ts = multipleTargets[kpiKey].targets
        //need the next target to be first:
        ts.sort((a,b) => b.deadline.length - a.deadline.length /* targets without deadline last */ || a.deadline.localeCompare(b.deadline) /* soonest deadline first */)
        ts = ts.reduce((acc:DashboardTarget[],t) => {
            let isOverdue = t.deadline && moment().isAfter(t.deadline)
            if(
                (isOverdue && !removeOverdueTargets) ||  /* if overdue but allowing overdue targets */ 
                t.show_in_table || /* if it should always be shown in the table */
                (showAllNonOverdue && !isOverdue) || /* if it is being shown in the target overview table and it's not overdue*/
                (showAllNonOverdue && isOverdue && !removeOverdueTargets) /* if it is being shown in the target overview table and it is overdue*/
            ) {
                acc.push(t)
            }
            return acc
        }, [])
        const decoractedTargets = ts.map(t => {let dt: DecoratedDashboardTarget = t; dt.trend = multipleTargets[kpiKey].trend; dt.simple = false; return dt})
        targets[kpiKey] = targets[kpiKey].concat(decoractedTargets)
    }

    for(let kpiKey in simpleTargets) {
        if(!simpleTargets.hasOwnProperty(kpiKey)) continue
        if(!targets[kpiKey]) targets[kpiKey] = []
        let target: DecoratedDashboardTarget = simpleTargets[kpiKey].target
        if(!showAllNonOverdue && !target.show_in_table) continue
        target.trend = simpleTargets[kpiKey].trend
        target.simple = true
        targets[kpiKey].push(target)
    }

    for(let kpikey in kpiTargets){
        if(kpiTargets[kpikey] && tableData){
            let kpi = kpiTargets[kpikey]
            if (targets[kpi.kpi][0]) {
                let targetTableData = tableData[kpi.target_kpi]
                if(targetTableData) {
                    if(targetTableData[kpi.target_kpi_report]) {
                        // @ts-ignore not used anyways
                        targets[kpi.kpi][0].values[kpi.report] = targetTableData[kpi.target_kpi_report].last
                    }
                }
            }
        }
    }

    return targets
}

export const getAllReports = (reports:DashboardReport[], mainReport:DashboardReport|null) => {
    let decoratedReports: DecoratedDashboardReport[] = reports
    if(mainReport && mainReport.report_id) {
        let mr: DecoratedDashboardReport = mainReport
        mr.mainReport = true
        decoratedReports.unshift(mr)
    }
    return decoratedReports
}

export const getKpiTotals = (tableData: DashboardTableData, kpis:DashboardKPI[], reports:DashboardReport[], mainReport:DashboardReport|null) => {
    let total:number[] = Array(kpis.length).fill(0)
    if(mainReport && mainReport.report_id) {
        total = total.map((_n,i) => {
            let kpi = kpis[i]
            //mainreport value for each kpi, array index i = value for i'th kpi
            // @ts-ignore
            return tableData[kpi.kpi_key] ? tableData[kpi.kpi_key][mainReport.report_id] ? tableData[kpi.kpi_key][mainReport.report_id].last : 0 : 0
        })
    } else {
        total = total.map((_n,i) => {
            let kpi = kpis[i]
            //sum of all reports' value for each kpi, array index i = sum of i'th kpi values
            // @ts-ignore
            return tableData[kpi.kpi_key] ? reports.reduce((acc,r)=>tableData[kpi.kpi_key][r.report_id] ? acc+tableData[kpi.kpi_key][r.report_id].last : acc, 0) : 0
        })
    }
    return total
}

export const getKpiTotalsNew = (tableData: DashboardTableData, kpis: DashboardKPI[], mainReport: DashboardRow) => {
    let total: number[] = Array(kpis.length).fill(0)
    total = total.map((_n, i) => {
        let kpi = kpis[i]
        // @ts-ignore
        return tableData[kpi.kpi_key] ? tableData[kpi.kpi_key][mainReport.row_id] ? tableData[kpi.kpi_key][mainReport.row_id].last : 0 : 0
    })
    return total
}


// Returns true if the KPI is used in a widget
export const isKpiUsed = (dashboard: ParsedDashboardData, kpi?: DashboardKPI) => {
    if (dashboard == undefined || kpi == undefined) return false
    return dashboard.widgets.some(v => {
        return v.widgets.some(w => {
            if (!w.shown) return false
            if (w.kpi_key == kpi.kpi_key) return true
            return false
        })
    })
}

// Returns true if the row is used in a widget
export const isRowUsed = (dashboard: ParsedDashboardData, rowId: string) => {
    if (dashboard == undefined) return false
    return dashboard.widgets.some(v => {
        return v.widgets.some(w => {
            if (!w.shown) return false
            if (w.row_id == rowId) return true
            return false
        })
    })
}

/** Removes all widgets using the specified row. Does not modify the original dashboard, but returns a copy.
 */
export const removeWidgetsWithRow = (dashboard: ParsedDashboardData, rowId: any) => {
    let tmp: ParsedDashboardData = structuredClone(dashboard) 
    for(let i = 0; i < tmp.widgets.length; i++) {
        for(let j = 0; j < tmp.widgets[i].widgets.length; j++) {
            if (tmp.widgets[i].widgets[j].row_id == rowId) {
                tmp.widgets[i].widgets[j] = {shown: false, kpi_key: "", trend: 0, label: "", row_id: "", id: tmp.widgets[i].widgets[j].id}
            }
        }
    }
    return tmp
}

/** Removes all widgets using the specified KPI. Does not modify the original dashboard, but returns a copy.
 */
export const removeWidgetsWithKpi = (dashboard: ParsedDashboardData, kpi: DashboardKPI) => {
    let tmp: ParsedDashboardData = structuredClone(dashboard)
    for(let i = 0; i < tmp.widgets.length; i++) {
        for(let j = 0; j < tmp.widgets[i].widgets.length; j++) {
            if (tmp.widgets[i].widgets[j].kpi_key == kpi.kpi_key) {
                tmp.widgets[i].widgets[j] = {shown: false, kpi_key: "", row_id: "", trend: 0, label: "", id: tmp.widgets[i].widgets[j].id}
            }
        }
    }
    return tmp

}

export const getDashboardColumnName = (kpiKey: string, dashboard: ParsedDashboardData) => {
    const kpi = dashboard.kpis.find(k => k.kpi_key == kpiKey)
    if (kpi == undefined) return undefined

    if (kpi.label != undefined && kpi.label != "") return kpi.label

    return kpi.column
}

export const getDashboardRowName = (rowId: string, dashboard: ParsedDashboardData, reports: LibraryReport[]) => {
    const row = rowId == dashboard.main_row.row_id ? dashboard.main_row : dashboard.rows.find(r => r.row_id == rowId)
    if (row == undefined) return undefined

    if (row.row.label != undefined && row.row.label != "") return row.row.label

    if (row.type == RowType.Report) {
        return reports.find(r => r.report.report_id == (row.row as DashboardReportRow).report_id)?.report.name
    }

    return undefined
}

export const getWidgetDefaultName = (rowId: string | undefined, kpiKey: string | undefined, dashboard: ParsedDashboardData, reports: LibraryReport[]) => {
    if (rowId == undefined || kpiKey == undefined) return 'No table cell selected'
    const rowName = getDashboardRowName(rowId, dashboard, reports)
    const columnName = getDashboardColumnName(kpiKey, dashboard)

    if (rowName == undefined || columnName == undefined) return 'No table cell selected'
    return `${columnName} - ${rowName}`
}
