import React, { useEffect, useState, useMemo } from 'react'
import { connect } from 'react-redux'
import { SingleKeyMap } from '../helpers/Collections'
import { DashboardFull, DashboardOverview, LibraryReport, LoginSession, OrganizationSubscriptionSettings, OrgInfo } from '../types/transfertypes'
import { useParams } from 'react-router'
import { getDashboardAllData, getHomeDashboard, getDefaultDashboard, getDashboardOverview, setHomeDashboard, getDashboardReports } from '../actions/DashboardActions'
import { setPageTitle } from '../helpers/DocumentHelpers'
/* CSS */
import '../css/dashboard.css'
import '../css/DashboardAdmin.css'
import DashboardWidgets from '../components/Dashboard/DashboardWidgets'
import ShowIf from '../components/Generic/ShowIf'
import DashboardTable2 from '../components/Dashboard/DashboardTable2'
import RequirePermission from '../components/RequirePermission'
import { is } from '../helpers/PermissionHelpers'
// @ts-ignore
import { Link } from 'react-router-dom'
import ResponsiveToggle from '../components/Generic/ResponsiveToggle'
import HomeIcon from '../components/icons/HomeIcon'
import InfoTooltip from '../components/Generic/InfoTooltip'
import LoadingAnimation from '../components/LoadingAnimation'
import { createMailBundle, deleteMailBundle, getAllPlans, getOrgMailBundles, updateMailBundle } from '../actions/MailBundleActions'
import { MailBundleWithRelations, newMailBundle, Plan } from '../types/reportingtypes'
import { FaEnvelope } from 'react-icons/fa'
import DashboardMailSignupModal from '../components/Dashboard/DashboardMailSignupModal'
import { getOrganization, getOrganizationSubscriptionSettings } from '../actions/OrganizationActions'

interface DashboardProps {
    dispatch: any,
    dashboardData: SingleKeyMap<DashboardFull>,
    dashboardOverview: DashboardOverview[],
    homeDashboard: number,
    defaultDashboard: number,
    dashboardNotAccessible: boolean,
    user: LoginSession,
    history: any,
    reports: LibraryReport[],
    mailbundles: MailBundleWithRelations[],
    plans: Plan[]
    orgInfo: OrgInfo
    subscriptionSettings: OrganizationSubscriptionSettings
}

let mapStateToProps = (state: any, ownProps: DashboardProps) => {
    return {
        user: state.User.info,
        dashboardOverview: state.Dashboard.overview,
        dashboardData: state.Dashboard.dashboard_data,
        homeDashboard: state.Dashboard.homeDashboard,
        defaultDashboard: state.Dashboard.defaultDashboard,
        dashboardNotAccessible: state.Dashboard.dashboardNotAccessible,
        followings: state.User.followings,
        reports: state.Dashboard.reports,
        mailbundles: state.Reporting.mailBundles,
        plans: state.Reporting.plans,
        orgInfo: state.Organization.info,
        subscriptionSettings: state.Organization.subscriptionSettings
    }
}

let Dashboard2 = (props: DashboardProps) => {

    const [dashboard, setDashboard] = useState<DashboardFull | undefined>()
    const [hoverRowId, setHoverRowId] = useState<string>("")
    const [hoverKpiKey, setHoverKpiKey] = useState<string>("")
    const [showMailbundleModal, setShowMailbundleModal] = useState(false);

    let { dID } = useParams<{ dID: string }>()
    const dashboardID = useMemo(() => Number(dID), [dID])
        
    const mailBundlesWithUser = useMemo(() => 
        props.mailbundles.filter(m => m.user_ids.includes(props.user.user_id))
    , [props.mailbundles, props.user])
    
    const userSelfSubscriptions = useMemo(() => 
        mailBundlesWithUser.filter(mb => mb.self_subscription && mb.owner_id === props.user.user_id)
    ,[mailBundlesWithUser]) 
    
    const userSubscribesToDashboard = useMemo(() => 
        userSelfSubscriptions.length > 0 ? userSelfSubscriptions.flatMap(mb => mb.dashboards).some(d => d.dashboard_id === dashboardID) : false
    , [userSelfSubscriptions, dashboardID])
    
    const mailBundleWithDashboard = useMemo(() =>
        mailBundlesWithUser
    .filter(mb => !mb.self_subscription)
    .find(mb => mb.dashboards.some(d => d.dashboard_id === dashboardID))
    ,[mailBundlesWithUser, dashboardID])
    
    const userGetsDashboardByBundle = useMemo(() => 
        !!mailBundleWithDashboard
    , [mailBundlesWithUser, dashboardID])
    
    useEffect(() => {
        props.dispatch(getDashboardAllData(dashboardID))
        props.dispatch(getHomeDashboard())
        props.dispatch(getDefaultDashboard())
        props.dispatch(getDashboardOverview())
        props.dispatch(getDashboardReports())
        props.dispatch(getAllPlans())
        setPageTitle("Dashboard")
    }, [])

    useEffect(() => {
        props.dispatch(getDashboardAllData(dashboardID))
    }, [dashboardID])

    useEffect(() => {
        if(!!props.user?.organization){
            props.dispatch(getOrgMailBundles(props.user.organization))
            props.dispatch(getOrganization(props.user.organization))
            props.dispatch(getOrganizationSubscriptionSettings(props.user.organization))
        }
    }, [props.user])

    useEffect(() => {
        let dash = props.dashboardData.get(dID)
        setDashboard(dash)
    }, [props.dashboardData, dID])

    const widgetData = useMemo(() => {
        if (dashboard != undefined) {
            return dashboard.widget_data
        }
        return []
    }, [dashboard])

    const updateHomeDashboard = () => {
        props.dispatch(setHomeDashboard(dashboardID))
    }

    const removeHomeDashboard = () => {
        if (props.defaultDashboard !== dashboardID) {
            props.dispatch(setHomeDashboard(0))
        }
    }

    const toggleHomeDashboard = () => {
        if (props.homeDashboard === dashboardID || (props.defaultDashboard === dashboardID && noAccessToHomeDashboard)) {
            removeHomeDashboard()
        } else {
            updateHomeDashboard()
        }
    }

    const setHoverValues = (id: string, key: string) => {
        setHoverRowId(id)
        setHoverKpiKey(key)
    }

    const noAccessToHomeDashboard = (!props.homeDashboard || (props.dashboardOverview && props.dashboardOverview.every(d => d.id !== props.homeDashboard)))

    const subscribe = (plan: Plan, include_table: boolean) => {
        if(!dashboard) return;
        
        // Add dashboard to existing subscribtion with same plan
        if(userSelfSubscriptions.length > 0 && !userSelfSubscriptions.flatMap(mb => mb.dashboards).some(d => d.dashboard_id === dashboardID)){
            const mb = userSelfSubscriptions.find(m => m.plan_id === plan.id)
            if(mb){
                mb.dashboards.push({dashboard_id: dashboardID, include_table, include_widgets: true, index: 0})
                props.dispatch(updateMailBundle(mb))
                return;
            }
        }

        // create subscribtion if doesnt exist
        const mb: MailBundleWithRelations = newMailBundle(dashboard?.dashboard_data.org_id)
        mb.active = true
        mb.dashboards.push({dashboard_id: dashboardID, include_table, include_widgets: true, index: 0})
        mb.name = props.user.firstname + " " + props.user.lastname + " - Subscription"
        mb.link = true
        mb.user_ids.push(props.user.user_id)
        mb.plan = plan.interval
        mb.plan_id = plan.id
        mb.self_subscription = true
        mb.owner_id = props.user.user_id    
        props.dispatch(createMailBundle(mb))
    }

    const unsubscribe = () => {
        if(userSelfSubscriptions.length === 0) return;
        const userSelfSubscription = userSelfSubscriptions.find(mb => mb.dashboards.some(d => d.dashboard_id === dashboardID))

        if(!userSelfSubscription) return;
        const hasTrendViews = userSelfSubscription.insight_views.length > 0
        const hasReports = userSelfSubscription.insights.length > 0
        const hasMultipleDashboards = userSelfSubscription.dashboards.length > 1
        const hasOtherItems = hasTrendViews || hasReports || hasMultipleDashboards
        
        if(hasOtherItems){
            const mb = userSelfSubscription
            mb.dashboards = mb.dashboards.filter(d => d.dashboard_id !== dashboardID)
            props.dispatch(updateMailBundle(mb))
            return;
        }
        props.dispatch(deleteMailBundle(userSelfSubscription)) // Delete mailbundle if no other items
    }

    return (
        <div className="home-settings padding-top-20px">
            <div className="col-md-12 margin-bottom-20px">
                <div className="row">
                    <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                        {
                            !dashboard ? <div className='mt-5'>
                                <div style={{width: "100%", display: "flex", justifyContent: "center"}}>
                                    <LoadingAnimation style={{marginTop: "100px"}} />
                                </div>
                                <h1 className="text-align-center">Getting Dashboard...</h1>
                            </div> :
                                <div>
                                    <div className="d-flex align-items-center" style={{justifyContent: "space-between"}}>
                                            <div>
                                                <select className="form-control mr-2 form-control-lg inline-block auto-width vertical-align-middle" value={dID} onChange={e => props.history.push(`/dashboard/${e.target.value}`)}>
                                                    {
                                                        props.dashboardOverview.map(d => (
                                                            <option key={d.id} value={d.id}>{d.title}</option>
                                                        ))
                                                    }
                                                </select>
                                                <ShowIf if={dashboard.dashboard_data.description != ""}>
                                                    <InfoTooltip text={dashboard.dashboard_data.description} place="bottom"/>
                                                </ShowIf>
                                            </div>
                                            <div className="d-flex align-items-center">
                                                <ResponsiveToggle style={{width: "fit-content", padding: "0.375rem 0.75rem", borderColor: "#d9d9d9"}} label="Show on home" onChange={toggleHomeDashboard} checked={props.homeDashboard === dashboardID || (props.defaultDashboard === dashboardID && noAccessToHomeDashboard)}>
                                                    <HomeIcon width={14} height={14} add={!(props.homeDashboard === dashboardID || (props.defaultDashboard === dashboardID && noAccessToHomeDashboard))} />
                                                </ResponsiveToggle>
                                                <ShowIf if={props.orgInfo?.self_subscription_enabled && props.subscriptionSettings?.enable_dashboard}>
                                                    <div 
                                                        className={`${userSubscribesToDashboard || userGetsDashboardByBundle ? "btn-primary" : "btn-default"} abc-click responsive-button-container ml-2`} 
                                                        title={userSubscribesToDashboard? "You recieve this Dashboard by mail" : "Sign up to recieve Dashboard by mail"}
                                                        onClick={() => setShowMailbundleModal(true)}
                                                        style={{width: "fit-content", padding: "0.375rem 0.75rem", borderColor: "#d9d9d9"}}
                                                    >   
                                                        <span className={`responsive-button-label d-flex ${userSubscribesToDashboard || userGetsDashboardByBundle ? "text-light" : ""}`}>
                                                            <FaEnvelope width={14} height={14} className='d-inline-block mr-1 h6'/>
                                                            <span className=''>{userSubscribesToDashboard || userGetsDashboardByBundle ? "Subscribed" : "Subscribe"}</span>
                                                        </span>
                                                    </div>
                                                </ShowIf>
                                                <RequirePermission perms={is.dashboardAdmin}>
                                                    <Link 
                                                        to={`/dashboardadmin/${dID}`} 
                                                        className="responsive-button-container btn-default ml-2"
                                                        style={{width: "fit-content", padding: "0.375rem 0.75rem", borderColor: "#d9d9d9", textDecoration: "none"}}
                                                    >
                                                        <span className="responsive-button-label d-flex">
                                                            <i className="fa fa-pencil-square-o mr-1 h6" aria-hidden="true"></i>
                                                            <span>Edit</span>
                                                        </span>
                                                    </Link>
                                                </RequirePermission>
                                            </div>
                                            {/*<ShowIf if={props.homeDashboard !== dashboardID && !(props.defaultDashboard === dashboardID && noAccessToHomeDashboard)}>
                                                <span className="fa fa-star-o dashboard-star vertical-align-middle" title="Show on home" onClick={updateHomeDashboard}></span>
                                            </ShowIf>
                                            <ShowIf if={props.homeDashboard === dashboardID || (props.defaultDashboard === dashboardID && noAccessToHomeDashboard)}>
                                                <span className="fa fa-star dashboard-star vertical-align-middle" title={props.defaultDashboard === dashboardID ? "This is a company dashboard. You cannot remove this from home, but you can override it by showing another dashboard." : "Remove from home"} onClick={removeHomeDashboard}></span>
                                            </ShowIf>*/}
                                        {/* <ShowIf if={!followsReport}>
                                            <button className="btn btn-default btn-xs" onClick={this.followDashboard(true)}>Follow</button>
                                        </ShowIf>
                                        <ShowIf if={followsReport}>
                                            <button className="btn btn-default btn-xs" onClick={this.followDashboard(false)}>Unfollow</button>
                                        </ShowIf> */}
                                    </div>
                                    <hr />
                                    <div className="margin-top-30px">
                                        <DashboardWidgets
                                            setHoverValues={(id: string, key: string) => setHoverValues(id, key)}
                                            hoverReportId={hoverRowId}
                                            hoverKpiKey={hoverKpiKey}
                                            widgetData={widgetData}
                                            dashboardId={dashboardID}
                                        />
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                </div>
                <div className="row">
                    <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                    <div className="col-lg-10 col-md-10 col-sm-12 col-xs-12">
                        {dashboard && !dashboard.dashboard_data.hide_table &&
                            <div className="margin-top-20px">
                                <div className="d-flex justify-content-center">
                                    <div className="dashboard-table-wrapper mb-3">
                                        <DashboardTable2 
                                            user={props.user}
                                            dashboard={dashboard}
                                            setHoverValues={setHoverValues}
                                            hoverKpi={hoverKpiKey}
                                            hoverRow={hoverRowId}
                                            reports={props.reports}
                                        />
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                    <div className="col-lg-1 col-md-1 col-sm-0 col-xs-0"></div>
                </div>
            </div>
            {showMailbundleModal && dashboard &&
                <DashboardMailSignupModal
                    subscribed={userSubscribesToDashboard}
                    mailbundle={mailBundleWithDashboard}
                    subscription={userSelfSubscriptions.find(mb => mb.dashboards.some(d => d.dashboard_id === dashboardID))}
                    show={showMailbundleModal}
                    onClose={() => setShowMailbundleModal(false)}
                    onSubscribe={subscribe}
                    onUnsubscribe={unsubscribe}
                    plans={props.plans}
                    dashboard={dashboard}
                    tableShown={!dashboard.dashboard_data.hide_table}
                />
            }
        </div>
    )

}

// @ts-ignore
Dashboard2 = connect(mapStateToProps)(Dashboard2)

export default Dashboard2
