import React from 'react'
import { SortableElement, SortableContainer, SortableHandle } from 'react-sortable-hoc'
import "../../css/sortableList.css"

export const DragHandle = SortableHandle(({className}) => <span className={`fa fa-ellipsis-h ${className}`}></span>)

const Item = SortableElement(({value, getItem, hideDragHandle}) => (
    getItem ? <div style={{position:"relative", zIndex: 10000}}>{getItem(value)}</div> :
    <div className={`sortable-list-item ${hideDragHandle ? "drag-handle-disabled" : ""}`}>
        <DragHandle className="sortable-list-handle"/>
        {value}
    </div>
))

const List = SortableContainer(({children, className}) => {
    return <div className={className}>{children}</div>
})


class SortableList extends React.Component {
    onSortEnd = ({oldIndex, newIndex}) => this.props.onSortEnd(oldIndex, newIndex)
    
    render() {
        if(!this.props.items) return <span></span>
        return (
            <List onSortEnd={this.onSortEnd} lockAxis={"y"} useDragHandle className={this.props.containerClassName}>
                { 
                    this.props.items.map((value, index) => (
                        <Item 
                            key={`item-${index}`} 
                            index={index} 
                            value={value} 
                            disabled={this.props.disabled} 
                            hideDragHandle={this.props.disabled} 
                            getItem={this.props.getItem ? (val) => this.props.getItem(val, index) : undefined} 
                        />
                    ))
                }
            </List>
        )
    }
}

export default SortableList