import React, { useEffect, useRef, useState } from 'react'
import useOnScreen from '../../hooks/useOnScreen'

const InfiniteScrollOnScreen = ({hasMore, next, loadingText}) => {
    const ref = useRef()
    const isVisible = useOnScreen(ref)
    const [hasTriggered, setHasTriggered] = useState(false)

    useEffect(() => {
        if(isVisible && !hasTriggered && hasMore){
            next()
            setHasTriggered(true)
        }else if (!isVisible){
            setHasTriggered(false)
        }
    }, [isVisible])
    
    return (
        <div style={{position: "relative"}}>
            <div ref={ref} style={{position: "absolute", top: -500}} />
            {(hasMore && hasTriggered) ? loadingText : null}
        </div>
    )
}

export default InfiniteScrollOnScreen
