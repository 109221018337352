/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { getChecklistsStatus } from '../actions/StatusActions'
import { getChecklistStatus, decorateChecklists, countChecklistStatus } from '../helpers/ChecklistHelpers'
import { getUsers } from '../actions/SystemActions'
import { getFullName, getInitials } from '../helpers/UserHelpers'
import ChecklistStatusTable from '../components/Status/ChecklistStatusTable'
import WordWrap from '../components/Generic/WordWrap'
import Progress from '../components/Generic/Progress'
import DateSpan from '../components/Generic/DateSpan'
import ChecklistDropdown from '../components/Status/ChecklistDropdown'
import * as ActionTypes from '../actions/ActionTypes'
import { packAction } from '../actions/ActionTypes'
import SortArrow from '../components/Generic/SortArrow'
import ShowIf from '../components/Generic/ShowIf'
import TextInputDialog from '../components/Dialogs/TextInputDialog'
import DeleteChecklistDialog from '../components/Dialogs/DeleteChecklistDialog'
import UserOptionDialog from '../components/Dialogs/UserOptionDialog'
import AssignUserDialog from '../components/Dialogs/AssignUserDialog'
import { setPageTitle } from '../helpers/DocumentHelpers'
import { withSettingsStorage } from '../helpers/SettingsService'
import { canUseChecklists } from '../helpers/PermissionHelpers'
import { Link } from 'react-router-dom'
import { getBucketInfo, getBucketsDataInfo, getBucketUsers } from '../actions/BucketActions'
import Select from '../components/Generic/CustomSelect'
import ReactTooltip from 'react-tooltip'
import {getDataDescriptionDialogDescription} from '../helpers/ReportHelpers'
import ReactMarkdown from 'react-markdown'
import { getCompareTypeSymbol } from '../helpers/ReportHelpers'
import moment from 'moment'
import SaveWithWarningDialog from '../components/Dialogs/SaveWithWarningDialog'
import { changeMultLayout, fixChecklist, updateChecklistItems } from '../actions/ChecklistActions'
import { getActionTemplates } from '../actions/TemplateActions'

/* CSS */
import '../css/checklistsStatus.css'
import { duplicateChecklist } from '../actions/ChecklistActions'
import EditChecklistDialog from '../components/Dialogs/EditChecklistDialog'
import MultiSelectBar, { MultiSelectButtonType } from '../components/MultiSelectBar'
import DividerText from '../components/Generic/DividerText'
import GenericDialog from '../components/Dialogs/GenericDialog'
import WarningAcceptDialog from '../components/Dialogs/WarningAcceptDialog'
import ResponsiveButton from '../components/Generic/ResponsiveButton'
import { getOrganization } from '../actions/OrganizationActions'
import { FaPlus } from 'react-icons/fa'
import { getUserInfo } from '../actions/UserActions'
import CreateActionDialog from '../components/Action/CreateActionDialog'
import FixActionDialog from '../components/Action/FixActionDialog'
import { compareObjects } from '../helpers/TypeHelpers'
import LoadingAnimation from '../components/LoadingAnimation'

const mapStateToProps = (state, ownProps) => {
	return {
		checklists: state.Status.checklists,
        loading: state.Status.loading,
        users: state.System.users,
        userInfo: state.User.info,
        bucketUsers: state.Bucket.bucketUsers,
        buckets: state.Bucket.buckets,
        bucketDetails: state.Bucket.bucketDetails,
        layouts: state.Checklist.layouts,
        orgInfo: state.Organization.info,
        templates: state.Checklist.actionTemplateGroups,
        tooManyActions: state.Status.too_many_actions,
        actionCount: state.Status.action_count
	}
}

const DEFAULT_LAYOUT = '[{"name":"TO DO", "color":"btn-primary"}, {"name":"DONE", "color":"btn-success"}]'

class ChecklistStatus extends React.Component {
    constructor(props) {
        super(props)
        this.state = { 
            sort: 'activity', 
            sortDirection: true, 
            statusFilter: 'active',
            checkedChecklists: [],
            author_filter: undefined,
            assignee_filter: undefined,
            searchInput: "",
            editChecklist: null,
            showEditChecklist: false,
            chosenLayout: DEFAULT_LAYOUT,
            showDeleted: false,
            showCreateAction: false,
            showQuickFix: false,
            checklistToFix: null,
            // for use when Actions exceed X amount, and we want to fetch the filtered Actions only from the API
            // change maxcount here if we want to display more/less Actions without a forced filter
            apiFilterOptions: {maxcount: 150}, 
            tooManyActions: null,
            bucketNameMap: {},
            bucket_filter: null
        }
    }

    allChecklistsChecked = false
    fetchedBuckets = []

    componentDidMount() {
        setPageTitle("Status")

        if(!this.props.checklists){
            this.props.dispatch(getChecklistsStatus(this.state.apiFilterOptions))
        }
        if(!this.props.users)
            this.props.dispatch(getUsers())
        this.props.dispatch(packAction(ActionTypes.GET_LAYOUTS))
        
        if(!this.props.userInfo){
            this.props.dispatch(getUserInfo())
        }

        if(!this.props.templates || !this.props.templates.fetched) {
            this.props.dispatch(getActionTemplates())
        }

        if(!this.props.bucketDetails || this.props.bucketDetails.keys().length === 0) {
            this.props.dispatch(getBucketsDataInfo())
        }

    }

    componentDidUpdate(prevProps, prevState) {
        if(this.props.checklists !== undefined) {
            this.props.checklists.forEach(checklist => {
                if(!this.fetchedBuckets.includes(checklist.list.bucket) && checklist.list.bucket) {
                    if(!this.props.bucketUsers.contains(checklist.list.bucket))
                        this.props.dispatch(getBucketUsers(checklist.list.bucket))
                    this.fetchedBuckets.push(checklist.list.bucket)
                }
            });
        }
        if(!this.props.orgInfo && this.props.userInfo && !!this.props.userInfo.organization){
            this.props.dispatch(getOrganization(this.props.userInfo.organization))
        }
        if(!!this.state.checklistToFix && !this.props.buckets?.get(this.state.checklistToFix?.list.bucket)){
            this.props.dispatch(getBucketInfo(this.state.checklistToFix.list.bucket))
        }
        
        if(prevProps.tooManyActions !== this.props.tooManyActions || this.state.tooManyActions === null){
            this.setState({tooManyActions: this.props.tooManyActions})
        }
        
        // if filter changed
        if (this.state.tooManyActions && !compareObjects(prevState.apiFilterOptions, this.state.apiFilterOptions)) {
            this.props.dispatch(getChecklistsStatus(this.state.apiFilterOptions))
        }

        if (Object.entries(this.state.bucketNameMap).length === 0 && !!this.props.bucketDetails && this.props.bucketDetails.keys().length > 0) {
            const tmp = {}
            this.props.bucketDetails.keys().forEach(k => {
                const info = this.props.bucketDetails.get(k)?.info
                if(!!info){
                    tmp[info.id] = info.name;
                }
            })
            this.setState({bucketNameMap: tmp})
        }
    }

    filterPredicate(checklist) {
        let assignee_filter = this.state.assignee_filter
        let author_filter = this.state.author_filter
        let bucket_filter = this.state.bucket_filter
        return !(
                (assignee_filter && checklist.list.assignee !== assignee_filter) ||
                (author_filter && checklist.list.user_id !== author_filter) ||
                (bucket_filter && checklist.list.bucket !== bucket_filter)
            )
    }

    statusPredicate(checklist) {
        if(checklist.list.archived && this.state.statusFilter === "archived") return true
        if(!checklist.list.archived && checklist.list.completed && this.state.statusFilter === "completed") return true
        if(!checklist.list.archived && checklist.list.overdue && this.state.statusFilter === "overdue") return true
        if(!checklist.list.archived && !checklist.list.completed && checklist.list.not_started && this.state.statusFilter === "not_started") return true
        if(!checklist.list.archived && checklist.list.started && this.state.statusFilter === "active") return true
        if(!checklist.list.archived && this.state.statusFilter === "active") return true
        return false
    }

    setStatusFilter(status) {
        this.setState({statusFilter: status})
        this.clearCheckedChecklists()
    }

    checklistComparator(a,b) {
        let sortType = this.state.sort
        let sortDirection = this.state.sortDirection
        let x = sortDirection? 1 : -1
        let y = sortDirection? -1 : 1
        switch(sortType) {
            case 'name':
                return (a.list.name<b.list.name?x:(a.list.name>b.list.name?y:0))
            case 'assignee':
                let aAssignee = getFullName(this.props.users, a.list.assignee)
                let bAssignee = getFullName(this.props.users, b.list.assignee)
                return (aAssignee<bAssignee?x:(aAssignee>bAssignee?y:0))
            case 'author':
                let aAuthor = getFullName(this.props.users, a.list.user_id)
                let bAuthor = getFullName(this.props.users, b.list.user_id)
                return (aAuthor<bAuthor?x:(aAuthor>bAuthor?y:0))
            case 'creation':
                return (a.list.upload_time<b.list.upload_time?x:(a.list.upload_time>b.list.upload_time?y:0))
            case 'activity':
                return (a.list.touch_time<b.list.touch_time?x:(a.list.touch_time>b.list.touch_time?y:0))
            case 'deadline':
                return (a.list.deadline<b.list.deadline?x:(a.list.deadline>b.list.deadline?y:0))
            case 'status':
                let aStatus = getChecklistStatus(a)
                let bStatus = getChecklistStatus(b)

                let aval = (aStatus.done / aStatus.total) * 100
                let bval = (bStatus.done / bStatus.total) * 100

                if (aStatus.total == 0) {
                    aval = -1
                }
                if(bStatus.total == 0){
                    bval = -1
                }

                if (!sortDirection) {
                    return aval - bval
                } 
                return bval - aval
            default:
                return (a.list.upload_time<b.list.upload_time?x:(a.list.upload_time>b.list.upload_time?y:0))
        }
    }

    removeAssigneeFilter() {
        this.setState({assignee_filter: null, apiFilterOptions: {...this.state.apiFilterOptions, assignee_id: undefined}})
        this.clearCheckedChecklists()
    }

    removeAuthorFilter() {
        this.setState({author_filter: null, apiFilterOptions: {...this.state.apiFilterOptions, author_id: undefined}})
        this.clearCheckedChecklists()
    }

    removeDatasourceFilter() {
        this.setState({bucket_filter: null, apiFilterOptions: {...this.state.apiFilterOptions, bucket: undefined}})
    }

    setAssigneeFilter(assignee) {
        this.setState({assignee_filter: assignee, apiFilterOptions: {...this.state.apiFilterOptions, assignee_id: assignee}})
        this.clearCheckedChecklists()
    }

    setAuthorFilter(author) {
        this.setState({author_filter: author, apiFilterOptions: {...this.state.apiFilterOptions, author_id: author}})
        this.clearCheckedChecklists()
    }

    setDatasourceFilter(bucket) {
        this.setState({bucket_filter: bucket, apiFilterOptions: {...this.state.apiFilterOptions, bucket: bucket}})
        this.clearCheckedChecklists()
    }

    setSort(sortType) {
        if(this.state.sort === sortType) {
            this.setState({sortDirection: !this.state.sortDirection})
        } else {
            this.setState({sort: sortType})
            this.setState({sortDirection: false})
        }
    }

    getFilteredChecklists = (checklists) => {
        return  checklists
                .filter(checklist => { return checklist.list.name.toLowerCase().includes(this.state.searchInput.toLowerCase())})
                // NOTE: Disabled Users are not in "this.props.users"
                // .filter(checklist => { let user = this.props.users.find(u => checklist.list.user_id === u.id); return user && (!user.is_consultant || (user.id === this.props.userInfo.user_id && this.props.userInfo.is_consultant_login))})
                // .filter(checklist => { let user = this.props.users.find(u => checklist.list.assignee === u.id); return user && (!user.is_consultant || (user.id === this.props.userInfo.user_id && this.props.userInfo.is_consultant_login))})
                .filter(checklist => this.filterPredicate(checklist))
    }

    getShownFilteredChecklists(checklists) {
        return this.getFilteredChecklists(checklists).filter(checklist => this.statusPredicate(checklist))
    }

    getChecklistsToShow(checklists) {
        let filteredChecklists = decorateChecklists(this.getFilteredChecklists(checklists))
        this.stats = countChecklistStatus(filteredChecklists)
        this.fullStats = countChecklistStatus(decorateChecklists(checklists))
        this.totalChecklistCount = this.state.statusFilter === 'archived' ? this.fullStats.archived : checklists.length -this.fullStats.archived
        
        if(filteredChecklists.length === 0) {
            return (<tr><td colSpan={"100%"} className='text-center font-italic p-2 text-secondary'>No Actions found.</td></tr>)
        }

        let mappedChecklists = filteredChecklists
                .sort((a, b) => this.checklistComparator(a, b) )
                .filter(checklist => this.statusPredicate(checklist))
                .map((checklist, i) => {
                    return this.getChecklistJSX(checklist, i)
                })
        this.shownChecklistCount = mappedChecklists.length
        return mappedChecklists 
    }

    getDeletedChecklistsToSHow(checklists) {
        let decoratedChecklists = decorateChecklists(checklists)
        let mappedChecklists = decoratedChecklists
            .sort((a, b) => b.list.touch_time - a.list.touch_time)
            .filter(checklist => checklist.list.archived == true)
            .map((checklist, i) => {
                return this.getDeletedChecklistJSX(checklist, i)
            })
        return mappedChecklists
    }

    checkChecklist(id) {
        var checkedChecklists = this.state.checkedChecklists
        if (this.state.checkedChecklists.includes(id)) {
            checkedChecklists.splice(checkedChecklists.indexOf(id), 1)
        } else {
            checkedChecklists.push(id)
        }
        this.setState({checkedChecklists: checkedChecklists})
    }

    clearCheckedChecklists() {
        this.setState({checkedChecklists: []})
        this.allChecklistsChecked = false
    }

    checkAllChecklists() {
        var checkedChecklists = []
        let checklists = this.getShownFilteredChecklists(this.props.checklists)
        if(!this.allChecklistsChecked) {
            checklists.forEach(item => {
                if(!item.missing_columns || item.missing_columns?.length === 0) checkedChecklists.push(item.list.id)
            })
        }
        this.setState({checkedChecklists: checkedChecklists})
        this.allChecklistsChecked = !this.allChecklistsChecked
    }

    getCheckedState(id) {
        return this.state.checkedChecklists.includes(id)
    }

    archiveChecklists() {
        var result = []
        this.state.checkedChecklists.forEach(item => result.push({id: item, archived: true}))
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLISTS, result)) 
        this.clearCheckedChecklists()
    }

    unarchiveChecklists() {
        var result = []
        this.state.checkedChecklists.forEach(item => result.push({id: item, archived: false}))
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLISTS, result)) 
        this.clearCheckedChecklists()
    }

    archiveChecklist(checklist_id) {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, {id: checklist_id, archived: true}))
    }

    unarchiveChecklist(checklist) {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, {id: checklist.id, archived: false}))
    }

    openEditNameDialog(checklist) {
        this.openHandler(checklist, () => 
            this.refs.changeNameDialog.show(this.state.checklistToBeEditted ? this.state.checklistToBeEditted.name : "")
        )
    }

    openEditDialog = (checklist) => {
        this.setState({editChecklist: checklist.list, showEditChecklist: true})
    }

    saveChecklistName(name) {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, {id: this.state.checklistToBeEditted.id, name: name}))
        this.closeHandler()
    }

    openDeleteDialog(checklist) {
        this.openHandler(checklist)
        this.refs.deleteChecklistDialog.show()
    }

    openArchiveDialog = (checklist) => {
        this.openHandler(checklist)
        this.refs.archiveAcceptDialog.show()
    }

    deleteChecklist() {
        this.props.dispatch(packAction(ActionTypes.DELETE_CHECKLIST, {id: this.state.checklistToBeEditted.id}))
        this.closeHandler()
    }

    openDeleteAllDialog() {
        this.refs.deleteAllChecklistDialog.show()
    }

    openArchiveAllDialog() {
        this.refs.archiveMultiAcceptDialog.show()
    }

    deleteAllChecklists() {
        let checklists = this.props.checklists.filter(checklist => checklist.list.archived).map(checklist => checklist.id)
        this.props.dispatch(packAction(ActionTypes.DELETE_MULTIPLE_CHECKLISTS, checklists))
        this.clearCheckedChecklists() 
    }

    cancelDialog(refName) {
        this.refs[refName].hide()
    }

    openAssignDialog(checklist) {
        this.openHandler(checklist, () => this.refs.assigneeDialog.showEdit(checklist.list ? checklist.list.assignee : -1, checklist.list.description, checklist.list.deadline ? moment(checklist.list.deadline) : null, !!checklist.list.deadline))
    }

    openMultiAssignDialog() {
        if (this.state.checkedChecklists.length == 0) return
        let checklist = this.getChecklist(this.state.checkedChecklists[0])
        this.openHandler(checklist, () => this.refs.multiAssigneeDialog.show(this.state.checklistToBeEditted ? this.state.checklistToBeEditted.assignee : -1))
        // this.refs.multiAssigneeDialog.show(this.state.checklistToBeEditted ? this.state.checklistToBeEditted.assignee : -1)
    }

    getChecklist = (id) => {
        let checklist = this.props.checklists.find((c) => {return c.id == id})
        return checklist
    }

    openAuthorDialog(checklist) {
        this.openHandler(checklist, () => this.refs.authorDialog.show(this.state.checklistToBeEditted ? this.state.checklistToBeEditted.author : -1))
    }

    openMultiAuthorDialog() {
        if (this.state.checkedChecklists.length == 0) return
        let checklist = this.getChecklist(this.state.checkedChecklists[0])
        this.openHandler(checklist, () => this.refs.multiAuthorDialog.show(this.state.checklistToBeEditted ? this.state.checklistToBeEditted.author : -1))
    }

    openLayoutDialog(checklist = null){
        //if (this.state.checkedChecklists.length == 0) return
        if (checklist != null) {
            this.openHandler(checklist)
        }
        this.refs.layoutDialog.show()
    }

    saveChecklistAssignee(assignee, message, hasdeadline, deadline) {

        let deadlineMoment = hasdeadline ? moment(deadline).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        let data = {id: this.state.checklistToBeEditted.id, assignee: parseInt(assignee, 10), has_deadline: hasdeadline, deadline: deadlineMoment, message: message, description: message }
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, data))

        this.closeHandler()
    }

    shouldLayoutConfirm = () => {
        if (this.state.checklistToBeEditted?.id != null){
            let checklist = this.getChecklist(this.state.checklistToBeEditted.id)
            if (getChecklistStatus(checklist).done > 0) {
                return true
            }
        }
        for (let i = 0; i < this.state.checkedChecklists.length; i++){
            let checklist = this.props.checklists.find((c) => c.id == this.state.checkedChecklists[i])
            if (getChecklistStatus(checklist).done > 0) {
                return true
            }
        }
        return false
    }

    multiSaveChecklistAssignee(assignee, message, hasdeadline, deadline) {

        let deadlineMoment = hasdeadline ? moment(deadline).format('YYYY-MM-DD') : moment().format('YYYY-MM-DD')
        let data = []

        if (message) {
            this.state.checkedChecklists.forEach((c) => {
                data.push({id: c, assignee: parseInt(assignee, 10), message: message, description: message, has_deadline: hasdeadline, deadline: deadlineMoment})
            })
        } else {
            this.state.checkedChecklists.forEach((c) => {
                data.push({id: c, assignee: parseInt(assignee, 10), has_deadline: hasdeadline, deadline: deadlineMoment})
            })
        }
        
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLISTS, data))
    
        this.closeHandler()
    }

    

    saveChecklistAuthor(author) {
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLIST, {id: this.state.checklistToBeEditted.id, user_id:  parseInt(author, 10)}))
        this.closeHandler()
    }

    multiSaveChecklistAuthor(author) {
        let data = []
        this.state.checkedChecklists.forEach((c) => {
            data.push({id: c, user_id: parseInt(author, 10)})
        })
        this.props.dispatch(packAction(ActionTypes.UPDATE_CHECKLISTS, data))
        this.closeHandler()
    }

    saveLayouts = () => {
        if (this.state.checklistToBeEditted?.id != null) {
            this.props.dispatch(changeMultLayout(this.state.chosenLayout, [this.state.checklistToBeEditted.id]))
        } else if(this.state.checkedChecklists.length != 0) {
            this.props.dispatch(changeMultLayout(this.state.chosenLayout, this.state.checkedChecklists))
        }
    }

    closeHandler() {
        this.setState({checklistToBeEditted: {id: null, tempAssignee: undefined, name: undefined, author: undefined}, chosenLayout: DEFAULT_LAYOUT})
    }

    openHandler(checklist, cb) {
        if(cb) {
            this.setState({checklistToBeEditted: {id: checklist.id, assignee: checklist.list.assignee, name: checklist.list.name, author: checklist.list.user_id}}, cb)
        } else {
            this.setState({checklistToBeEditted: {id: checklist.id, assignee: checklist.list.assignee, name: checklist.list.name, author: checklist.list.user_id}})
        }
    }

    canAccessBucket(user) {
        let result = false
        if(this.props.checklists && this.state.checklistToBeEditted !== undefined){
            let checklist = this.props.checklists.find(item => item.id === this.state.checklistToBeEditted.id)
            if(checklist !== undefined){
                let usersWithAccess = this.props.bucketUsers.get(checklist.list.bucket)
                if(checklist.list.bucket === ""){
                    usersWithAccess = this.props.users
                }
                
                if( usersWithAccess !== undefined){
                    result = usersWithAccess.some(u => user.id === u.id)
                }
            }
        }

        return result
    }

    canAccessAllBuckets(user) {
        let denied = false
        if(this.props.checklists && this.state.checkedChecklists.length > 0){
            this.state.checkedChecklists.forEach(cid => {
                if (denied) return
                let checklist = this.props.checklists.find(item => item.id === cid)
                if (checklist !== undefined){
                    let usersWithAccess = this.props.bucketUsers.get(checklist.list.bucket)
                    if(checklist.list.bucket === ""){
                        usersWithAccess = this.props.users
                    }
                    
                    if( usersWithAccess !== undefined){
                        let result = usersWithAccess.some(u => user.id === u.id)
                        if (!result) denied = true
                    }
                }
            })
        }
        return !denied
    }

    getAssignUsers() {
        if(this.props.checklists && this.state.checklistToBeEditted !== undefined){
            let checklist = this.props.checklists.find(item => item.id === this.state.checklistToBeEditted.id)
            if(checklist !== undefined){
                let usersWithAccess = this.props.bucketUsers.get(checklist.list.bucket)
                if(checklist.list.bucket === ""){
                    usersWithAccess = this.props.users
                }
                
               return usersWithAccess
            }
        }
        else {
            let res = []
            this.props.users.forEach((user) => {
                if (!user.deleted) {
                    res.push(user)
                }
            })
            return res
        }
    }

    getAssignAllUsers() {
        let selectionUsers = this.props.users;

        if(this.props.checklists && this.state.checkedChecklists.length > 0){
            this.state.checkedChecklists.forEach(cid => {
                let checklist = this.props.checklists.find(item => item.id === cid)
                if (checklist !== undefined){
                    let usersWithAccess = this.props.bucketUsers.get(checklist.list.bucket)
                    if(checklist.list.bucket === ""){
                        usersWithAccess = this.props.users
                    }
                    

                    let bucketUsersIntersection = selectionUsers
                    if( usersWithAccess !== undefined){
                        bucketUsersIntersection = usersWithAccess.filter((a) => bucketUsersIntersection.some(b=> b.user_id === a.user_id))
                    }
                    selectionUsers = bucketUsersIntersection
                }
            })
        }
        return selectionUsers
    }

    duplicateChecklist = (checklist) => {
        this.props.dispatch(duplicateChecklist(checklist.id, false))
    }

    fromNow = (time) => {
        return moment.unix(time).fromNow();
    }

    getDeadlineDiff = (deadline) => {
        const today = new Date()
        const [year, month, day] = deadline.split('-')
        const deadlineDate = new Date(year, month - 1, day)
        const timeDiff = deadlineDate.getTime() - today.getTime();
        const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));
        return diffDays
    }

    getDayString = (num) => {
        if (num == 1 || num == -1) return "day"
        return "days"
    }

    showQuickFix = (checklist) => {
        this.setState({showQuickFix : true, checklistToFix: checklist})
    }

    saveFixedAction = (action, update) => {
        this.props.dispatch(fixChecklist(action.id, action, "status", update))
    }

    getChecklistJSX(checklist, id) {
        let permissions = this.props.userInfo.access ? this.props.userInfo.access : []
        let filterByAssigneePayload = checklist.list.assignee
        let filterByAuthorPayload = checklist.list.user_id
        let checklistFilter = checklist.list.filter ? JSON.parse(checklist.list.filter).filter : []
        let columns = JSON.parse(checklist.list.data_columns)?.map(c => c.Name)
        let deadlineDiff = 0
        if(checklist.list.deadline){
            deadlineDiff = this.getDeadlineDiff(checklist.list.deadline)
        }
        let time = new Date()
        let uploadDiff = (time.getTime() / 1000) - checklist.list.upload_time

        let baseUrl = window.location.href.substring(0, window.location.href.indexOf("#"))

        const hasMissingColumns = checklist.missing_columns && checklist.missing_columns.length > 0

        return (
            <tr key={id} className="all-white-space-nowrap">
                <td className="width-40 text-align-center">
                    <input disabled={hasMissingColumns} checked={this.getCheckedState(checklist.id)} type="checkbox" onChange={() => this.checkChecklist(checklist.id)}/>
                </td>
                <td className="checklist-overview-name abc-click">
                    <div className='w-100 d-flex align-items-center'>
                        <a className='w-100 abc-click' style={{textDecoration:"none", color: "#304642"}} href={baseUrl + `#/checklist/${checklist.id}`}>
                            <div>
                                <span className='checklist-overview-dot-text'>{checklist.list.name}</span> <i data-tip data-for={`tooltip-${id}`} style={{display:"inline-block"}} className='fa fa-info-circle glyphicon-info-gray'></i>
                                <ReactTooltip className='checklist-overview-tooltip' id={`tooltip-${id}`} effect='solid' place='bottom'>
                                    <ChecklistDescription filter={checklistFilter} columns={columns} showColumnDescription={false} columnDescription={{}} description={checklist.list.description} filterDescription={getDataDescriptionDialogDescription("Action list")(checklist.list.limit, checklist.list.sort_direction, checklist.list.sort_column, checklistFilter)}/>
                                </ReactTooltip>
                            </div>
                        </a>
                        {hasMissingColumns && 
                            <i 
                                className='d-inline-block fa fa-warning text-danger ml-2' 
                                aria-hidden="true" 
                                data-tip data-for={`warning-tooltip-${id}`}
                                onClick={() => this.showQuickFix(checklist)}
                            ></i>
                        }
                        <ReactTooltip className='checklist-overview-tooltip' id={`warning-tooltip-${id}`} effect='solid' place='bottom'>
                            This Action contains columns that are no longer available. Click to do a quick fix.
                        </ReactTooltip>
                    </div>
                </td>
                <td className="vertical-align-middle min-width-150 checklist-overview-progress abc-click">
                <a className='abc-click' style={{textDecoration:"none", color: "#304642"}} href={baseUrl + `#/checklist/${checklist.id}`}><div style={{width:"100%"}}>
                    <div className='checklist-progress-wrapper'>
                        <Progress color={"success"} type={"progress-bar-primary"} min={0} max={getChecklistStatus(checklist).total} current={getChecklistStatus(checklist).done} enablePropgressNumber />
                        <span className='progress-number-color' style={{float: "right", alignSelf: "flex-end", lineHeight: 1}}>{getChecklistStatus(checklist).done} of {getChecklistStatus(checklist).total}</span>
                    </div>
                </div></a>
                </td>
                <td className="">
                    <DateSpan secs={checklist.list.touch_time} />
                </td>
                <td className="">
                    <span className="filter-option" onClick={() => this.setAssigneeFilter(filterByAssigneePayload)} >{getFullName(this.props.users, checklist.list.assignee)}</span>
                </td>
                <td className="">
                    <span className="filter-option"  onClick={() => this.setAuthorFilter(filterByAuthorPayload)}>{getFullName(this.props.users, checklist.list.user_id)}</span>
                </td>
                <td className='' >
                    
                    <div style={{display: "flex", justifyContent: "end", height: "100%"}}>
                            <div data-tip data-for={`tooltip-update-${id}`}>
                            {checklist?.list?.bucket != "" ? 
                                checklist.list.automatic_update ? 
                                <img className="checklist-overview-updated-icon vertical-align-middle" alt="Automatic update" src={uploadDiff > 86400 ? "./img/data_refresh_red.svg" : "./img/data_refresh_black.svg"} />
                                :
                                <ResponsiveButton disabled={hasMissingColumns} label='Update' responsiveLabel='Updated' onClick={() => this.props.dispatch(updateChecklistItems(checklist.id, false))}/>
                            :
                                <i className="fa fa-clipboard" style={{fontSize: 20, verticalAlign: "middle", color: deadlineDiff < 0 ? "#d9534f" :"#565655"}} aria-hidden="true"></i>
                            }
                            
                            </div>
                    <ReactTooltip className='checklist-overview-tooltip' id={`tooltip-update-${id}`} effect='solid' place='bottom'>
                            {checklist?.list?.bucket != "" ?
                            <>
                                <span>Updated {this.fromNow(checklist.list.upload_time)}</span>
                                {!checklist.list.automatic_update && !hasMissingColumns && <><br/><i className='checklist-overview-deaddiff'>This Action does not update automatically</i></>}
                                {hasMissingColumns && <><br/><i className='checklist-overview-deaddiff'>This Action contains columns that are no longer available.</i></>}
                            </>:
                                <span>Pasted data from <DateSpan secs={checklist.list.upload_time} /></span>
                            }
                                
                    </ReactTooltip>
                    </div>
                    
                </td>
                <td className=''>
                    <ShowIf if={checklist.list.has_deadline}>
                    <div style={{display: "inline-block"}}>
                        <i data-tip data-for={`tooltip-deadline-${id}`} className="fa fa-clock-o" style={{fontSize: 20, verticalAlign: "middle", color: deadlineDiff < 0 ? "#d9534f" :"#565655"}} aria-hidden="true"></i>
                        <ReactTooltip className='checklist-overview-tooltip' id={`tooltip-deadline-${id}`} effect='solid' place='bottom'>
                            <span>Deadline: </span>
                            <DateSpan string={checklist.list.deadline} />
                            <br />
                            {deadlineDiff < 0 ? 
                            <i className='checklist-overview-deaddiff'>Overdue by {Math.abs(deadlineDiff)} {this.getDayString(deadlineDiff)}</i>
                            : 
                            <i className='checklist-overview-deaddiff'>{deadlineDiff} {this.getDayString(deadlineDiff)} until deadline</i>}
                        </ReactTooltip>
                    </div>
                    </ShowIf>
                </td>
                <td className="">
                    <ChecklistDropdown checklist={checklist} permissions={permissions} 
                        onClickEdit={() => this.openEditDialog(checklist)}
                        onClickRestore={() => this.unarchiveChecklist(checklist)}
                        onClickArchive={() => this.openArchiveDialog(checklist)}
                        onClickDelete={() => this.openDeleteDialog(checklist)}
                        onClickAssign={() => this.openAssignDialog(checklist)}
                        onClickChangeAuthor={() => this.openAuthorDialog(checklist)}
                        onClickDuplicate={() => this.duplicateChecklist(checklist)}
                        onClickEditName={() => this.openEditNameDialog(checklist)}
                        onClickLayout={() => this.openLayoutDialog(checklist)}
                        />
                </td>
            </tr>
        )
    }

    getDeletedChecklistJSX(checklist, id) {
        let checklistFilter = checklist.list.filter ? JSON.parse(checklist.list.filter).filter : []
        let columns = JSON.parse(checklist.list.data_columns).map(c => c.Name)
        return (
            <tr key={id} className="all-white-space-nowrap" style={{height: 41}}>
                <td style={{borderRight: "none"}}>
                    <button className='btn btn-default btn-xs' title='Restore checklist' onClick={() => this.unarchiveChecklist(checklist)}><i className="fa fa-undo" aria-hidden="true"></i></button>
                </td>
                <td className="min-width-150 width-50-p">
                    <span className='checklist-overview-dot-text'>{checklist.list.name}</span> <i data-tip data-for={`tooltip-deleted-${id}`} style={{display:"inline-block"}} className='fa fa-info-circle glyphicon-info-gray'></i>
                    <ReactTooltip className='checklist-overview-tooltip' id={`tooltip-deleted-${id}`} effect='solid' place='bottom'>
                        <ChecklistDescription filter={checklistFilter} columns={columns} showColumnDescription={false} columnDescription={{}} description={checklist.list.description} filterDescription={getDataDescriptionDialogDescription("Action list")(checklist.list.limit, checklist.list.sort_direction, checklist.list.sort_column, checklistFilter)}/>
                    </ReactTooltip>
                </td>
                <td className="min-width-70">
                    <DateSpan secs={checklist.list.touch_time} />
                </td>
                <td className="min-width-70">
                    <span className="" title={getFullName(this.props.users, checklist.list.assignee)} >{getInitials(this.props.users, checklist.list.assignee)}</span>
                </td>
                <td className="min-width-70">
                    <span className="" title={getFullName(this.props.users, checklist.list.user_id)} >{getInitials(this.props.users, checklist.list.user_id)}</span>
                </td>
                <td className="" >
                    <div style={{display: "flex", justifyContent: "end", alignItems: "center"}}>
                        <button className='btn btn-danger btn-xs' onClick={() => this.openDeleteDialog(checklist)}>Delete</button>
                    </div>
                </td>
            </tr>
        )
    }
    getAssigneeValue = () => {
        if (this.state.assignee_filter == null){
            return null
        }
        return {value: this.state.assignee_filter, label: getFullName(this.props.users, this.state.assignee_filter)}
    }

    getCreatedByValue = () => {
        if(this.state.author_filter == null){
            return null
        }
        return {value: this.state.author_filter, label: getFullName(this.props.users, this.state.author_filter)}
    }

    getDatasourceValue = () => {
        if (this.state.bucket_filter == null) {
            return null
        }
        return {value: this.state.bucket_filter, label: this.state.bucketNameMap[this.state.bucket_filter]}
    }

    updateSearchInput = (e) => {
        let value = e.target.value
        if(value === ""){
            this.setState({apiFilterOptions: {...this.state.apiFilterOptions, search: value}})
        }
        this.setState({searchInput: value})
    }

    onCreatedByChange = (val, e) => {
        if(e.action == "clear"){
            this.removeAuthorFilter()
        } else if (e.action == "select-option") {
            this.setAuthorFilter(val.value)
        }
    }

    onAssignedToChange = (val, e) => {
        if(e.action == "clear"){
            this.removeAssigneeFilter()
        } else if (e.action == "select-option") {
            this.setAssigneeFilter(val.value)
        }
    }

    onDatasourceChange = (val, e) => {
        if(e.action == "clear"){
            this.removeDatasourceFilter()
        } else if (e.action == "select-option") {
            this.setDatasourceFilter(val.value)
        }
    }

    toggleArchiveView = () => {
        if (this.state.statusFilter === 'archived') {
            this.setStatusFilter('active')
        } else {
            this.setStatusFilter('archived')
        }
    }

    getArchiveText = () => {
        if (this.state.statusFilter === 'archived') {
            return `See Active Action lists (${this.stats.active})`
        } else {
            return `See Deleted Action lists (${this.stats.archived})`
        }
    }

    onEditClose = () => {
        this.setState({editChecklist: null, showEditChecklist: false})
    }

    getMultiSelectOptions = () => {
        let checklistText = this.state.checkedChecklists.length > 1 ? "Action lists" : "Action list";
        return [
            {
                label: (
                    <span>
                        Assign <strong>{this.state.checkedChecklists.length}</strong> {checklistText}
                    </span>
                ),
                onClick: () => {
                    this.openMultiAssignDialog(0)
                },
            },
            {
                label: (
                    <span>
                        Change layout for <strong> {this.state.checkedChecklists.length} </strong> {checklistText} </span>
                ),
                onClick: () => {
                    this.openLayoutDialog()
                },
            },
            {
                label: (
                    <span>
                        Change author on <strong>{this.state.checkedChecklists.length}</strong> {checklistText}
                    </span>
                ),
                onClick: () => {
                    this.openMultiAuthorDialog()
                },
            },
            this.state.statusFilter === "archived"
                ? {
                      label: (
                          <span>
                              Delete <strong>{this.state.checkedChecklists.length}</strong> {checklistText} (permanently)
                          </span>
                      ),
                      onClick: () => {
                          this.openDeleteAllDialog()
                      },
                      type: MultiSelectButtonType.WARNING,
                  }
                : {
                      label: (
                          <span>
                              Delete <strong>{this.state.checkedChecklists.length}</strong> {checklistText}
                          </span>
                      ),
                      onClick: () => {
                          this.openArchiveAllDialog()
                      },
                      type: MultiSelectButtonType.WARNING,
                  },
        ]
    }

    getLayoutPreview = () => {
        let layout = JSON.parse(this.state.chosenLayout)

        return layout.map((l,i) => 
        <div key={i} className={`margin-right-10px checklist-layout-preview inline-block ${l.color === 'black' ? 'btn-dark' : l.color === 'btn-primary' ? 'btn-old-primary' : l.color}`}>
                {l.color === 'black' ? <span className="white">{l.name}</span> : <span>{l.name}</span>}
        </div>)
    }

    getLayoutWarningLabel = () => {
        let layout = JSON.parse(this.state.chosenLayout)
        return <>All items in the chosen checklists will be moved to category <i>"{layout[0].name}"</i>, do you want to continue?</>
    }

    hasFilter = () => {
        return (this.state.assignee_filter || this.state.author_filter || this.state.bucket_filter || this.state.searchInput !== "")
    }

    render() {
        if(!this.props.users || Object.keys(this.state.bucketNameMap).length === 0) return null
        let checklists = this.props.checklists ? this.props.checklists : []
        let preparedChecklists = this.getChecklistsToShow(checklists)
        let deletedChecklists = this.getDeletedChecklistsToSHow(checklists)
        let assignees = new Set()
        let authors = new Set()
        checklists.forEach((c) => {
            assignees.add(c.list.assignee)
            authors.add(c.list.user_id)
        })
        
        let assigneesOptions = [];
        let authorOptions = []; 
        if (this.state.tooManyActions) {
            assigneesOptions = this.props.users.map((u) => {
                return {value: u.id, label: u.firstname + " " + u.lastname}
            }).sort((a, b) => a.label.localeCompare(b.label));
            authorOptions = assigneesOptions
        } else {
            assigneesOptions = Array.from(assignees).map((u) => {
                return {value: u, label: getFullName(this.props.users, u)}
            }).sort((a, b) => a.label.localeCompare(b.label));
            authorOptions = Array.from(authors).map((u) => {
                return {value: u, label: getFullName(this.props.users, u)}
            }).sort((a, b) => a.label.localeCompare(b.label));
        }
        
        let buckets = new Set()
        if (!!this.props.checklists && !this.state.tooManyActions){
            this.props.checklists.forEach((status) => {
                if (!!status.list && status.list.bucket != "") {
                    buckets.add(status.list.bucket)
                }
            })
        } else if(this.state.tooManyActions && !!this.props.users && !!this.props.bucketDetails){
            this.props.bucketDetails.keys().forEach(k => {
                const info = this.props.bucketDetails.get(k)?.info
                if(!!info && info.id != ""){
                    buckets.add(info.id);
                }
            })
        }
        
        let bucketOptions = Array.from(buckets).filter(b => b != "").map((bucket) => ({value: bucket, label: this.state.bucketNameMap[bucket]}))
            .sort((a, b) => a.label.localeCompare(b.label));
        const allColumns = this.props.buckets?.get(this.state.checklistToFix?.list.bucket)?.model.columns
        
        return (
            <div className="container-fluid">
                <div className="checklist-overview-title">
                    <h1 className="pt-3 margin-bottom-5px">Actions</h1>

                    <div style={{ marginBottom: 15, marginTop: 15, gap: 4 }} className='d-flex align-items-center'>
                        {this.props.orgInfo && this.props.orgInfo.enable_action_templates && (this.props.templates && this.props.templates.enabled) &&
                            <button className='btn btn-primary btn-sm' onClick={() => this.setState({showCreateAction: true})}>
                                <FaPlus className='d-inline-block mr-1'/> New Action 
                            </button>
                        }
                        <Link to="/layouts">
                            <button className="btn btn-default btn-sm">Manage layouts</button>
                        </Link>
                    </div>
                </div>

                <div className="checklist-overview-toolbar">
                    <div className="checklist-overview-filters">
                        <Select 
                            options={assigneesOptions} 
                            isClearable 
                            onChange={this.onAssignedToChange} 
                            className={"fixed-slicer-select margin-left-10"} 
                            classNamePrefix="fixed-slicer-select" 
                            placeholder="Assigned to" 
                            value={this.getAssigneeValue()} 
                        />

                        <Select 
                            options={authorOptions} 
                            isClearable 
                            onChange={this.onCreatedByChange} 
                            className={"fixed-slicer-select margin-left-10"} 
                            classNamePrefix="fixed-slicer-select" 
                            placeholder="Created by" 
                            value={this.getCreatedByValue()} 
                        />
                        
                        <Select
                            options={bucketOptions} 
                            isClearable 
                            onChange={this.onDatasourceChange} 
                            className={"fixed-slicer-select margin-left-10"} 
                            classNamePrefix="fixed-slicer-select" 
                            placeholder="Datasource" 
                            value={this.getDatasourceValue()} 
                        /> 
                    </div>
                    <div className="checklist-overview-search">
                        <div style={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
                            <ShowIf if={!this.props.loading}>
                                <span className="vertical-align-middle" style={{ marginRight: 8 }}>{`Showing ${!this.state.tooManyActions || this.hasFilter() ? this.shownChecklistCount : 0} of ${!!this.state.tooManyActions ? this.props.actionCount : this.totalChecklistCount}`}</span>
                            </ShowIf>
                        </div>
                        <div className="input-group margin-top-0" id="searchBarContainer">
                            <input 
                                id="searchIdInput" 
                                type='search'
                                className={`form-control form-control-sm zero-border-right ${this.state.searchInput ? "alert-warning" : ""}`} 
                                placeholder={`Search`} 
                                onChange={(e) => this.updateSearchInput(e)} 
                                onKeyDown={(e) => {if(e.key === "Enter" && this.state.tooManyActions) this.setState({apiFilterOptions: {...this.state.apiFilterOptions, search: this.state.searchInput}})}}
                                value={this.state.searchInput} 
                            />
                            <span title="Search beginning" className={`input-group-append ${this.state.searchInput ? "alert-warning" : ""}`}>
                                <button className="btn btn-sm btn-default" type="button" onClick={() => {if(this.state.tooManyActions) this.setState({apiFilterOptions: {...this.state.apiFilterOptions, search: this.state.searchInput}})}}>
                                    <i className="fa fa-search"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
                <MultiSelectBar type="Action list" selected={this.state.checkedChecklists} onDeselectClick={() => { 
                    this.setState({ checkedChecklists: []}); this.allChecklistsChecked = false}} options={this.getMultiSelectOptions()} />
                <ShowIf if={!this.props.loading && !!this.state.tooManyActions && checklists.length === 0 && !this.hasFilter()}>
                    <div className='p-4 h5'>
                        <div>Your Actions are heavy to load. Please use the filters or search bar to reach the desired Actions faster.</div>
                        <br />
                        <div className='mb-2'>Or load anyways by clicking here:</div>
                        <button className='btn btn-secondary' onClick={() => {this.setState({tooManyActions: false}); this.props.dispatch(getChecklistsStatus())}}>Load content</button>
                    </div>
                </ShowIf>
                <ShowIf if={this.props.loading}>
                    <LoadingAnimation />
                </ShowIf> 
                <ShowIf if={!this.props.loading && (!this.state.tooManyActions || this.hasFilter())}>
                    <ChecklistStatusTable>
                        <thead>
                            <tr className="all-white-space-nowrap">
                                <th className="width-40 text-align-center">
                                    <input type="checkbox" checked={this.allChecklistsChecked} onChange={() => this.checkAllChecklists()} />
                                </th>
                                {/* <th className="width-40 text-align-center"></th> */}
                                <th onClick={() => this.setSort("name")}>
                                    Action list <SortArrow sortDirection={this.state.sortDirection} mySymbol="name" currentSymbol={this.state.sort} />
                                </th>
                                <th onClick={() => this.setSort("status")}>
                                    Status <SortArrow sortDirection={this.state.sortDirection} mySymbol="status" currentSymbol={this.state.sort} />
                                </th>
                                <th onClick={() => this.setSort("activity")}>
                                    Last activity <SortArrow sortDirection={this.state.sortDirection} mySymbol="activity" currentSymbol={this.state.sort} />
                                </th>
                                <th onClick={() => this.setSort("assignee")}>
                                    Assigned to <SortArrow sortDirection={this.state.sortDirection} mySymbol="assignee" currentSymbol={this.state.sort} />
                                </th>
                                <th onClick={() => this.setSort("author")}>
                                    Created by <SortArrow sortDirection={this.state.sortDirection} mySymbol="author" currentSymbol={this.state.sort} />
                                </th>
                                {/* <th onClick={() => this.setSort('deadline')} >Deadline <SortArrow sortDirection={this.state.sortDirection} mySymbol='deadline' currentSymbol={this.state.sort} /></th>
                                <th onClick={() => this.setSort('creation')} >Created <SortArrow sortDirection={this.state.sortDirection} mySymbol='creation' currentSymbol={this.state.sort} /></th> */}
                                <th></th>
                                <th></th>
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{preparedChecklists}</tbody>
                    </ChecklistStatusTable>

                    <button className="btn btn-default btn-sm float-right margin-bottom-10px" onClick={() => this.setState({ showDeleted: true })}>
                        {this.getArchiveText()}
                    </button>
                </ShowIf>

                <TextInputDialog ref="changeNameDialog" title="Change Action list name" saveHandler={(n) => this.saveChecklistName(n)} closeHandler={() => this.closeHandler()} />

                <AssignUserDialog ref="assigneeDialog" title="Change assignee" saveHandler={(a, m, ud, d) => this.saveChecklistAssignee(a, m, ud, d)} closeHandler={() => this.closeHandler()} users={this.getAssignUsers()} sort={{ sortBy: "firstname", order: "asc" }} showUserPredicate={(u) => canUseChecklists(u.access) && this.canAccessBucket(u) && !u.is_consultant} />

                {/* Multi */}
                <AssignUserDialog ref="multiAssigneeDialog" title="Change assignee" saveHandler={(a, m, ud, d) => this.multiSaveChecklistAssignee(a, m, ud, d)} closeHandler={() => this.closeHandler()} users={this.getAssignAllUsers()} sort={{ sortBy: "firstname", order: "asc" }} showUserPredicate={(u) => canUseChecklists(u.access) && this.canAccessAllBuckets(u) && !u.is_consultant} />

                <UserOptionDialog ref="authorDialog" title="Change author" saveHandler={(a) => this.saveChecklistAuthor(a)} closeHandler={() => this.closeHandler()} users={this.props.users} sort={{ sortBy: "firstname", order: "asc" }} showUserPredicate={(u) => canUseChecklists(u.access) && this.canAccessBucket(u) && !u.is_consultant} />

                {/* Multi */}
                <UserOptionDialog ref="multiAuthorDialog" title="Change author" saveHandler={(a) => this.multiSaveChecklistAuthor(a)} closeHandler={() => this.closeHandler()} users={this.props.users} sort={{ sortBy: "firstname", order: "asc" }} showUserPredicate={(u) => canUseChecklists(u.access) && this.canAccessAllBuckets(u) && !u.is_consultant} />

                {this.state.showEditChecklist && this.state.editChecklist && <EditChecklistDialog checklist={this.state.editChecklist} onClose={this.onEditClose} redirect={false} />}

                <SaveWithWarningDialog
                    ref="layoutDialog"
                    title="Select layout"
                    warningLabel={this.getLayoutWarningLabel()}
                    saveText="Save and update"
                    saveHandler={() => this.saveLayouts()}
                    closeHandler={() => {
                        this.closeHandler()
                    }}
                    shouldConfirm={this.shouldLayoutConfirm}
                >
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <div className="standard-layout-text">Select a standard layout</div>
                        <select
                            value={this.state.chosenLayout}
                            className="form-control form-control-sm inline-block width-150px"
                            onChange={(e) => {
                                this.setState({ chosenLayout: e.target.value })
                            }}
                        >
                            <option value={DEFAULT_LAYOUT}>Default</option>
                            {this.props?.layouts?.map((l) => (
                                <option key={l.id} value={l.layout}>
                                    {l.name}
                                </option>
                            ))}
                        </select>
                    </div>
                    <DividerText label="Preview" style={{ marginTop: 10 }} />
                    <div className="margin-top-10px margin-bottom-10px single-checklist-category-wrapper checklist-overview-preview-box">{this.getLayoutPreview()}</div>
                    {/* <hr className='hr-color'/>
                    <div style={{display: "flex", justifyContent: "center", marginTop: 10}}>
                        <Link to="/layouts">
                            <div className="" >
                                <button className="btn btn-default btn-sm" onClick={() => {this.refs.layoutDialog.hide(); this.closeHandler()}}>Manage layouts</button>
                            </div>
                        </Link>
                    </div> */}
                </SaveWithWarningDialog>

                <GenericDialog title="Deleted Action lists" show={this.state.showDeleted} onClose={() => this.setState({ showDeleted: false })} style={{ width: "100%", maxWidth: "80%" }}>
                    <i className="float-left" style={{ color: "#979797" }}>
                        Action lists here will be deleted permanently after 30 days
                    </i>
                    <button className="btn btn-danger btn-xs float-right" onClick={() => this.openDeleteAllDialog()} style={{ marginBottom: 5 }}>
                        Delete all
                    </button>
                    <ChecklistStatusTable>
                        <thead>
                            <tr className="all-white-space-nowrap">
                                {/* <th className="width-40 text-align-center"></th> */}
                                <th></th>
                                <th>Action list</th>
                                <th>Deletion date </th>
                                <th>Assigned to </th>
                                <th>Created by </th>
                                {/* <th onClick={() => this.setSort('deadline')} >Deadline <SortArrow sortDirection={this.state.sortDirection} mySymbol='deadline' currentSymbol={this.state.sort} /></th>
                                <th onClick={() => this.setSort('creation')} >Created <SortArrow sortDirection={this.state.sortDirection} mySymbol='creation' currentSymbol={this.state.sort} /></th> */}
                                <th></th>
                            </tr>
                        </thead>
                        <tbody>{deletedChecklists}</tbody>
                    </ChecklistStatusTable>
                    {deletedChecklists.length < 1 ? (
                        <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
                            <span style={{ textAlign: "center" }}>No Action lists to show</span>
                        </div>
                    ) : null}
                </GenericDialog>

                {!!this.state.checklistToFix && !!allColumns && <FixActionDialog
                    actionStatus={this.state.checklistToFix}
                    show={this.state.showQuickFix}
                    onClose={() => this.setState({showQuickFix: false, checklistToFix: null})}
                    onSave={(action, update) => {
                        this.saveFixedAction(action, update);
                        this.setState({showQuickFix: false, checklistToFix: null})
                    }}
                    allColumns={allColumns}
                />}
                
                <CreateActionDialog 
                    history={this.props.history}
                    onClose={() => this.setState({showCreateAction: false})}
                    show={this.state.showCreateAction}
                    user={this.props.userInfo}
                    users={this.props.users}
                    dispatch={this.props.dispatch}
                />

                <DeleteChecklistDialog ref="deleteChecklistDialog" accept={() => this.deleteChecklist()} closeHandler={() => this.cancelDialog("deleteChecklistDialog")} />

                <DeleteChecklistDialog ref="deleteAllChecklistDialog" all={true} accept={() => this.deleteAllChecklists()} closeHandler={() => this.cancelDialog("deleteAllChecklistDialog")} />

                <WarningAcceptDialog
                    title="Delete Action list"
                    ref="archiveAcceptDialog"
                    acceptText="Delete"
                    accept={() => {
                        this.archiveChecklist(this.state.checklistToBeEditted.id)
                    }}
                    closeHandler={() => this.closeHandler()}
                >
                    <span>
                        Are you sure you want to delete the Action list "<i>{this.state.checklistToBeEditted?.name}</i> "?
                    </span>
                </WarningAcceptDialog>

                <WarningAcceptDialog
                    title="Delete multiple Action lists"
                    ref="archiveMultiAcceptDialog"
                    acceptText="Delete"
                    accept={() => {
                        this.archiveChecklists()
                    }}
                    closeHandler={() => this.cancelDialog("archiveMultiAcceptDialog")}
                >
                    <span>Are you sure you want to delete these Action lists?</span>
                    <ul style={{maxHeight:500, overflowY: "auto"}}>
                        {this.state.checkedChecklists.map((c, i) => {
                            return <li key={i}>{this.getChecklist(c).list.name}</li>
                        })}
                    </ul>
                </WarningAcceptDialog>
            </div>
        )
    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return  (JSON.stringify(curState.checkedChecklists) !== JSON.stringify(prevState.checkedChecklists) ||
            curState.sort !== prevState.sort ||
            curState.sortDirection !== prevState.sortDirection ||
            curState.assignee_filter !== prevState.assignee_filter ||
            curState.author_filter !== prevState.author_filter ||
            curState.statusFilter !== prevState.statusFilter)
}

const getSettingsKeys = (state) => {
    let {checkedChecklists, showEditChecklist, editChecklist, ...settings} = state
    let keys = Object.keys(settings)
    if(!keys.includes("assignee_filter")) keys.push("assignee_filter")
    if(!keys.includes("author_filter")) keys.push("author_filter")
    return keys
}

const getSessionStoreKey = _this => {
    return "checklist-status"
}

ChecklistStatus = withSettingsStorage(ChecklistStatus, getSettingsKeys, shouldComponentUpdateSettings, null, null, getSessionStoreKey, null, null, "checklist-status", {withSessionStore: true})

ChecklistStatus = connect(mapStateToProps)(ChecklistStatus)

export default ChecklistStatus

class ChecklistDescription extends React.Component{
    render() {
        return (
            <React.Fragment>
                <ShowIf if={this.props.description}>
                    <div>
                    <p><b>Description</b></p>
                        <ReactMarkdown children={this.props.description} />
                    </div>
                    {/* <hr className="full-width-hr margin-top-20px margin-bottom-20px" /> */}
                </ShowIf>
                <p><b>Filters</b></p>
                <div>
                    {this.props.filterDescription}
                </div>
                <br />
                <ul>
                    {
                        this.props.filter.map((f, i) => (
                            <li key={i}>
                                {f.column}&nbsp;
                                {getCompareTypeSymbol(f.compare_type)}&nbsp;
                                {
                                    f.target_values.length == 0 ? "No value"
                                    : (f.compare_type === 9 || f.compare_type === 12 || f.compare_type === 7 || f.compare_type === 8 || f.compare_type === 10 || f.compare_type === 11 ? '"' + f.target_values.join('", "') + '"' : f.target_values.join(", "))
                                }
                            </li>
                        ))
                    }
                    {
                        this.props.filter.length === 0 ? <li>All rows are included (no filters have been applied)</li> : null
                    }
                </ul>
                <ShowIf if={this.props.showColumnDescription}>
                    <hr className="full-width-hr margin-top-20px margin-bottom-20px" />
                    <table className="table table-condensed table-hover table-striped table-bordered">
                        <thead>

                            <tr>
                                <th>Column</th>
                                <th>Description</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                this.props?.columns?.map?.(c => (
                                    <tr key={c}>
                                        <td>{c}</td>
                                        <td data-tip data-for={`tooltip-${c}`}>
                                            <WordWrap>
                                                {this.props.columnDescription[c] ? this.props.columnDescription[c] : ""}
                                            </WordWrap>
                                            <ShowIf if={this.props.columnDescription[c]}>
                                                <ReactTooltip id={`tooltip-${c}`} type='dark' effect='solid' place='right'> <ReactMarkdown children={this.props.columnDescription[c]}/></ReactTooltip>
                                            </ShowIf>
                                        </td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </ShowIf>
            </React.Fragment>
        )
    }
}
