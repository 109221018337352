import React, { useState } from "react";
import { DataBucket, QueryCriterion } from "../../types/transfertypes";
import {
    CxmMultipleAggregationQueryResult,
    CxmColumn,
    End2EndColumnSettings,
    getCatName,
    End2EndSettingsMap,
    End2EndSettings,
} from "../../types/cxmtypes";
import End2EndItem from "./End2EndItem";
import { findColorForCategorization } from "../../helpers/GeneralHelpers";
import { CompareType } from "../../types/slicertypes";
import AddSlicerDialog2 from "../Dialogs/AddSlicerDialog2";

import styles from "../../css/End2EndColumn.module.css";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import { Dispatch, AnyAction } from "redux";
import { getSlicerValues } from "../../actions/ReportActions";
import Drawer from "../Animation/Drawer";
import { Arrow } from "../Report/Slicer2";
import ShowIf from "../Generic/ShowIf";
// @ts-ignore
import { Link, useParams } from "react-router-dom";
import { formatNumber } from "../../helpers/numberHelpers";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    c: CxmColumn;
    bucket: DataBucket;
    data: CxmMultipleAggregationQueryResult;
    settings: End2EndColumnSettings;
    updateSettings: (updateFunc: (setting: End2EndColumnSettings) => void) => void;
    fullSettings: End2EndSettings;
    showGrid: (extraFilter?: { category: string; value: string }) => void;
    dispatch: Dispatch<AnyAction>;
}

const End2EndColumn = ({
    c,
    bucket,
    data,
    settings,
    updateSettings: updateSettingsProps,
    fullSettings,
    dispatch,
    ...props
}: Props) => {
    const updateSettings = (updateFunc: (column: End2EndColumnSettings) => void) => {
        updateSettingsProps((s) => {
            updateFunc(s);
            //Pruning empty filters
            s.filter = s.filter.filter((f) => f.target_values.length > 0);
        });
    };
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [chosenFilter, setChosenFilter] = useState<QueryCriterion>();
    const [addSlicerSearchStr, setAddSlicerSearchStr] = useState("");

    const [showFilterList, setShowFilterList] = useState(false);

    const { id } = useParams();

    const { confirm, ConfirmDialog } = useConfirmDialog();

    const toggleCategoryFilter = (value: string) => {
        updateSettings((s) => {
            const filterIndex = s.filter.findIndex(
                (f) => f.column === getCatName(s.use_second_category, c) && f.compare_type == CompareType.EQ,
            );

            if (filterIndex != -1) {
                if (s.filter[filterIndex].target_values.includes(value)) {
                    s.filter[filterIndex].target_values = s.filter[filterIndex].target_values.filter(
                        (t) => t !== value,
                    );
                } else {
                    s.filter[filterIndex].target_values.push(value);
                }
            } else {
                let filter: QueryCriterion = {
                    column: getCatName(s.use_second_category, c),
                    compare_type: CompareType.EQ,
                    target_values: [value],
                    is_column: false,
                };
                s.filter.push(filter);
            }
        });
    };

    // const setCategoryFilter = (values: string[]) => {
    //     updateSettings((s) => {
    //         const filterIndex = s.filter.findIndex((f) => f.column === getCatName(s.use_second_category, c));

    //         if (filterIndex != -1) {
    //             s.filter[filterIndex].target_values = values;
    //         } else {
    //             let filter: QueryCriterion = {
    //                 column: getCatName(s.use_second_category, c),
    //                 compare_type: CompareType.EQ,
    //                 target_values: values,
    //                 is_column: false,
    //             };
    //             s.filter.push(filter);
    //         }
    //     });
    // };

    const saveFilter = (filter: QueryCriterion) => {
        updateSettings((s) => {
            const filterIndex = s.filter.findIndex((f) => f.column === filter.column);

            if (filterIndex != -1) {
                s.filter[filterIndex] = filter;
            } else {
                s.filter.push(filter);
            }
        });
        setShowAddFilter(false);
        setChosenFilter(undefined);
    };

    const getSlicerColumns = () => {
        let columns: string[] = bucket.info.model.columns.map((c) => c.name);
        columns.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        return columns;
    };

    const columnClick = (column: string) => {
        const filter = settings.filter.find((f) => f.column == column);
        if (filter != undefined) {
            setChosenFilter(filter);
        } else {
            const f: QueryCriterion = {
                column: column,
                compare_type: CompareType.EQ,
                target_values: [],
                is_column: false,
            };
            setChosenFilter(f);
        }
        dispatch(getSlicerValues(bucket.info.id, { column: column, filter: [] }));
        setShowAddFilter(true);
    };

    const removeFilter = async (filter: QueryCriterion) => {
        const answer = await confirm(
            <span>
                This will remove the filter on <i>{filter.column}</i>, are you sure?
            </span>,
        );
        if (answer) {
            updateSettings((s) => {
                const filterIndex = s.filter.findIndex((f) => f.column == filter.column);
                if (filterIndex < 0) return;
                s.filter.splice(filterIndex, 1);
            });
        }
    };

    const swapCategory = () => {
        updateSettings((s) => {
            s.use_second_category = !s.use_second_category;
        });
    };

    const categoryFilter = settings.filter.find((f) => f.column == getCatName(settings.use_second_category, c));

    const cards = (
        bucket.info.model.categorization_name == getCatName(settings.use_second_category, c)
            ? bucket.info.model.categorization.map((cat) => cat.name)
            : (bucket.info.model.categorizations
                  .find((cat) => cat.name == getCatName(settings.use_second_category, c))
                  ?.categorization.map((cat) => cat.name) ?? [])
    ).sort((a, b) => a.toLowerCase().localeCompare(b.toLowerCase()));

    let columns: string[] = getSlicerColumns();

    const filterList = settings.filter.map((f, i) => {
        return (
            <div className={styles.filterBox} key={i} onClick={() => columnClick(f.column)}>
                <span title={f.column} className={"line-clamp-1"}>
                    {f.column}
                </span>
                <span> ({f.target_values.length})</span>
                <i
                    onClick={(e) => {
                        e.stopPropagation();
                        removeFilter(f);
                    }}
                    className={`fa fa-times ${styles.closeButton}`}
                    aria-hidden="true"
                ></i>
            </div>
        );
    });

    const getButtonColor = (bool: boolean) => {
        if (bool) {
            return "btn-default active";
        } else {
            return "btn-default";
        }
    };

    const getCards = () => {
        const total = Object.values(data.aggregation.segments).reduce((a, b) => a + b[settings.aggregation_type], 0);
        if (!settings.use_second_category) {
            return (
                <div className={styles.firstContainer}>
                    {cards.map((k) => {
                        let color: string | undefined = bucket.info.setup.categorization_colors[k];
                        if (color == undefined) {
                            color = findColorForCategorization(
                                getCatName(settings.use_second_category, c),
                                k,
                                bucket.info.model,
                            );
                        }
                        const isFiltered = categoryFilter?.target_values.includes(k) ?? false;

                        let value: number | undefined = data.aggregation.segments?.[k]?.[settings.aggregation_type];
                        if (settings.show_percentages && value != undefined) {
                            value = (value / total) * 100;
                        }
                        // let valueStr: string | undefined = value?.toFixed(settings.show_decimals ? 2 : 0);
                        // if (settings.show_percentages && valueStr != undefined) {
                        //     valueStr += "%";
                        // }
                        let valueStr: string | undefined = undefined
                        if (value != undefined) {
                            valueStr = formatNumber(value, {
                                percentage: settings.show_percentages, 
                                decimals: settings.show_decimals ? 2 : 0,
                                truncate: true,
                            })
                        }
                        let tmpSettings = structuredClone(fullSettings);
                        let sIndex = tmpSettings.buckets[bucket.id]?.filter.findIndex(
                            (f) =>
                                f.column == getCatName(settings.use_second_category, c) &&
                                f.compare_type == CompareType.EQ,
                        );
                        if (sIndex == undefined) {
                            tmpSettings.buckets[bucket.id] = structuredClone(settings);
                            sIndex = -1;
                        }
                        if (sIndex == -1) {
                            tmpSettings.buckets[bucket.id].filter.push({
                                column: getCatName(settings.use_second_category, c),
                                compare_type: CompareType.EQ,
                                target_values: [k],
                                is_column: false,
                            });
                        } else {
                            if (!tmpSettings.buckets[bucket.id].filter[sIndex].target_values.includes(k)) {
                                tmpSettings.buckets[bucket.id].filter[sIndex].target_values.push(k);
                            }
                        }
                        const location = {
                            pathname: `/end2end/${id}/grid`,
                            search: `?source=${bucket.id}`,
                            state: {settings: tmpSettings, extraTitle: k},
                        };
                        return (
                            <End2EndItem
                                key={k}
                                color={color}
                                name={k}
                                value={valueStr ?? "-"}
                                onClick={() => toggleCategoryFilter(k)}
                                isFiltered={isFiltered}
                                style={valueStr == undefined ? { opacity: 0.5 } : undefined}
                                location={location}
                                title={value ? formatNumber(value, {percentage: settings.show_percentages}) : "No value"}
                                unique={fullSettings.unique}
                            />
                        );
                    })}
                </div>
            );
        } else {
            const name = getCatName(settings.use_second_category, c);
            const cat = bucket.info.model.categorizations.find((x) => x.name === name);
            if (cat == undefined) return <h2>Category not found</h2>;
            let maxY = 0;
            let maxX = 0;
            cat.categorization.forEach((x) => {
                maxY = Math.max(x.position.y, maxY);
                maxX = Math.max(x.position.x, maxX);
            });
            let cardArray = [];
            for (let i = 0; i <= maxY; i++) {
                for (let j = 0; j <= maxX; j++) {
                    cardArray.push(<div key={`empty-${i * maxY + j}`} className={styles.emptyItem} />);
                }
            }
            cat.categorization.forEach((x) => {
                const pos = x.position.y * (maxX + 1) + x.position.x;

                let color: string | undefined = bucket.info.setup.categorization_colors[x.name];
                if (color == undefined) {
                    color = findColorForCategorization(
                        getCatName(settings.use_second_category, c),
                        x.name,
                        bucket.info.model,
                    );
                }
                const isFiltered = categoryFilter?.target_values.includes(x.name) ?? false;

                let value: number | undefined = data.aggregation.segments?.[x.name]?.[settings.aggregation_type];
                if (settings.show_percentages && value != undefined) {
                    value = (value / total) * 100;
                }
                // let valueStr: string | undefined = value?.toFixed(settings.show_decimals ? 2 : 0);
                // if (settings.show_percentages && valueStr != undefined) {
                //     valueStr += "%";
                // }
                let valueStr: string | undefined = undefined
                if (value != undefined) {
                    valueStr = formatNumber(value, {
                        percentage: settings.show_percentages, 
                        decimals: settings.show_decimals ? 2 : 0,
                        truncate: true,
                    })
                }

                let tmpSettings = structuredClone(fullSettings);
                let sIndex = tmpSettings.buckets[bucket.id]?.filter.findIndex(
                    (f) => f.column == getCatName(settings.use_second_category, c) && f.compare_type == CompareType.EQ,
                );
                if (sIndex == undefined) {
                    tmpSettings.buckets[bucket.id] = structuredClone(settings);
                    sIndex = -1;
                }
                if (sIndex == -1) {
                    tmpSettings.buckets[bucket.id].filter.push({
                        column: getCatName(settings.use_second_category, c),
                        compare_type: CompareType.EQ,
                        target_values: [x.name],
                        is_column: false,
                    });
                } else {
                    if (!tmpSettings.buckets[bucket.id].filter[sIndex].target_values.includes(x.name)) {
                        tmpSettings.buckets[bucket.id].filter[sIndex].target_values.push(x.name);
                    }
                }
                const location = {
                    pathname: `/end2end/${id}/grid`,
                    search: `?source=${bucket.id}`,
                    state: {settings: tmpSettings, extraTitle: x.name},
                };

                cardArray[pos] = (
                    <End2EndItem
                        key={x.name}
                        color={color}
                        name={x.name}
                        value={valueStr ?? "-"}
                        onClick={() => toggleCategoryFilter(x.name)}
                        isFiltered={isFiltered}
                        style={value == undefined ? { opacity: 0.5 } : undefined}
                        advanced
                        location={location}
                        title={value != undefined ? formatNumber(value, {percentage: settings.show_percentages}) : "No value"}
                        unique={fullSettings.unique}
                    />
                );
            });
            return (
                <div
                    className={styles.secondContainer}
                    style={{ gridTemplateColumns: `repeat(${maxX + 1}, minmax(0, 1fr))` }}
                >
                    {cardArray}
                </div>
            );
        }
    };

    const totalShown = Object.values(data.aggregation.segments).reduce((a, b) => a + b.count, 0);
    const total = data.total

    return (
        <>
            <div className={`d-flex flex-column align-items-center ${settings.hide_column ? styles.hide : ''} ${props.className ?? ""}`} style={{ gap: 10 }}>
                {/*<button onClick={() => console.log(bucket, data, settings)}>DEBUG</button>*/}
                <div style={{maxWidth: 200}} className={styles.title}>
                    <h3>{bucket.info.name}</h3>
                    <ShowIf if={c.categorizationColumn2 != ""}>
                    <button
                        className={`btn btn-default btn-xs ${getButtonColor(settings.use_second_category)}`}
                        onClick={swapCategory}
                        style={{ width: 25, height: 25 }}
                    >
                        <img aria-hidden="true" src="./img/switch.svg" style={{ width: "100%", height: "100%" }} alt="" />
                    </button>
                    </ShowIf>
                </div>
                <div className="d-flex align-items-center flex-wrap" style={{ gap: 5, width: 200 }}>
                    {/* Start of filter button */}
                    <div className="dropdown inline-block d-flex w-full vertical-align-top" style={{ gap: 5 }}>
                        <button
                            className="btn btn-sm btn-primary"
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fa fa-plus"></i>
                        </button>

                        {/* Button to add filters if no filters are applied. */}
                        {/* Placed here to make the dropdown be placed correctly */}
                        <ShowIf if={settings.filter.length == 0}>
                            <button
                                className={styles.filterButton}
                                style={{ justifyContent: "start", gap: 5, width: "100%", flexGrow: 1 }}
                                onClick={() => setShowFilterList((v) => !v)}
                                id="dropdownMenu2"
                                data-toggle="dropdown"
                            >
                                <i style={{ opacity: 0.5 }} className="fa fa-filter" aria-hidden="true"></i>
                                <span style={{ opacity: 0.5 }}>No filters</span>
                            </button>
                        </ShowIf>
                        <ShowIf if={settings.filter.length == 1}>{filterList}</ShowIf>
                        <ShowIf if={settings.filter.length > 1}>
                            <button className={styles.filterButton} onClick={() => setShowFilterList((v) => !v)}>
                                <i className="fa fa-filter" aria-hidden="true"></i>
                                <span>Filters ({settings.filter.length})</span>

                                <div onFocus={(e) => e.target.blur()} className={styles.filterDrawerButton}>
                                    <Arrow pose={showFilterList ? "visible" : "hidden"} className={"fa fa-angle-up"} />
                                </div>
                            </button>
                        </ShowIf>
                        <div
                            className="dropdown-menu scrollable-dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                            style={{ paddingTop: 0 }}
                        >
                            <div className="text-center px-2 sticky-modal-header" style={{ paddingTop: "6px" }}>
                                <input
                                    type="text"
                                    value={addSlicerSearchStr}
                                    placeholder="Search columns"
                                    className="form-control form-control-sm"
                                    onChange={(e) => setAddSlicerSearchStr(e.target.value)}
                                />
                                <div className="dropdown-divider"></div>
                            </div>
                            {columns
                                .filter(
                                    (c) =>
                                        addSlicerSearchStr === "" ||
                                        c.toLowerCase().includes(addSlicerSearchStr.toLowerCase()),
                                )
                                .map((col, index) => {
                                    const filter = settings.filter.find((f) => f.column == col);
                                    return (
                                        <button
                                            className="hover-cursor dropdown-item"
                                            type="button"
                                            onClick={() => columnClick(col)}
                                            key={index}
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <span>{col}</span>
                                                {filter != undefined && filter.target_values.length > 0 && (
                                                    <div style={{ gap: 5 }} className="d-flex align-items-center">
                                                        <span style={{ marginLeft: 5 }}>
                                                            ({filter.target_values.length})
                                                        </span>
                                                        <i
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                removeFilter(filter);
                                                            }}
                                                            className={`fa fa-times ${styles.closeButton}`}
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                )}
                                            </div>
                                        </button>
                                    );
                                })}
                        </div>
                    </div>
                    {/* End of filter button */}

                    <ShowIf if={settings.filter.length > 0}>
                        {settings.filter.length > 1 && (
                            <>
                                <Drawer expand={showFilterList}>
                                    <div className={styles.filterContainer}>{filterList}</div>
                                </Drawer>
                            </>
                        )}
                    </ShowIf>
                </div>

                {getCards()}

                <Link
                    className={styles.showingButton}
                    to={{
                        pathname: `/end2end/${id}/grid`,
                        search: `?source=${bucket.id}`,
                        state: {settings: fullSettings},
                    }}
                >
                    Showing {totalShown} of {total}
                </Link>
            </div>
            {chosenFilter != undefined && (
                <AddSlicerDialog2
                    show={showAddFilter}
                    slicer={chosenFilter}
                    slicers={settings.filter}
                    saveSlicer={saveFilter}
                    onCancel={() => {
                        setChosenFilter(undefined);
                        setShowAddFilter(false);
                    }}
                    bucket={bucket.info}
                />
            )}
            <ConfirmDialog />
        </>
    );
};

export default End2EndColumn;
