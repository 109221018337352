import React from "react";
import styles from "../../css/End2EndItem.module.css";
import { IoOpenOutline } from "react-icons/io5";
// @ts-ignore
import { Link } from "react-router-dom";
import { End2EndTransferSettings } from "../../views/End2EndGridView";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    name: string;
    value: string | number;
    color?: string;
    isFiltered?: boolean;
    advanced?: boolean;
    location: {pathname: string, search: string, state: End2EndTransferSettings}
    title: string
    unique: boolean
}

const End2EndItem = ({ name, value, isFiltered = false, advanced = false, unique, ...props }: Props) => {
    const barColor = props.color ?? stringToHashColor(name?.toString() ?? "Yeet");
    return (
        <div className={`${styles.container} ${advanced ? styles.advanced : ""} ${props.className ?? ""}`} {...props}>
            <div className={styles.decoy} />
            <div className={`${styles.extend} ${unique && value == '-' ? styles.enabled : ''}`}>
                <div className={styles.label}>
                    <span className={styles.labelBox} style={{ position: "relative" }}>
                        <div className={`${styles.checkbox} ${isFiltered ? styles.show : ""}`}>
                            <input readOnly type="checkbox" checked={isFiltered} />
                        </div>
                        <span title={name} className={styles.labelText}>
                            {name}
                        </span>
                    </span>
                </div>
                <div className={styles.bar} style={{ backgroundColor: barColor }} />
            </div>
            <Link style={{textDecoration: "none"}} to={props.location}>
                <div className={styles.content} title={props.title}>
                    <span className={styles.labelBox} style={{ position: "relative" }}>
                        <IoOpenOutline  className={`${styles.openIcon}`}/>
                        <span className={styles.labelValue}>{value}</span>
                    </span>
                </div>
            </Link>
        </div>
    );
};

export default End2EndItem;

// Function to generate a consistent color based on an input string
function stringToHashColor(input: string): string {
    const toHex = (num: number): string => {
        const hex = num.toString(16);
        return hex.length === 1 ? "0" + hex : hex;
    };

    const hashCode = (str: string): number => {
        let hash = 0;
        for (let i = 0; i < str.length; i++) {
            hash = str.charCodeAt(i) + ((hash << 5) - hash);
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    };

    // Generate hash from input string
    const hash = hashCode(input);

    // Convert hash to RGB components
    const r = (hash >> 16) & 0xff;
    const g = (hash >> 8) & 0xff;
    const b = hash & 0xff;

    // Convert RGB components to hex string
    const hexColor = `#${toHex(r)}${toHex(g)}${toHex(b)}`;

    return hexColor;
}
