import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import { setHomeDashboardForUser } from "../../actions/DashboardActions"
import { toggleFavouriteReports } from "../../actions/ReportActions"
import { saveUpdatedUser, updatePermissions } from "../../actions/UserActions"
import { getOrganizationUser } from "../../actions/OrganizationActions"
import OrganizationAdministrationEditHomePageSetup from "./OrganizationAdministrationEditHomePageSetup"
import OrganizationAdministrationEditInfo from "./OrganizationAdministrationEditInfo"
import OrganizationAdministrationEditPermissions from "./OrganizationAdministrationEditPermissions"
import AlertBanner, { alertTypes } from "../Alert/AlertBanner"
import OrganizationAdministrationEditAccessControl from "./OrganizationAdministrationEditAccessControl"
import { updateBucketAccess } from "../../actions/BucketActions"
import { getApiCallCount } from '../../helpers/ApiHelpers'
import ShowIf from "../Generic/ShowIf"

const mapStateToProps = (state, ownProps) => {
    return {
        orgUsers: state.Organization.users,
    }
}

function OrganizationAdministrationEdit(props) {
    const [activeTab, setActiveTab] = useState("info")
    const [updatedUserInfo, setUpdatedUserInfo] = useState(null)
    const [infoValidate, setInfoValidate] = useState(false)
    const [showExitPrompt, setShowExitPrompt] = useState(false)
    const [unsavedChanges, setUnsavedChanges] = useState(false)
    const [alertConfig, setAlertConfig] = useState(null)
    const [savedClicked, setSavedClicked] = useState(false)
    const [allDataLoaded, setAllDataLoaded] = useState(false)
    const [updatedFavouriteReports, setUpdatedFavouriteReports] = useState([])
    const [tabIndex, setTabIndex] = useState([])
    const [activeRequests, setActiveRequests] = useState(1)
    
    //Helper
    function getCurrentUser(user_id){
        let user = props.orgUsers.find((u) => user_id === u.id)
        return user
    }
    setInterval(() => { if (getApiCallCount() !== activeRequests) setActiveRequests(getApiCallCount()); }, 200)

    useEffect(() => {
        let user = getCurrentUser(props.userId)

        setUpdatedUserInfo(user)

        let favouriteReports = user.favourite_reports?.length > 0
                     ? user.favourite_reports.map((fr) => {
                        return { label: fr.report_name, value: fr.report_id }
                        })
                    : []
        setUpdatedFavouriteReports(favouriteReports)    
       
    }, [props.orgUsers])

    useEffect(()=> {
        let tIndex = [
                {id: "info", label: "Information", tabIndex: 1},
                {id: "permissions", label: "Permissions", tabIndex: 2},
                {id: "homepage", label: "Homepage setup", tabIndex: 4},
        ]

      if (tabIndex.length === 0) {  

        if (props.organizationInfo.use_access_control) {
            
            tIndex.splice(2, 0, {id: "access", label: "Access control", tabIndex: 3},)
        }
        setTabIndex(tIndex)
    }

    }, [tabIndex])

    useEffect(() => {
        if(updatedUserInfo){
            const informationChanged = unsavedInformation()
            const permissionsChanged = unsavedPermissions()
            const homeDashboardChanged = unsavedHomeDashboard()
            const favouriteReportsChanged = unsavedFavouriteReports()
            const accessControlChanged = unsavedAccessControl()

            if (informationChanged || permissionsChanged || homeDashboardChanged || favouriteReportsChanged || accessControlChanged) {
                setUnsavedChanges(true)
            } else {
                setUnsavedChanges(false)
            }
        }

        if(!allDataLoaded) {
            setAllDataLoaded(true)
            props.dispatch(getOrganizationUser(props.organizationInfo.id, props.userId))
        }

    }, [updatedUserInfo, updatedUserInfo?.permissions, props.userId, props.orgUsers, updatedUserInfo?.home_dashboard, updatedFavouriteReports, updatedUserInfo?.bucket_access])

    useEffect(() => {

        if (savedClicked && !unsavedChanges) {

            closeEditModalHandler()
            setSavedClicked(false)
        }

        if (unsavedChanges && showExitPrompt) {
            const config = {
                type: alertTypes.WARNING,
                title: "Unsaved changes",
                text: "Are you sure you want to exit?",
                customButtons: (
                    <>
                        <button className="btn btn-outline-secondary mr-2" onClick={() => cancelExitPrompt()}>
                            Cancel
                        </button>
                        <button onClick={() => saveChangesHandler()} className="btn btn-primary mr-2">
                            Save
                        </button>
                        <button onClick={() => exitWithoutSavingHandler()} className="btn btn-danger">
                            Exit without saving
                        </button>
                    </>
                ),
            }
            setAlertConfig(config)
        } else {
            setAlertConfig(null)
        }
    }, [unsavedChanges, showExitPrompt])

    useEffect(() => {
        setAlertConfig(props.alertConfig)
    }, [props.alertConfig])

    function userInfoChangedHandler(user) {
        const updated = { ...updatedUserInfo, ...user }
        setUpdatedUserInfo(updated)
    }

    function tabChangeHandler(value) {
        const canBeSaved = validateFields()
        if (canBeSaved) {
            setActiveTab(value)
            setShowExitPrompt(false)
        }
    }

    function permissionsChangedHandler(perms) {
        let user
        user = { ...updatedUserInfo }
        user.permissions = perms
        setUpdatedUserInfo(user)
    }

    function validateFields() {
        let cur_user = getCurrentUser(props.userId)

        const userInfo = !!updatedUserInfo ? { ...updatedUserInfo } : { ...cur_user }
        const user = {
            id: userInfo.id,
            firstname: userInfo.firstname,
            lastname: userInfo.lastname,
            email: userInfo.email,
            job_title: userInfo.job_title,
            department: userInfo.department,
            language: userInfo.language,
            organization_id: userInfo.organization_id,
            deleted: userInfo.deleted,
            location: userInfo.location
        }

        const keys = Object.keys(user)
        let canBeSaved = true
        for (const k of keys) {
            if (user[k] === "" || user[k] === null || user[k] === undefined) {
                if(k === "location" && !props.locationFieldSettings.location_field_mandatory){
                    canBeSaved = true
                } else{
                    canBeSaved = false
                }
            }
        }

        const phoneReg = /^\+?\d{1,3}[\s-]?\d{3,14}$/u

        if (!!userInfo.phone_number && !phoneReg.test(userInfo.phone_number)) {
            canBeSaved = false
        }

        if(!userInfo.phone_number) {
            userInfo.phone_number = "";
        }
        
        if (!canBeSaved) {
            const config = {
                type: alertTypes.DANGER,
                title: "Missing field(s)",
                text: "Please provide the missing field(s)",
            }

            setAlertConfig(config)
        } else {
            setAlertConfig(null)
        }

        setInfoValidate(true)
        return canBeSaved
    }

    function saveChangesHandler(e) {
        const informationChanged = unsavedInformation()
        const permissionsChanged = unsavedPermissions()
        const homeDashboardChanged = unsavedHomeDashboard()
        const favouriteReportsChanged = unsavedFavouriteReports()
        const accessControlChanged = unsavedAccessControl()
        const canBeSaved = validateFields()

        if(updatedUserInfo.location === undefined ||updatedUserInfo.location === null){
            updatedUserInfo.location = ""
        }

        if(updatedUserInfo.location !== "" && !props.locations.map(x => x.value).includes(updatedUserInfo.location)){
            props.setLocations((prev) => [...prev, {label: updatedUserInfo.location, value: updatedUserInfo.location}].sort((a,b) => a.label.localeCompare(b.label)))
        }

        if (canBeSaved && informationChanged) {
            props.dispatch(saveUpdatedUser(updatedUserInfo))
        }

        if (permissionsChanged) {
            props.dispatch(updatePermissions(props.userId, updatedUserInfo.permissions))
        }

        if (homeDashboardChanged) {
            props.dispatch(setHomeDashboardForUser({ userID: props.userId, dashboardID: updatedUserInfo.home_dashboard }))
        }

        if (favouriteReportsChanged) {
            let cur_user = getCurrentUser(props.userId)
            const old = cur_user.favourite_reports
            const updated = updatedFavouriteReports.map((fr) => fr.value).sort()

            for (const fr of updatedFavouriteReports) {
                const indexO = old?.findIndex((o) => o.report_id === fr.value)
                const indexU = updated.findIndex((u) => u === fr.value)

                if (indexO > -1) {
                    old.splice(indexO, 1)
                    updated.splice(indexU, 1)
                }
            }

            const deleted = old?.map((o) => {
                const favouriteItem = {
                    id: o.id,
                    item_id: o.report_id,
                    item_type: "report",
                    user_id: o.user_id,
                }

                return favouriteItem
            })

            const added = updated.map((u) => {
                const favouriteItem = {
                    id: -1,
                    item_id: u,
                    item_type: "report",
                    user_id: props.userId,
                }

                return favouriteItem
            })

            props.dispatch(toggleFavouriteReports({ favouriteItems: added.concat(deleted), userID: props.userId }))
        }


        if(accessControlChanged) {
            props.dispatch(updateBucketAccess(props.organizationInfo.id, props.userId, updatedUserInfo.bucket_access))
        }

        setSavedClicked(true)

        if (!informationChanged && !permissionsChanged && !homeDashboardChanged && !favouriteReportsChanged && !accessControlChanged) {
            setUnsavedChanges(false)
            props.closeModalHandler()
        } else {
            setUnsavedChanges(true)
        }


    }

    function unsavedAccessControl() {
        let cur_user = getCurrentUser(props.userId)
        let old = cur_user.bucket_access ?? []
        let updated = updatedUserInfo.bucket_access ?? [];

        let isSame = arraysHaveSameValues(old, updated)

        return !isSame;
    }

    function unsavedFavouriteReports() {
        let cur_user = getCurrentUser(props.userId)
        let old = cur_user.favourite_reports?.map((fr) => fr.report_id).sort()
        let updated = updatedFavouriteReports?.map((fr) => fr.value).sort()

        let isSame = false

        if (!old) {
            old = []
        }

        if (!updated) {
            updated = []
        }

        if (old.length === 0 && updated.length === 0) {
            isSame = true
            return !isSame
        }

        if (old) {
            if (old.length === updated.length) {
                old.forEach((element, index) => {
                    if (element === updated[index]) {
                        isSame = true
                    } else {
                        isSame = false
                    }
                })
            }
        }

        return !isSame
    }

    function unsavedHomeDashboard() {
        let cur_user = getCurrentUser(props.userId)
        if (updatedUserInfo.home_dashboard !== cur_user.home_dashboard) {
            return true
        } else return false
    }

    function homepageSetupChangeHandler(values, changedField) {
        if (changedField === "dashboard") {
            const updated = { ...updatedUserInfo, home_dashboard: values?.value ? values.value : 0 }
            setUpdatedUserInfo(updated)
        }

        if (changedField === "favouriteReports") {
            setUpdatedFavouriteReports(values)
        }
    }

    function unsavedInformation() {
        const user = ["firstname", "lastname", "phone_number", "job_title", "department", "language", "location"]

        let unsaved = false
        let cur_user = getCurrentUser(props.userId)

        for (const u of user) {
            if (updatedUserInfo[u] !== cur_user[u]) {
                unsaved = true
            }
        }

        return unsaved
    }

    function unsavedPermissions() {
        let unsaved = false
        if (!updatedUserInfo?.permissions) {
            return false
        }

        let updatedPermissions = [...updatedUserInfo.permissions]
        let cur_user = getCurrentUser(props.userId)

        if(cur_user.permissions) {
            const permissionsAreTheSame = arraysHaveSameValues(updatedPermissions, cur_user.permissions)

            if (!permissionsAreTheSame) {
                unsaved = true
            }
        }
        else {
            return true
        }

        return unsaved
    }

    function arraysHaveSameValues(array1, array2) {
        const arr1 = [...array1].sort()
        const arr2 = [...array2].sort()

        if (arr1.length === arr2.length) {
            return arr1.every((element, index) => {
                if (element === arr2[index]) {
                    return true
                }
                return false
            })
        }

        return false
    }

    function closeEditModalHandler() {

        const hasChanged = unsavedInformation() || unsavedPermissions() || unsavedAccessControl() || unsavedHomeDashboard() || unsavedFavouriteReports()

        setUnsavedChanges(hasChanged)

        if (hasChanged) {
            setShowExitPrompt(true)
        } else {
            exitWithoutSavingHandler()
        }
    }

    function cancelExitPrompt() {
        setUnsavedChanges(false)
        setShowExitPrompt(false)
    }

    function exitWithoutSavingHandler() {
        let cur_user = getCurrentUser(props.userId)
        setUpdatedUserInfo(cur_user)
        setShowExitPrompt(false)
        setUnsavedChanges(false)
        props.closeModalHandler()
    }

    function bucketChangedHandler(buckets) {
        const updatedUser = {...updatedUserInfo}
        updatedUser.bucket_access = buckets.selected.map(b => b.id);
        const bucketIDs = buckets.selected.map(b => b.id)
        const reports = props.items?.reports.filter((r) => 
           bucketIDs.includes(r.bucket))
        const dashboards = props.items.dashboards ? props.items.dashboards.filter((d) => 
          { 
            let accepted = true;
            for (const db of d.buckets) {
                if (!bucketIDs.includes(db)) accepted = false 
            }
            return accepted
        }) : []
    
        const favs = updatedFavouriteReports.map(ufr => ufr.value)
        let favourite_reports = reports.filter(fr => favs.includes(fr.report_id))
        favourite_reports = favourite_reports.filter(r => bucketIDs.includes(r.bucket))
        updatedUser.reports = reports;
        updatedUser.dashboards = dashboards;
        updatedUser.favourite_reports = favourite_reports

        setUpdatedFavouriteReports(favourite_reports.map(fr => {return {label: fr.name, value: fr.report_id}}))
        setUpdatedUserInfo(updatedUser)

    }

    let cur_user = getCurrentUser(props.userId)

    return (
        <div>
            <div id="edit-user-modal" className={" org-admin-modal modal " + (props.shown ? "display-block show" : "")} tabIndex="-1" role="dialog">
                {!!props.shown && (
                    <div className="modal-dialog vh-100 mt-5 modal-xl modal-dialog-scrollable" role="document" style={{minWidth: 800}}>
                        <div className="modal-content ">
                            <div className="modal-header edit-user-modal-header">
                                <h4 className="modal-title">Edit {cur_user.firstname}</h4>
                            </div>
                            <ul className="edit-user-tabs">
                                {tabIndex.map(tab => 
                                <li className="edit-user-tab" key={tab.id}>
                                    <label className="edit-user-tab-label" htmlFor={tab.id} onClick={(e) => tabChangeHandler(tab.id)}>
                                        <input type="radio" id={tab.id} name="edit-user-tab" value={tab.id} checked={activeTab === tab.id} disabled={activeTab !== tab.id} aria-current="page" aria-checked="true" onChange={(e) => tabChangeHandler(e.target.value)} />

                                        <div className="edit-user-tab-label-text">{tab.label}</div>
                                    </label>
                                </li>
                                    )}
                            </ul>
                            <div className="modal-body edit-user-modal-body ">
                                <section className="edit-user-tab-content">  
                                    <ShowIf if={activeRequests===0}>
                                        {activeTab === tabIndex[0]?.id && <OrganizationAdministrationEditInfo user={!!updatedUserInfo ? updatedUserInfo : cur_user} departments={props.departments} userInfoChangedHandler={userInfoChangedHandler} disableUser={props.disableUser} validated={infoValidate} mode={"edit"} locationFieldSettings={props.locationFieldSettings} dispatch={props.dispatch} locations={props.locations} setAlertConfig={(config) => setAlertConfig(config)} />}
                                        {activeTab === tabIndex[1]?.id &&<OrganizationAdministrationEditPermissions user={!!updatedUserInfo ? updatedUserInfo : cur_user} departments={props.departments} permissionsChangedHandler={permissionsChangedHandler} />}
                                        {props.organizationInfo.use_access_control ? 
                                        <>
                                        {activeTab === tabIndex[2]?.id && <OrganizationAdministrationEditAccessControl user={updatedUserInfo} items={props.items} departments={props.departments} bucketChangedHandler={bucketChangedHandler} />}
                                        {activeTab === tabIndex[3]?.id && <OrganizationAdministrationEditHomePageSetup dispatch={props.dispatch} favReports={updatedFavouriteReports} user={!!updatedUserInfo ? updatedUserInfo :cur_user} homepageSetupChangeHandler={homepageSetupChangeHandler} mode="edit" />}
                                        </>
                                        :
                                        activeTab === tabIndex[2]?.id && <OrganizationAdministrationEditHomePageSetup dispatch={props.dispatch} favReports={updatedFavouriteReports} user={!!updatedUserInfo ? updatedUserInfo : cur_user} homepageSetupChangeHandler={homepageSetupChangeHandler} mode="edit" />
                                        }
                                    </ShowIf>
                                    <ShowIf if={activeRequests!==0}>
                                        Please wait while data is loading...
                                    </ShowIf>
                                </section>
                            </div>
                            <div className="modal-footer ">
                                {!!alertConfig && <AlertBanner config={alertConfig}></AlertBanner>}
                                {!alertConfig?.customButtons && (
                                    <section className="d-flex justify-content-end">
                                        <button className="btn btn-outline-primary mr-2" onClick={() => closeEditModalHandler()}>
                                            Close
                                        </button>
                                        <button onClick={() => saveChangesHandler()} className="btn btn-primary" disabled={!unsavedChanges}>
                                            Save & close
                                        </button>
                                    </section>
                                )}
                            </div>
                        </div>
                    </div>
                )}
            </div>
            {!!props.shown && <div className="fade modal-backdrop show"></div>}
        </div>
    )
}
export default connect(mapStateToProps)(OrganizationAdministrationEdit)
