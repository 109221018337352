import { DashboardData, DashboardWidgetData, ParsedDashboardData, Model, BucketColumnType, DashboardRow } from "../types/transfertypes"

export const ID_TYPE = 2
export const DECIMAL_TYPE = 4
export const DATE_TYPE = 8
export const CATEGORIZATION_TYPE = 64

export const mapColumnTypes = (type: number) => {
    switch (type) {
        case ID_TYPE:
            return "id"
        case DECIMAL_TYPE:
            return "decimal"
        case DATE_TYPE:
            return "date"
        case CATEGORIZATION_TYPE:
            return "categorization"
        default:
            return ""
    }
}

export const columnTypeToInt = (type: string) => {
    switch (type) {
        case "id":
            return ID_TYPE
        case "decimal":
            return DECIMAL_TYPE
        case "date":
            return DATE_TYPE
        case "categorization":
            return CATEGORIZATION_TYPE
        default:
            return -1
    }
}

export enum AggregationType {
    // If type = decimal
    SUM = "sum",
    AVG = "avg",
    MIN = "min",
    MAX = "max",

    // All other types
    COUNT = "count",
}

export const getAggregationsForType = (type: BucketColumnType) => {
    return type != BucketColumnType.Decimal ? 
        [AggregationType.COUNT] 
        : 
        [
            AggregationType.COUNT,
            AggregationType.SUM,
            AggregationType.AVG,
            AggregationType.MIN,
            AggregationType.MAX
        ]
}

// Needed because we use an old typescript compiler that doesn't support direct conversion 🙃
// EDIT: Now it does, but having a default value is nice 
export const stringToAggType = (s: string) => {
    switch (s) {
        case AggregationType.SUM:
            return AggregationType.SUM
        case AggregationType.AVG:
            return AggregationType.AVG
        case AggregationType.MIN:
            return AggregationType.MIN
        case AggregationType.MAX:
            return AggregationType.MAX
        case AggregationType.COUNT:
            return AggregationType.COUNT
        default:
            return AggregationType.COUNT
    }
}

export interface SelectOption<T> {
    label: string,
    value: T,
    extra?: any
}

export interface SelectGroupOption<T> {
    label: string,
    options: SelectOption<T>[],
    extra?: any
}

export let capitalizeText = (text: string) => {
    return text.charAt(0).toUpperCase() + text.slice(1)
}

// Parses and converts widgets to the new format. Should have been done on the backend, but is used as a backup
const parseWidgets = (w: string) => {
    let widgets: DashboardWidgetData[] = JSON.parse(w)
    for(let i = 0; i < widgets.length; i++) {
        for(let j = 0; j < widgets[i].widgets.length; j++){
            if(widgets[i].widgets[j].report_id != undefined && widgets[i].widgets[j].row_id == undefined) {
                widgets[i].widgets[j].row_id = `${widgets[i].widgets[j].report_id}`
            }
            if(widgets[i].widgets[j].hide_last_month == undefined){
                widgets[i].widgets[j].hide_last_month = false
            }
        }
    }
    return widgets
}

export const rowSortFunction = (a: DashboardRow, b: DashboardRow) => {
        if (a.hidden === false && b.hidden === true) return -1;

        if (a.hidden === true && b.hidden === false) return 1;

        return 0
}

const parseRows = (r: string) => {
    let rows: DashboardRow[] = JSON.parse(r) 
    rows.sort(rowSortFunction)

    return rows
}

/** Parses the given dashboard, returning a version where the stringified field are parsed
 */
export const parseDashboardData = (db: DashboardData) : ParsedDashboardData => {
    let tmp: DashboardData = structuredClone(db)
    let widgets = parseWidgets(tmp.widgets)

    // Adding ids to widgets without id for backwards compatability
    for (let i = 0; i < widgets.length; i++) {
        for (let j = 0; j < widgets[i].widgets.length; j++) {
            if (widgets[i].widgets[j].id == undefined) {
                widgets[i].widgets[j].id = generateTimestampBasedId()
            }
        }
    }
    let ret: ParsedDashboardData = {
        description: tmp.description,
        hide_table: tmp.hide_table,
        id: tmp.id,
        kpi_targets: tmp.kpi_targets,
        kpis: JSON.parse(tmp.kpis),
        multiple_targets: tmp.multiple_targets,
        reports: db.reports,
        main_report: db.main_report,
        org_id: tmp.org_id,
        restricted: tmp.restricted,
        simple_targets: tmp.simple_targets,
        title: tmp.title,
        user_whitelist: tmp.user_whitelist,
        widgets: widgets,
        version: tmp.version,
        rows: parseRows(tmp.rows),
        main_row: JSON.parse(tmp.main_row)
    }
    return ret
}

/** Stringifies the fields in the dashboard, making them into the format that is saved in the database
 */
export const stringifyDashboardData = (pdb: ParsedDashboardData) : DashboardData => {
    let tmp: ParsedDashboardData = structuredClone(pdb)
    let ret: DashboardData = {
        description: tmp.description,
        hide_table: tmp.hide_table,
        id: tmp.id,
        kpi_targets: tmp.kpi_targets,
        kpis: JSON.stringify(tmp.kpis),
        main_report: tmp.main_report,
        multiple_targets: tmp.multiple_targets,
        org_id: tmp.org_id,
        reports: tmp.reports,
        restricted: tmp.restricted,
        simple_targets: tmp.simple_targets,
        title: tmp.title,
        user_whitelist: tmp.user_whitelist,
        widgets: JSON.stringify(tmp.widgets),
        rows: JSON.stringify(tmp.rows),
        version: tmp.version,
        main_row: JSON.stringify(tmp.main_row)
    }
    return ret

}

/** Recursively compares the two objects. Returns true if they are equal, otherwise false.
*/
export const compareObjects = (obj1: any, obj2: any, inKey?: string, debug = false) => {
    if (typeof obj1 != typeof obj2){
        if (debug) {
            console.log(`Wrong types ${inKey}`)
            console.log(obj1)
            console.log(obj2)
        }
        return false
    } 
    
    if (typeof obj1 != "object" || obj1 == null || obj2 == null) {
        let res = obj1 == obj2
        if (!res && debug) {
            console.log(`Not eq ${inKey}`)
            console.log(obj1)
            console.log(obj2)
        }
        return res
    }

    if (Object.keys(obj1).length != Object.keys(obj2).length) {
        if (debug){
            console.log(`Not same amount of keys ${inKey}`)
            console.log(obj1)
            console.log(obj2)
        }
        return false
    }
    // Quickly return false if obj1 and obj2 does not contain the same keys instead of searching recursively
    for (let key in obj1) {
        if (!Object.keys(obj2).some(k => k === key)) {
            if (debug){
                console.log(`Not same keys ${inKey} > ${key}`)
                console.log(obj1)
                console.log(obj2)
            }
            return false
        }
    }

    // Recursively run through the objects
    for (let key in obj1) {
        let result = compareObjects(obj1[key], obj2[key], key, debug)
        if (!result) {
            if (debug) {
                console.log(`False ${inKey} ${key}`)
            }
            return false
        }
    }
    return true
}

export const isBucketModelBad = (model?: Model) => {
    if (model == undefined) return true
    return model.columns == null || model.categorization == null || model.formula_columns == null
}

// Gets an ID based on current time
export const generateTimestampBasedId = (prefix = '') => {
    const timestamp = Date.now().toString(36); // Convert current timestamp to base36
    const randomPart = Math.random().toString(36).substr(2, 5); // Generate a random part
    const id = prefix + timestamp + randomPart;
    return id;
}


