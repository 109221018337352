import * as ActionTypes from '../../actions/ActionTypes'
import { apiGet } from '../../helpers/ApiHelpers'
import { gotChecklistsStatus, setActionStatusLoading, tooManyActions } from '../../actions/StatusActions'
import { notifyApiFailure } from '../../helpers/ErrorHelpers'

const StatusApiMiddleware = ({dispatch, getState}) => (next) => (action) => {
    next(action)
    let url = undefined
    let data = undefined
    switch (action.type) {
        case ActionTypes.GET_CHECKLISTS_STATUS:
            url = 'checklists/status'

            let first = true;
            if(!!action.payload){
                const queryParams = Object.keys(action.payload)
                for(const k of queryParams){
                    if(!action.payload[k] && action.payload[k] !== 0) continue;
                    if(first){
                        url += '?' + k + '=' + action.payload[k]
                        first = false
                    } else {
                        url += '&' + k + '=' + action.payload[k]
                    }             
                }
            }
            dispatch(setActionStatusLoading(true))
            apiGet(url, status => {
                    if(typeof status === "number"){
                        dispatch(tooManyActions(status))
                    } else if (!!status){   
                        dispatch(gotChecklistsStatus(status))
                    }
                    dispatch(setActionStatusLoading(false))
                }, 
                e => {
                    notifyApiFailure("loading Action list statuses", 'GET', url, data, e.status)
                    dispatch(setActionStatusLoading(false))
                }
            )
            break
        default:
            break
    }
}

export default StatusApiMiddleware