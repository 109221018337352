export type KeyMapKey = string | number
export class SingleKeyMap<T> {
    data: {[key:string]: T} = {} // we store data in an object data[key]

    constructor(oldMap?: SingleKeyMap<T>) {
        if (oldMap && typeof oldMap === typeof this) {
            this.data = structuredClone(oldMap.data)
        }
    }

    get(key: KeyMapKey): T | undefined
    get(key: KeyMapKey, defaultValue: T): T
    get(key: KeyMapKey, defaultValue?: T): T | undefined {
        if (this.data[key] !== undefined) {
            return this.data[key];
        }
        return defaultValue;
    }

    set(key:KeyMapKey, value:T) {
        this.data[key] = value
        return this
    }

    contains(key:KeyMapKey) {
        return this.data.hasOwnProperty(key)
    }

    values() {
        return Object.keys(this.data).map((key) => { return this.data[key] })
    }

    keys() {
        return Object.keys(this.data)
    }

    map<K>(func:(key:KeyMapKey,value:T,index:number) => K) {
        return this.keys().map((key,index) => func(key,this.data[key],index))
    }

    forEach(func:(key:KeyMapKey,value:T,index:number) => void) {
        this.keys().forEach((key,index) => func(key,this.data[key],index))
    }

    isEmpty() {
        return this.keys().length === 0
    }
}

export class DoubleKeyMap<T> {
    data = new SingleKeyMap<SingleKeyMap<T>>() // we store data in a multi-layered object SingleKeyMap(SingleKeyMap())

    constructor(oldMap?: DoubleKeyMap<T>) {
        if (oldMap && typeof oldMap === typeof this) {
            let newMap = new SingleKeyMap<SingleKeyMap<T>>()
            for(let key in oldMap.data.data) {
                if(!oldMap.data.data.hasOwnProperty(key)) continue;
                newMap.set(key, new SingleKeyMap(oldMap.get1(key, new SingleKeyMap())))
            }
            this.data = newMap
        }
    }

    get(key1:KeyMapKey, key2:KeyMapKey, defaultValue?:T) {
        if (defaultValue == undefined) {
            return this.data.get(key1, new SingleKeyMap()).get(key2)
        }
        return this.data.get(key1, new SingleKeyMap()).get(key2, defaultValue)
    }

    get1(key1:KeyMapKey, defaultValue = new SingleKeyMap<T>()) {
        return this.data.get(key1, defaultValue)
    }

    set(key1:KeyMapKey, key2:KeyMapKey, value: T) {
        let d = this.data.get(key1, new SingleKeyMap())
        d.set(key2, value)
        this.data.set(key1, d)
        return this
    }

    set1(key1:KeyMapKey, value:SingleKeyMap<T>) {
        return this.data.set(key1, value)
    }

    values(key1:KeyMapKey) {
        return this.data.get(key1, new SingleKeyMap())?.values()
    }

    forEach(func:(key1:KeyMapKey,key2:KeyMapKey,value:any) => void) {
        this.data.forEach((k1,v1)=>v1.forEach((k2:KeyMapKey,v2:any)=>func(k1,k2,v2)))
    }
}

export class TripleKeyMap<T> {
    data = new SingleKeyMap<DoubleKeyMap<T>>() // we store data in a multi-layered object SingleKeyMap(SingleKeyMap(SingleKeyMap()))

    constructor(oldMap?:TripleKeyMap<T>) {
        if (oldMap && typeof oldMap === typeof this) {
            //TODO: inner-singlekeymaps should be assigned
            this.data = Object.assign(new SingleKeyMap(), oldMap.data)
        }
    }

    get(key1:KeyMapKey, key2:KeyMapKey, key3:KeyMapKey, defaultValue?:any) {
        return this.data.get(key1, new DoubleKeyMap()).get(key2, key3, defaultValue)
    }

    get2(key1:KeyMapKey, key2:KeyMapKey, defaultValue = new SingleKeyMap<T>()) {
        return this.data.get(key1, new DoubleKeyMap()).get1(key2, defaultValue)
    }

    get1(key1:KeyMapKey, defaultValue = new DoubleKeyMap<T>()) {
        return this.data.get(key1, defaultValue)
    }

    set(key1:KeyMapKey, key2:KeyMapKey, key3:KeyMapKey, value:any) {
        let d = this.data.get(key1, new DoubleKeyMap())
        d.set(key2, key3, value)
        this.data.set(key1, d)
        return this
    }

    set2(key1:KeyMapKey, key2:KeyMapKey, value:SingleKeyMap<T>){
        let d = this.data.get(key1, new DoubleKeyMap())
        d.set1(key2, value)
        this.data.set(key1, d)
        return this
    }

    set1(key1:KeyMapKey, value:DoubleKeyMap<T>) {
        this.data.set(key1, value)
        return this
    }

    forEach(func:(key1:KeyMapKey,key2:KeyMapKey,key3:KeyMapKey,value:any) => void) {
        this.data.forEach((k1,v1)=>v1.forEach((k2:KeyMapKey,v2:any)=>v2.forEach((k3:KeyMapKey,v3:any)=>func(k1,k2,k3,v3))))
    }
}
