import React from "react";
import {
    CxmColumn,
    CxmMultipleAggregationQueryResult,
    End2EndColumnSettings,
} from "../../types/cxmtypes";
import styles from "../../css/End2EndDrawer.module.css";
// @ts-ignore
import Select from "react-select";
import { DataBucket, BucketColumnType } from "../../types/transfertypes";
import { Dispatch, AnyAction } from "redux";
import { getAggregationsForType, capitalizeText, AggregationType } from "../../helpers/TypeHelpers";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    c: CxmColumn;
    bucket: DataBucket;
    data: CxmMultipleAggregationQueryResult;
    settings: End2EndColumnSettings;
    updateSettings: (updateFunc: (setting: End2EndColumnSettings) => void) => void;
    dispatch: Dispatch<AnyAction>;
}

const End2EndDrawer = ({ settings, updateSettings, c, bucket, ...props }: Props) => {
    const setColumn = (column: string) => {
        updateSettings((s) => {
            s.aggregation_column = column;
            const bColumn = bucket.info.model.columns.find((c) => c.name == column);
            const aggs = getAggregationsForType(bColumn?.type ?? BucketColumnType.Text);
            if (!aggs.some((a) => a == s.aggregation_type) || bColumn == undefined) {
                s.aggregation_type = AggregationType.COUNT;
            }
        });
    };

    const setAggType = (aggType: AggregationType) => {
        updateSettings((s) => {
            s.aggregation_type = aggType;
        });
    };

    const setShowDecimals = (value: boolean) => {
        updateSettings((s) => {
            s.show_decimals = value;
        });
    };

    const setShowPercentages = (value: boolean) => {
        updateSettings((s) => {
            s.show_percentages = value;
        });
    };

    const setHideColumn = (value: boolean) => {
        updateSettings((s) => {
            s.hide_column = value
        })
    }

    const getButtonColor = (bool: boolean) => {
        if (bool) {
            return "btn-default active";
        } else {
            return "btn-default";
        }
    };

    const column = bucket.info.model.columns.find((c) => c.name == settings.aggregation_column);
    if (column == undefined) {
        return <h2>Could not find column</h2>;
    }

    const columnOptions = [
        { label: `Default (${c.id_column})`, value: c.id_column },
        ...bucket.info.model.columns
            .filter((col) => c.id_column != col.name)
            .map((c) => ({ label: c.name, value: c.name })),
    ];
    const columnValue = columnOptions.find((o) => o.value == settings.aggregation_column);

    const aggregationOptions = getAggregationsForType(column.type).map((a) => ({ label: capitalizeText(a), value: a }));
    const aggregationValue = aggregationOptions.find((o) => o.value == settings.aggregation_type);

    return (
        <div className={styles.column}>
            <Select options={columnOptions} value={columnValue} onChange={(v) => setColumn(v?.value ?? c.id_column)} />
            <div className={styles.extraOptions}>
                <Select
                    className={styles.aggSelect}
                    options={aggregationOptions}
                    value={aggregationValue}
                    onChange={(v) => setAggType(v?.value ?? AggregationType.COUNT)}
                />
                <button
                    title="Show/hide decimals"
                    className={`btn btn-default btn-sm margin-left-5px ${getButtonColor(settings.show_decimals)}`}
                    onClick={() => setShowDecimals(!settings.show_decimals)}
                >
                    0,0
                </button>
                <button
                    title="Toggle percentages"
                    className={`btn btn-default btn-sm margin-left-5px ${getButtonColor(settings.show_percentages)}`}
                    onClick={() => setShowPercentages(!settings.show_percentages)}
                >
                    %
                </button>
            </div>
            <button className='btn btn-default btn-sm' onClick={() => setHideColumn(!settings.hide_column)}>
                {settings.hide_column ? 'Show column' : 'Hide column'}
            </button>
        </div>
    );
};

export default End2EndDrawer;
