// @ts-ignore
import hash from 'object-hash'
import { Reducer } from 'react'
import { Model } from '../types/transfertypes'

export const equal = (a:any, b:any) => { //Shallow object comparing
    if(typeof a !== typeof b) return false
    else if (a === null && b === null) return true
    else if (a === null || b === null) return false
    else if (typeof a !== 'object' && typeof b !== 'object') return a===b
    else {
        return Object.entries(a).every(entry => {
            let key = entry[0]
            let value = entry[1]
            return b[key] === value
        }) && Object.entries(b).every(entry => {
            let key = entry[0]
            let value = entry[1]
            return a[key] === value
        })
    }
}

//castingings to any are used to achieve compatibility with IE functions on document which has been removed in TypeScript
export const copyToClipboard = (string:string) => {
    const _document:any = document
    const _window:any = window

    _document.execCommand("Copy")

    let dataHolder = _document.createElement('pre')
    dataHolder.innerHTML = string
    dataHolder.setAttribute("id", "copyData")
    _document.body.appendChild(dataHolder)

    if (_document.selection) {
        let range = _document.body.createTextRange()
        range.moveToElementText(_document.getElementById("copyData"))
        range.focus.select()
    } else if (_window.getSelection) {
        let range = _document.createRange()
        range.selectNode(_document.getElementById("copyData"))

        if (_document.body.createTextRange) {
            let range2 = _document.body.createTextRange()
            range2.collapse()
            range2.select()
        } else {
            _document.getSelection().removeAllRanges()
        }

        _window.getSelection().addRange(range)
    }
    
    _document.execCommand("Copy")
    
    try {
        _document.body.removeChild(dataHolder)
    } catch {}

}

export const hashNValues = (...values:any[]) => {
    let strings = values.map(x => JSON.stringify(x))
    return hash(strings, {excludeKeys: () => true, encoding: 'base64'})
}

export const deepClone = (o:object) => JSON.parse(JSON.stringify(o))

/* measured in characters (2 bytes) */
export const getUsedLocalStorageSpace = ():number => {
    return Object.keys(window.localStorage).map(function(key) { return typeof localStorage[key] === "string" ? localStorage[key].length : 0 }).reduce(function(a,b) { return a+b }, 0)
}

/* measured in characters (2 bytes) */
export const getUsedSessionStorageSpace = ():number => {
    return Object.keys(window.sessionStorage).map(function(key) { return typeof sessionStorage[key] === "string" ? sessionStorage[key].length : 0 }).reduce(function(a,b) { return a+b }, 0)
}

export const getStateReducer = <S>() => {
    const stateReducer: Reducer<S, Partial<S> |((prevState: S) => Partial<S>)> = (state: S, action: Partial<S> | ((prevState: S) => Partial<S>)): S => ({
        ...state,
        ...(typeof action === 'function' ? action(state) : action),
    });

    return stateReducer
}

export const getTodaysDate = () => {

    // Get today's date
    let today = new Date();

    // Extract year, month, and day
    let year = today.getFullYear();
    let month = String(today.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it starts from 0
    let day = String(today.getDate()).padStart(2, '0');

    // Format the date as "YYYY-MM-DD"
    let formattedDate = `${year}-${month}-${day}`;
    return formattedDate
}

/**
* Gets the color set for the category value, or undefined if none are found.
* A category name could, for example, "Customer Segmentation", and category value could be "New"
*/
export const findColorForCategorization = (categoryName: string, categoryValue: string, model: Model) => {
    if (categoryName === model.categorization_name) {
        // If the category is the active category
        const cat = model.categorization.find(c => c.name == categoryValue)
        const c = cat?.color
        return c != undefined ? `rgb(${c.r}, ${c.g}, ${c.b})` : undefined
    } else {
        // Else we try to find it in the other categorizations
        const category = model.categorizations.find(c => c.name === categoryName)
        const cat = category?.categorization.find(c => c.name === categoryValue)
        const c = cat?.color
        return c != undefined ? `rgb(${c.r}, ${c.g}, ${c.b})` : undefined
    }
}
