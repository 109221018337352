import * as ActionTypes from "../../actions/ActionTypes";
import { RootState } from "../..";
import { End2EndAction, gotE2ETest, gotCxmSetups, gotE2EData, gotE2ESetups } from "../../actions/End2EndActions";
import { apiGet, apiPost } from "../../helpers/ApiHelpers";
import { notifyApiFailure } from "../../helpers/ErrorHelpers";
import { Middleware } from "redux";

const End2EndApiMiddleware: Middleware<{}, RootState> =
    ({ dispatch }) =>
    (next) =>
    (action: End2EndAction) => {
        next(action);

        let url: string;
        let data: any = undefined;
        switch (action.type) {
            case ActionTypes.GET_TEST_E2E:
                url = "end2end/test";
                apiGet(
                    url,
                    (p) => {
                        dispatch(gotE2ETest(p));
                    },
                    (e) => {
                        notifyApiFailure("error getting e2e test", "GET", url, data, e.status);
                    },
                );
                break;
            case ActionTypes.GET_CXM_SETUPS:
                url = "end2end/setups";
                apiGet(
                    url,
                    (p) => {
                        dispatch(gotCxmSetups(p));
                    },
                    (e) => {
                        notifyApiFailure("error getting cxm setups", "GET", url, data, e.status);
                    },
                );
                break;
            case ActionTypes.QUERY_E2E:
                url = "end2end/query";
                data = action.payload;
                apiPost(
                    url,
                    data,
                    (p) => {
                        dispatch(gotE2EData(p));
                    },
                    (e) => {
                        notifyApiFailure("error querying e2e", "POST", url, data, e.status);
                    },
                );
                break;
            case ActionTypes.GET_E2E_SETUPS:
                url = "end2end";
                apiGet(
                        url,
                        (p) => {
                            dispatch(gotE2ESetups(p))
                        },
                        (e) => {
                            notifyApiFailure("error getting e2e setups", "GET", url, data, e.status)
                        }
                    )
        }
    };

export default End2EndApiMiddleware;
