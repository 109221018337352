/**
 * formatNumber formats the given number, returning a string
 */
export function formatNumber(value: number, settings: FormatSettings = {}): string {
    const s = {...defaultSettings, ...settings};
    let tmp = value;
    let truncateVal = s.truncateTo;
    if (s.truncate && !s.percentage) {
        if (truncateVal == undefined) {
            if (value >= 1_000_000) {
                truncateVal = "M";
            } else if (value >= 10_000) {
                truncateVal = "K";
            }
        }

        if (truncateVal == "K") {
            tmp = tmp / 1_000;
        } else if (truncateVal == "M") {
            tmp = tmp / 1_000_000;
        }
    }

    let formatOptions: Intl.NumberFormatOptions = {};
    if (s.truncate && truncateVal != undefined) {
        formatOptions = { minimumFractionDigits: 2, maximumFractionDigits: 2 };
    } else if (s.decimals != undefined) {
        formatOptions = { minimumFractionDigits: s.decimals, maximumFractionDigits: s.decimals };
    } 
    const formatter = Intl.NumberFormat("da-DA", formatOptions);
    let result = formatter.format(tmp);
    if (s.percentage) {
        result = `${result}%`;
    }
    if (!s.percentage && truncateVal) {
        result = `${result}${truncateVal}`;
    }
    return result;
}

type FormatSettings = {
    /** If it should truncate to K or M. If the number is too small, it will not be truncated
     * default: `false` */
    truncate?: boolean;
    /** If this is set, it will truncate to the given type, no matter how big or small the number is
     * Default: `undefined`*/
    truncateTo?: "K" | "M";
    /** How many decimals to display, setting it to undefined shows all decimals.
     * If this is set to a number, it will at minimum show that many decimals, for example '1.00'
     * Default: `undefined` (all)*/
    decimals?: number;
    /** If it should seperate thousands with a '.'
     * Default: `true` */
    showThousandSeperator?: boolean;

    /** If it should add a percentage sign
     * Default: `false`*/
    percentage?: boolean;
};

const defaultSettings: FormatSettings = {
    truncate: false,
    showThousandSeperator: true,
    percentage: false,
};
