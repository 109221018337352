import { OrgItemsDashboard, OrgItemsReport } from "./organizationtypes";
import { User } from "./transfertypes";

export interface CloudItem {
    org_id: number;
    // dashboards props
    id?: number;
    title?: string;
    // reports props
    report_id?: number;
    name?: string;
    is_public?: boolean
}

export interface CloudDashboard extends CloudItem {
    id: number;
    title: string;
}

export interface CloudReport extends CloudItem {
    report_id: number;
    name: string;
    bucket: string;
}

export interface Plan {
    id: number;
    interval: string;
    hour: number;
    minute: number;
    description: string;
}

export interface MailBundle {
    id: number;
    name: string;
    org_id: number;
    plan: string;
    plan_id?: number;
    last_sent: number;
    active: boolean;
    link: boolean;
    email_text: string;
    email_title: string;
    combined_pdf: boolean;
    owner_id: number;
    self_subscription: boolean;
}

export interface MailBundleWithRelations extends MailBundle{
    user_ids : number[];
    dashboards : ReportingDashboard[];
    insights : ReportingInsight[];
    insight_views: InsightView[];
}

export interface ReportingInsight {
    report_id: number;
}

export interface ReportingDashboard {
    id?: number;
    dashboard_id: number;
    include_table: boolean;
    include_widgets: boolean;
    index: number;
}

export interface InsightView {
    id? : number;
    report_id: number;
    hash: string;
    type: "trend" | "matrix" | "kpi" | "sum" | "profit";
    index: number;
}

export interface DashboardMap {
    [key:number] : OrgItemsDashboard
}
export interface ReportMap {
    [key:number] : OrgItemsReport
}
export interface UserMap{
    [key:number] : User
}

export const newMailBundle = (orgID : number) : MailBundleWithRelations => {
    return {
        id: 0,
        name: "",
        org_id: orgID,
        plan: "",
        last_sent: 0,
        active: false,
        link: true,
        email_text: "",
        email_title: "",
        combined_pdf: true,
        dashboards: [],
        insights: [],
        insight_views: [],
        user_ids: [],
        plan_id: 1,
        owner_id: -1,
        self_subscription: false
    }
}