import { RootState } from "..";
import { SingleKeyMap } from "../helpers/Collections";
import { DataBucket, Column, BucketColumnType, QueryCriterion } from "../types/transfertypes";
import { connect, ConnectedProps } from "react-redux";
import React, { useMemo, useEffect, useState, useRef } from "react";
import { useParams, useLocation } from "react-router";
import {
    End2EndFullSetup,
    End2EndColumnSettings,
    End2EndSettingsMap,
    CxmColumnQuery,
    CxmColumn,
    getCatName,
    CxmGridQuery,
    CxmGridQueryFullResult,
    CxmGridQueryResult,
    End2EndSettings,
} from "../types/cxmtypes";
import { getCxmSetups, getE2ESetups } from "../actions/End2EndActions";
import { getBucketsDataInfo } from "../actions/BucketActions";
import useQuery from "../hooks/useQuery";
import { notifySuccess } from "../helpers/NotificationManager";
import { AggregationType, compareObjects } from "../helpers/TypeHelpers";
import { apiPost } from "../helpers/ApiHelpers";
import { notifyApiFailure } from "../helpers/ErrorHelpers";
import InfiniteScrollOnScreen from "../components/Generic/InfiniteScrollOnScreen";
import DataGrid from "../components/DataGrid";

import styles from "../css/SingleEnd2End.module.css";
import ColumnSelectionDialog from "../components/Dialogs/ColumnSelectionDialog";
import { copyToClipboard } from "../helpers/GeneralHelpers";
import AddSlicerDialog2 from "../components/Dialogs/AddSlicerDialog2";
import useConfirmDialog from "../hooks/useConfirmDialog";
import End2EndGridColumn from "../components/End2End/End2EndGridColumn";
// @ts-ignore
import { Link } from "react-router-dom";
import RequirePermission from "../components/RequirePermission";
import { is } from "../helpers/PermissionHelpers";
import { getUniqueDescription, getUniqueDescriptionGrid } from "../helpers/end2endHelpers";
import ShowIf from "../components/Generic/ShowIf";
// How many items to load at a time
const LIMIT = 100;

export type End2EndTransferSettings = {
    settings: End2EndSettings;
    extraTitle?: string | undefined;
};

let mapStateToProps = (state: RootState) => {
    return {
        results: state.End2End.results,
        cxmSetups: state.End2End.cxmSetups,
        setups: state.End2End.setups,
        buckets: state.Bucket.bucketDetails as SingleKeyMap<DataBucket>,
    };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    history: any;
}

const End2EndGridView = ({ dispatch, setups, cxmSetups, buckets, ...props }: Props) => {
    const { id } = useParams();
    const setup: End2EndFullSetup | undefined = useMemo(() => {
        const s1 = setups?.find((s) => s.id === Number(id));
        if (s1 == undefined) return undefined;
        const s2 = cxmSetups?.find((s) => s.id == s1.cxm_id);
        if (s2 == undefined) return undefined;
        return {
            id: s1.id,
            cxm_id: s2.id,
            setup: s1,
            cxm: s2,
        };
    }, [cxmSetups, setups]);
    const [settings, setSettings] = useState<End2EndSettings>();
    const updateSettings = (updateFunc: (q: End2EndSettings) => void) => {
        if (settings == undefined) return;
        let tmp = structuredClone(settings);
        updateFunc(tmp);
        setSettings(tmp);
    };

    const getSetting = (c: CxmColumn, map?: End2EndSettings) => {
        let setting = map != undefined ? map.buckets[c.bucket] : settings?.buckets[c.bucket];
        if (setting == undefined) {
            setting = {
                aggregation_column: c.id_column,
                aggregation_type: AggregationType.COUNT,
                filter: [],
                show_decimals: false,
                show_percentages: false,
                use_second_category: false,
                grid_columns: [],
                hide_column: false,
            };
        }

        if (setting.grid_columns == null || setting.grid_columns.length == 0) {
            const tmpBucket = buckets.get(c.bucket);
            if (tmpBucket == undefined) {
                setting.grid_columns = [];
            } else {
                const cols = tmpBucket.info.model.columns.slice(0, 5);
                if (!cols.some((c) => c.type == BucketColumnType.ID)) {
                    const idColumn = tmpBucket.info.model.columns.find((c) => c.type == BucketColumnType.ID);
                    if (idColumn != undefined) cols.splice(0, 0, idColumn);
                }
                setting.grid_columns = cols.map((c) => c.name);
            }
        }
        return setting;
    };

    const getUpdateSettingFunc = (c: CxmColumn) => (f: (setting: End2EndColumnSettings) => void) => {
        updateSettings((s) => {
            if (c == undefined) return;
            let s2 = getSetting(c, s);
            f(s2);
            s.buckets[c.bucket] = s2;
        });
    };

    const [bucket, setBucket] = useState<DataBucket>();

    const [offset, setOffset] = useState(0);
    const [data, setData] = useState<CxmGridQueryResult>([]);
    const [hasMoreData, setHasMoreData] = useState(true);
    const [totals, setTotals] = useState({ shown: 0, total: 0 });

    const [sortColumn, setSortColumn] = useState("");
    const [sortDescending, setSortDescending] = useState(false);

    const [showDecimals, setShowDecimals] = useState(false);

    const [selectedItems, setSelectedItems] = useState<any[]>([]);

    const [showAddFilter, setShowAddFilter] = useState(false);
    const [chosenFilter, setChosenFilter] = useState<QueryCriterion>();

    // Ref to the ColumnSelectionDialog
    const columnSelectDialog = useRef<any>();
    // Ref to the grid
    const dataGrid = useRef<any>();

    const { confirm, ConfirmDialog } = useConfirmDialog();

    const columns = useMemo(() => {
        if (setup == undefined || bucket == undefined) return [];
        const c = setup.cxm.columns.find((c) => c.bucket == bucket.id);
        if (c == undefined) return [];
        const setting = getSetting(c);
        return setting.grid_columns
            .map((c) => bucket.info.model.columns.find((bc) => bc.name == c))
            .filter((c) => c != undefined) as Column[];
    }, [setup, bucket, settings]);

    const q = useQuery();

    useEffect(() => {
        const bucketID = q.get("source") ?? setup?.cxm.columns.at(0)?.bucket;
        if (bucketID == undefined) {
            setBucket(undefined);
        } else {
            const b = buckets.get(bucketID);
            setBucket(b);
        }
    }, [buckets, q, setup]);

    const location = useLocation<End2EndTransferSettings>();

    useEffect(() => {
        if (location.state == undefined) {
            if (setup == undefined) {
                setSettings(undefined);
            } else {
                setSettings(setup.setup.settings);
            }
            return;
        }
        setSettings(location.state.settings);
    }, [location, setup]);

    useEffect(() => {
        if (cxmSetups === undefined) {
            dispatch(getCxmSetups());
        }
        if (buckets.keys.length == 0) {
            dispatch(getBucketsDataInfo());
        }
        if (setups === undefined) {
            dispatch(getE2ESetups());
        }
    }, []);

    const generateQuery = (reset = false) => {
        if (setup == undefined || settings == undefined || bucket == undefined) return;
        const queries: CxmColumnQuery[] = setup.cxm.columns.map((c) => {
            const s = getSetting(c);

            return {
                bucket: c.bucket,
                column: s.aggregation_column,
                categorization: getCatName(s.use_second_category, c),
                filter: s.filter,
            };
        });
        const col = setup.cxm.columns.find((c) => c.bucket == bucket.id);
        if (col == undefined) return;
        const setting = getSetting(col);
        const columns = setting.grid_columns;

        // Inserts the idColumn into the columns if it is not already present
        const idColumn = bucket.info.model.columns.find((c) => c.type == BucketColumnType.ID);
        if (idColumn == undefined) return;
        if (!columns.some((c) => c == idColumn.name)) {
            columns.splice(0, 0, idColumn.name);
        }

        if (sortColumn == "") {
            setSortColumn(columns[0]);
        }

        const query: CxmGridQuery = {
            setup_id: setup.cxm_id,
            queries: queries,
            bucket: bucket.id,
            columns: columns,
            sort_column: sortColumn != "" ? sortColumn : columns[0],
            ascending: !sortDescending,
            limit: LIMIT,
            offset: reset ? 0 : offset,
            unique: settings.unique,
        };
        return query;
    };

    // Makes sure there that the following useEffect and the infinite scroll won't compete on initial load
    const previousQuery = useRef<CxmGridQuery>();
    const getMoreData = (reset = false) => {
        if (!hasMoreData && !reset) return;
        const query = generateQuery(reset);
        if (query == undefined || compareObjects(previousQuery.current, query)) return;
        if (reset) {
            setOffset(0);
            setHasMoreData(true);
        }
        const url = "end2end/grid";
        previousQuery.current = query;
        apiPost(
            url,
            query,
            (data: CxmGridQueryFullResult) => {
                if (data.results == null) {
                    setHasMoreData(false);
                    if (reset) {
                        setData([]);
                    }
                    return;
                }
                if (reset) {
                    setData(data.results);
                } else {
                    setData((d) => {
                        d.push(...data.results);
                        return d;
                    });
                }
                setTotals({ shown: data.total_shown, total: data.total });
                setOffset((o) => o + LIMIT);
            },
            (e) => {
                notifyApiFailure("Failed to get end2end grid data", "POST", url, query, e.status);
            },
        );
    };

    useEffect(() => {
        //setData([])
        getMoreData(true);
    }, [settings, setup, bucket, sortColumn, sortDescending]);

    const onSort = (col: { name: string; index: number }, direction: boolean) => {
        setSortColumn(col.name);
        setSortDescending(direction);
    };

    const onSaveColumns = (cols: (Column & { index: number })[]) => {
        if (bucket == undefined || settings == undefined || settings.buckets[bucket.id] == undefined) return;
        setSettings((s) => {
            if (s == undefined || s.buckets[bucket.id] == undefined) return s;
            const newS = structuredClone(s);
            newS.buckets[bucket.id].grid_columns = cols.map((c) => c.name);
            return newS;
        });
    };

    if (settings == undefined || setup == undefined) {
        return <h3>No settings found, make sure you access this page through a setup</h3>;
    }
    if (bucket == undefined) {
        return <h3>No source found</h3>;
    }

    const idColumn = bucket.info.model.columns.find((c) => c.type == BucketColumnType.ID);
    if (idColumn == undefined) {
        return <h3>No ID column in selected source</h3>;
    }

    let c = setup.cxm.columns.find((c) => c.bucket == bucket.id);
    if (c == undefined) {
        return (
            <h3 style={{ color: "var(--danger)" }}>
                Column not found in setup, this should not happen, please contact support
            </h3>
        );
    }
    const setting = getSetting(c);
    // const updateSetting = getUpdateSettingFunc(c);

    const showChangeColumns = () => {
        columnSelectDialog.current.show(
            columns.map((c, i) => ({ name: c.name, type: c.type, index: i })),
            bucket.info.model.columns.map((c, i) => ({ name: c.name, type: c.type, index: i })),
            [],
        );
    };

    let getButtonColor = (bool: boolean) => {
        if (bool) {
            return "btn-default active";
        } else {
            return "btn-default";
        }
    };

    const saveFilter = (filter: QueryCriterion) => {
        const s = structuredClone(setting);
        const filterIndex = s.filter.findIndex(
            (f) => f.column === chosenFilter?.column && f.compare_type === chosenFilter.compare_type,
        );

        if (filterIndex != -1) {
            s.filter[filterIndex] = filter;
        } else {
            s.filter.push(filter);
        }
        // Prune empty filters
        s.filter = s.filter.filter((f) => f.target_values.length > 0);

        let tmpS = structuredClone(settings);
        tmpS.buckets[bucket.id] = s;
        setSettings(tmpS);
        setShowAddFilter(false);
        setChosenFilter(undefined);
    };

    const onSave = async () => {
        if (setup == undefined || settings == undefined) return;
        if (!(await confirm("Are you sure you want to save? This will save this setup for everyone."))) return;
        const data = {
            setup_id: setup.id,
            settings: settings,
        };
        apiPost(
            "end2end/settings",
            data,
            () => {
                notifySuccess("Successfully saved");
                dispatch(getE2ESetups());
            },
            (e) => {
                notifyApiFailure("Failed to save settings", "POST", "end2end/settings", data, e.status);
            },
        );
    };

    // const onSaveAs = () => {
    //     if (settings == undefined || setup == undefined) return;
    //     setTmpName(`${setup.setup.name} - Copy`);
    //     setShowSaveAs(true);
    // };

    const toggleUnique = () => {
        updateSettings((s) => {
            s.unique = !s.unique
        })
    }

    const onSelect = (ids: any[]) => {
        setSelectedItems(ids);
    };

    let copyReportDataToClipboard = () => {
        copyToClipboard(dataGrid.current.getHTML(false, true));
        const rowAmount = selectedItems.length === 0 ? data.length : selectedItems.length;
        notifySuccess(rowAmount + " rows has been copied to the clipboard");
    };

    const hasChanges = !compareObjects(settings, setup?.setup.settings);

    const backLocation = {
        pathname: `/end2end/${id}`,
        state: {
            settings: location?.state?.settings ?? settings
        }
    }

    const hasFilters = Object.values(settings.buckets).some(c => c.filter.length > 0)

    return (
        <>
            <div
                style={{
                    padding: 20,
                    paddingLeft: 15,
                    paddingRight: 15,
                    width: "100%",
                }}
            >
                <Link to={backLocation}>
                    <span className="abc-click report-template-back">
                        <i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back
                    </span>
                </Link>
                <div className="d-flex justify-content-between align-items-end">
                    <h1>
                        {setup.setup.name} - {bucket.info.name}{" "}
                        {location?.state?.extraTitle != undefined ? <>- {location.state.extraTitle}</> : ""}
                    </h1>
                    <div className={styles.drawerButtons}>
                        <ShowIf if={!settings.unique}>
                            <button style={!settings.unique && !hasFilters ? {opacity: 0} : undefined } className={`btn ${settings.unique ? 'btn-primary' : 'btn-light'}`} onClick={toggleUnique}>Unique Relation: {settings.unique ? "ON" : "OFF"}</button>
                        </ShowIf>
                        <RequirePermission perms={is.e2eadmin}>
                            <button onClick={onSave} disabled={!hasChanges} className="btn btn-primary btn-sm">
                                Save
                            </button>
                            {/*<button onClick={() => {}} disabled={!hasChanges} className="btn btn-primary btn-sm">
                                Save as
                            </button>*/}
                        </RequirePermission>
                    </div>
                </div>
                <ShowIf if={settings.unique}>
                    <div className={`${styles.uniqueBanner} ${settings.unique ? styles.enabled : ''}`} style={{marginTop: 0, padding: 10}}>
                        <div>
                            <span style={!settings.unique ? {display: "none"} : undefined}>{getUniqueDescriptionGrid(settings, buckets, setup.cxm, bucket.id)}</span>
                        </div>

                        <button style={!settings.unique && !hasFilters ? {opacity: 0} : undefined } className={`btn ${settings.unique ? 'btn-primary' : 'btn-light'}`} onClick={toggleUnique}>Unique Relation: {settings.unique ? "ON" : "OFF"}</button>
                    </div>
                </ShowIf>
                <div className={styles.toolbar}>
                    {setup.cxm.columns.map((c, i) => {
                        const bucket = buckets.get(c.bucket);
                        const setting = getSetting(c);
                        if (bucket == undefined || setting == undefined) {
                            return <h3 key={i}>Datasource could not be found</h3>;
                        }
                        const idColumn = bucket.info.model.columns.find((c) => c.type == BucketColumnType.ID);
                        if (idColumn == undefined) {
                            return <h3 key={i}>Model contains no ID column</h3>;
                        }
                        return (
                            <End2EndGridColumn
                                key={bucket.id}
                                c={c}
                                bucket={bucket}
                                settings={setting}
                                updateSettings={getUpdateSettingFunc(c)}
                                dispatch={dispatch}
                                idColumn={idColumn}
                            />
                        );
                    })}
                    <div className="d-flex flex-column">
                        <div style={{ textAlign: "right" }}>
                            Showing {totals.shown} of {totals.total}
                        </div>
                        <div className={styles.gridButtonGroup}>
                            <button
                                title="Show/hide decimals"
                                className={`btn btn-default btn-sm  ${getButtonColor(showDecimals)}`}
                                onClick={() => setShowDecimals((v) => !v)}
                            >
                                0,0
                            </button>
                            <button
                                title={
                                    selectedItems.length != 0
                                        ? "Copy " + selectedItems.length + " rows to clipboard"
                                        : "Copy to clipboard"
                                }
                                className="btn btn-default btn-sm"
                                onClick={() => copyReportDataToClipboard()}
                            >
                                <i className="fa fa-copy"></i>{" "}
                                {selectedItems.length != 0 ? `(${selectedItems.length})` : ""}
                            </button>
                            <button
                                title="Edit column layout"
                                className="btn btn-default btn-sm "
                                onClick={() => showChangeColumns()}
                            >
                                <i className="fa fa-columns"></i>
                            </button>
                        </div>
                    </div>
                </div>
                {data.length == 0 && hasMoreData ? (
                    <h3>Fetching data...</h3>
                ) : (
                    <>
                        <DataGrid
                            ref={dataGrid}
                            id={"e2e"}
                            columns={columns.map((c, i) => ({ name: c.name, type: c.type, index: i }))}
                            rows={data.map((d) => {
                                let tmpData = columns.map((c) => d[c.name]);
                                return { data: tmpData, id: d[idColumn.name] };
                            })}
                            defaultSortColumn={sortColumn}
                            defaultSortDirection={sortDescending}
                            onSort={onSort}
                            showOpenButton
                            bucketId={bucket.id}
                            sticky={49}
                            model={bucket.info.model}
                            showDecimals={showDecimals}
                            onSelect={onSelect}
                        />
                    </>
                )}
                <InfiniteScrollOnScreen
                    hasMore={hasMoreData}
                    next={() => getMoreData()}
                    loadingText="Fetching more data..."
                />
            </div>
            <ColumnSelectionDialog
                ref={columnSelectDialog}
                saveHandler={onSaveColumns}
                activeCategoryColumn={bucket.info.model.categorization_name}
            />

            {chosenFilter != undefined && (
                <AddSlicerDialog2
                    show={showAddFilter}
                    slicer={chosenFilter}
                    slicers={setting.filter}
                    saveSlicer={saveFilter}
                    onCancel={() => {
                        setChosenFilter(undefined);
                        setShowAddFilter(false);
                    }}
                    bucket={bucket.info}
                />
            )}

            <ConfirmDialog />
        </>
    );
};

export default connector(End2EndGridView);
