export const DUMMY_ACTION = 'DUMMY_ACTION'

// export const API_CALL = 'API_CALL'
// export const API_CALL_DONE = 'API_CALL_DONE'
export const PING = 'PING'
export const GENERAL_API_ERROR = 'GENERAL_API_ERROR'

export const GET_VERSION = 'GET_VERSION'
export const GET_VERSION_DONE = 'GET_VERSION_DONE'

export const LOGIN = 'LOGIN'
export const LOGIN_DONE = 'LOGGED_DONE'
export const LOGOUT = 'LOGOUT'
export const LOGOUT_DONE = 'LOGOUT_DONE'
export const LOGIN2FA = 'LOGIN2FA'
export const LOGIN2FA_DONE = 'LOGIN2FA_DONE'
export const GOOGLE_LOGIN = 'GOOGLE_LOGIN'
export const GOOGLE_LOGIN_DONE = 'GOOGLE_LOGIN_DONE'

export const INITIALIZE_2FA = 'INITIALIZE_2FA'
export const INITIALIZE_2FA_DONE = 'INITIALIZE_2FA_DONE'
export const SETUP_2FA = 'SETUP_2FA'
export const SETUP_2FA_DONE = 'SETUP_2FA_DONE'
export const IS_2FA_ENABLED = 'IS_2FA_ENABLED'
export const IS_2FA_ENABLED_DONE = 'IS_2FA_ENABLED_DONE'
export const IS_2FA_FORCED = 'IS_2FA_FORCED'
export const IS_2FA_FORCED_DONE = 'IS_2FA_FORCED_DONE'
export const RESET_SECRET = 'RESET_SECRET'
export const DISABLE_2FA = 'DISABLE_2FA'
export const DISABLE_2FA_USER = 'DISABLE_2FA_USER'

export const GET_USER_INFO = 'GET_USER_INFO'
export const GET_USER_INFO_DONE = 'GET_USER_INFO_DONE'
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO'
export const UPDATE_USER = 'UPDATE_USER'
export const UPDATE_USER_DONE = 'UPDATE_USER_DONE'
export const LOGIN_ANIMATION_PLAYED = 'LOGIN_ANIMATION_PLAYED'
export const GET_EMAIL_SETTINGS = 'GET_EMAIL_SETTINGS'
export const GET_EMAIL_SETTINGS_DONE = 'GET_EMAIL_SETTINGS_DONE'
export const SAVE_EMAIL_SETTINGS = 'SAVE_EMAIL_SETTINGS'
export const UPDATE_PERMISSIONS = "UPDATE_PERMISSIONS"
export const UPDATE_PERMISSIONS_DONE = "UPDATE_PERMISSIONS_DONE"
export const UPDATE_MULTIPLE_USERS_PERMISSIONS = "UPDATE_MULTIPLE_USERS_PERMISSIONS"
export const UPDATE_MULTIPLE_USERS_PERMISSIONS_DONE = "UPDATE_MULTIPLE_USERS_PERMISSIONS_DONE"
export const DISABLE_MULTIPLE_USERS = "DISABLE_MULTIPLE_USERS"
export const DISABLE_MULTIPLE_USERS_DONE = "DISABLE_MULTIPLE_USERS_DONE"
export const CREATE_USER = "CREATE_USER"
export const CREATE_USER_DONE = "CREATE_USER_DONE"
export const GET_CHECKLISTS_STATUS = 'GET_CHECKLISTS_STATUS'
export const GET_CHECKLISTS_STATUS_DONE = 'GET_CHECKLISTS_STATUS_DONE'
export const SEND_WELCOME_MAIL = "SEND_WELCOME_MAIL"
export const SEND_WELCOME_MAIL_DONE = "SEND_WELCOME_MAIL_DONE"
export const UPDATE_BUCKET_ACCESS = "UPDATE_BUCKET_ACCESS"
export const UPDATE_BUCKET_ACCESS_DONE = "UPDATE_BUCKET_ACCESS_DONE"

export const DOWNLOAD_BUCKET_DATA_SPECS = 'DOWNLOAD_BUCKET_DATA_SPECS'

export const GET_FAVOURITE_REPORTS_INFO = 'GET_FAVOURITE_REPORTS_INFO'
export const GET_FAVOURITE_REPORTS_INFO_DONE = 'GET_FAVOURITE_REPORTS_INFO_DONE'

export const TOGGLE_FAVOURITE = 'TOGGLE_FAVOURITE'
export const TOGGLE_FAVOURITE_DONE = 'TOGGLE_FAVOURITE_DONE'
export const TOGGLE_FAVOURITE_REPORTS= 'TOGGLE_FAVOURITE_ITEMS'
export const TOGGLE_FAVOURITE_REPORTS_DONE = 'TOGGLE_FAVOURITE_ITEMS_DONE'

export const GET_HOME_INFO = 'GET_HOME_INFO'
export const GET_HOME_INFO_DONE = 'GET_HOME_INFO_DONE'

export const GET_LIBRARY_INFO = 'GET_LIBRARY_INFO'
export const GET_LIBRARY_INFO_DONE = 'GET_LIBRARY_INFO_DONE'

export const MOVE_REPORTS_TO_GROUP = 'MOVE_REPORTS_TO_GROUP'
export const ASSIGN_REPORTS_TO_OWNER = 'ASSIGN_REPORTS_TO_OWNER'
export const DELETE_MULTIPLE_REPORTS = 'DELETE_MULTIPLE_REPORTS'
export const CHANGE_MULTIPLE_REPORT_STATUS = 'CHANGE_MULTIPLE_REPORT_STATUS'

export const GET_REPORT_INFO = 'GET_REPORT_INFO'
export const GET_REPORT_INFO_NOT_FOUND = 'GET_REPORT_INFO_NOT_FOUND'
export const GET_REPORT_INFO_NOT_ACCESSIBLE = 'GET_REPORT_INFO_NOT_ACCESSIBLE'
export const GET_REPORT_INFO_DONE = 'GET_REPORT_INFO_DONE'

export const REPORT_HAS_ERRORS = 'REPORT_HAS_ERRORS'

export const GET_REPORT_AGGREGATION = 'GET_REPORT_AGGREGATION'
export const GET_REPORT_AGGREGATION_DONE = 'GET_REPORT_AGGREGATION_DONE'

export const GET_BUCKET_TOTAL = 'GET_BUCKET_TOTAL'
export const GET_BUCKET_TOTAL_DONE = "GET_BUCKET_TOTAL_DONE"

export const GET_REPORT_MULTIPLE_AGGREGATIONS = 'GET_REPORT_MULTIPLE_AGGREGATIONS'
export const GET_REPORT_MULTIPLE_AGGREGATIONS_DONE = 'GET_REPORT_MULTIPLE_AGGREGATIONS_DONE'

export const GET_SUM_VIEW_DATA = 'GET_SUM_VIEW_DATA'
export const GET_SUM_VIEW_DATA_DONE = 'GET_SUM_VIEW_DATA_DONE'

export const GET_REPORT_TRENDSETS = 'GET_REPORT_TRENDSETS'
export const GET_REPORT_TRENDSETS_DONE = 'GET_REPORT_TRENDSETS_DONE'

export const DOWNLOAD_REPORT_DIFF_GRID_DATA = 'DOWNLOAD_REPORT_DIFF_GRID_DATA'
export const GET_REPORT_DIFF = 'GET_REPORT_DIFF'
export const GET_REPORT_DIFF_DONE = 'GET_REPORT_DIFF_DONE'

export const GET_HISTORY_DATA = 'GET_HISTORY_DATA'
export const GET_HISTORY_DATA_DONE = 'GET_HISTORY_DATA_DONE'

export const GET_HISTORY_PROFIT_DATA = 'GET_HISTORY_PROFIT_DATA'
export const GET_HISTORY_PROFIT_DATA_DONE = 'GET_HISTORY_PROFIT_DATA_DONE'

export const GET_ACTUAL_GRAPH_DATA = 'GET_ACTUAL_GRAPH_DATA'
export const GET_ACTUAL_GRAPH_DATA_DONE = 'GET_ACTUAL_GRAPH_DATA_DONE'

export const DOWNLOAD_REPORT_GRID_DATA = 'DOWNLOAD_REPORT_GRID_DATA'
export const GET_REPORT_GRID_DATA = 'GET_REPORT_GRID_DATA'
export const GET_REPORT_GRID_DATA_DONE = 'GET_REPORT_GRID_DATA_DONE'

export const JUMP_TO_REPORT_COMPOSE = 'JUMP_TO_REPORT_COMPOSE'
export const JUMPED_TO_REPORT_COMPOSE = 'JUMPED_TO_REPORT_COMPOSE'

export const START_LOADING = 'START_LOADING'

export const GET_ITEMS_COMMENTS = 'GET_ITEMS_COMMENTS'
export const GET_ITEMS_COMMENTS_DONE = 'GET_ITEMS_COMMENTS_DONE'

export const GET_REPORT_SLICER_VALUES = 'GET_REPORT_SLICER_VALUES'
export const GET_REPORT_SLICER_VALUES_DONE = 'GET_REPORT_SLICER_VALUES_DONE'

export const GET_REPORT_SLICER_AVAILABLE_VALUES = 'GET_REPORT_SLICER_AVAILABLE_VALUES'
export const GET_REPORT_SLICER_AVAILABLE_VALUES_DONE = 'GET_REPORT_SLICER_AVAILABLE_VALUES_DONE'

export const GET_REPORT_GROUPS = 'GET_REPORT_GROUPS'
export const GET_REPORT_GROUPS_DONE = 'GET_REPORT_GROUPS_DONE'

export const UPDATE_REPORT = 'UPDATE_REPORT'
export const UPDATE_REPORT_DONE = 'UPDATE_REPORT'

export const UPDATE_REPORT_KPIS = 'UPDATE_REPORT_KPIS'
export const UPDATE_REPORT_KPIS_DONE = 'UPDATE_REPORT_KPIS_DONE'

export const UPDATE_REPORT_KPICONFIG = 'UPDATE_REPORT_KPICONFIG'
export const UPDATE_REPORT_KPICONFIG_DONE = 'UPDATE_REPORT_KPICONFIG_DONE'

export const COPY_REPORT = 'COPY_REPORT'
export const COPY_REPORT_DONE = 'COPY_REPORT_DONE'

export const DELETE_REPORT = 'DELETE_REPORT'

export const TOO_MANY_INSIGHTS = 'TOO_MANY_INSIGHTS'

export const CAN_DELETE_REPORT = 'CAN_DELETE_REPORT'
export const CAN_DELETE_REPORT_DONE = 'CAN_DELETE_REPORT_DONE'

export const UPDATE_VIEWSETTINGS = 'UPDATE_VIEWSETTINGS'

export const UPDATE_KPIS_VIEWSETTINGS = 'UPDATE_KPIS_VIEWSETTINGS'

export const SAVE_REPORT_DESCRIPTION = 'SAVE_REPORT_DESCRIPTION'

export const MOVE_GROUP = 'MOVE_GROUP'

export const DELETE_GROUP = 'DELETE_GROUP'

export const CREATE_GROUP = 'CREATE_GROUP'

export const REMOVE_COLUMNS = 'REMOVE_COLUMNS'
export const REPORT_MANUAL_FIX = 'REPORT_MANUAL_FIX'

export const REMOVE_CHECKLIST_COLUMNS = 'REMOVE_CHECKLIST_COLUMNS'

export const ASSIGN_GROUP = 'ASSIGN_GROUP'

export const EDIT_GROUP = 'EDIT_GROUP'

export const GET_ORGANIZATION = "GET_ORGANIZATION"
export const GET_ORGANIZATION_DONE = "GET_ORGANIZATION_DONE"
export const GET_ORG_USERS = 'GET_ORG_USERS'
export const GET_ORG_USERS_DONE = 'GET_ORG_USERS_DONE'
export const GET_ORGANIZATION_LOCATIONS = 'GET_ORGANIZATION_LOCATIONS'
export const GET_ORGANIZATION_LOCATIONS_DONE = 'GET_ORGANIZATION_LOCATIONS_DONE'

export const CREATE_CHECKLIST_FROM_REPORT = 'CREATE_CHECKLIST_FROM_REPORT'
export const CREATE_CHECKLIST_FROM_REPORT_DONE = 'CREATE_CHECKLIST_FROM_REPORT_DONE'

export const UPDATE_CHECKLISTS = 'UPDATE_CHECKLISTS'
export const UPDATE_CHECKLISTS_DONE = 'UPDATE_CHECKLISTS_DONE'
export const UPDATE_CHECKLIST = 'UPDATE_CHECKLIST'
export const UPDATE_CHECKLIST_DONE = 'UPDATE_CHECKLIST_DONE'

export const DELETE_CHECKLIST = 'DELETE_CHECKLIST'
export const DELETE_MULTIPLE_CHECKLISTS = 'DELETE_MULTIPLE_CHECKLISTS'

export const FIX_CHECKLIST = "FIX_CHECKLIST"

export const UPDATE_CHECKLIST_ITEMS = 'UPDATE_CHECKLIST_ITEMS'
export const UPDATE_CHECKLIST_ITEMS_MULTIPLE = 'UPDATE_CHECKLIST_ITEMS_MULTIPLE'
export const UPDATE_CHECKLIST_ITEMS_MULTIPLE_DONE = 'UPDATE_CHECKLIST_ITEMS_MULTIPLE_DONE'
export const RESET_CHECKLIST_ITEMS = 'RESET_CHECKLIST_ITEMS'

export const DUPLICATE_CHECKLIST = 'DUPLICATE_CHECKLIST'

export const DOWNLOAD_CHECKLIST_DATA = 'DOWNLOAD_CHECKLIST_DATA'

export const TOO_MANY_ACTIONS = 'TOO_MANY_ACTIONS'
export const ACTION_STATUS_LOADING = 'ACTION_STATUS_LOADING'

export const SEARCH = 'SEARCH'
export const SEARCH_DONE = 'SEARCH_DONE'
export const CLEAR_SEARCH = 'CLEAR_SEARCH'

export const GET_NOTIFICATIONS = 'GET_NOTIFICATIONS'
export const GET_NOTIFICATIONS_DONE = 'GET_NOTIFICATIONS_DONE'
export const GET_UNREAD_NOTIFICATIONS = 'GET_UNREAD_NOTIFICATIONS'
export const GET_UNREAD_NOTIFICATIONS_DONE = 'GET_UNREAD_NOTIFICATIONS_DONE'
export const READ_NOTIFICATION = 'READ_NOTIFICATION'
export const READ_NOTIFICATION_DONE = 'READ_NOTIFICATION_DONE'
export const UNREAD_NOTIFICATION = 'UNREAD_NOTIFICATION'
export const UNREAD_NOTIFICATION_DONE = 'UNREAD_NOTIFICATION_DONE'
export const READ_ALL_NOTIFICATIONS = 'READ_ALL_NOTIFICATIONS'
export const READ_ALL_NOTIFICATIONS_DONE = 'READ_ALL_NOTIFICATIONS_DONE'

// export const GET_ITEM = 'GET_ITEM'
// export const GET_ITEM_DONE = 'GET_ITEM_DONE'

export const GET_CHECKLIST = 'GET_CHECKLIST'
export const GET_CHECKLIST_DONE = 'GET_CHECKLIST_DONE'
export const GET_CHECKLIST_NOT_FOUND = 'GET_CHECKLIST_NOT_FOUND'
export const GET_CHECKLIST_NOT_ACCESSIBLE = 'GET_CHECKLIST_NOT_ACCESSIBLE'
export const GET_CHECKLIST_ITEMS = 'GET_CHECKLIST_ITEMS'
export const GET_CHECKLIST_CATEGORY_ITEMS = 'GET_CHECKLIST_CATEGORY_ITEMS'
export const GET_CHECKLIST_ITEMS_DONE = 'GET_CHECKLIST_ITEMS_DONE'
export const GET_CHECKLIST_CATEGORY_ITEMS_DONE = 'GET_CHECKLIST_CATEGORY_ITEMS_DONE'
export const RESET_CHECKLIST_CATEGORY_ITEMS = 'RESET_CHECKLIST_CATEGORY_ITEMS'
export const GET_CHECKLIST_COMMENTS = 'GET_CHECKLIST_COMMENTS'
export const GET_CHECKLIST_COMMENTS_DONE = 'GET_CHECKLIST_COMMENTS_DONE'
export const CHANGE_STATUS = 'CHANGE_STATUS'
export const DELETE_CHECKLIST_ITEMS = 'DELETE_CHECKLIST_ITEMS'
export const COMPLETE_CHECKLIST = 'COMPLETE_CHECKLIST'
export const REACTIVATE_CHECKLIST = 'REACTIVATE_CHECKLIST'
export const SPLIT_CHECKLIST = 'SPLIT_CHECKLIST'
export const SAVE_CHECKLIST_LAYOUT = 'SAVE_CHECKLIST_LAYOUT'
export const EDIT_CHECKLIST = 'EDIT_CHECKLIST'

export const GET_ACTION_TEMPLATES = "GET_ACTION_TEMPLATES"
export const GOT_ACTION_TEMPLATES = "GOT_ACTION_TEMPLATES"
export const CREATE_ACTION_FROM_TEMPLATE = "CREATE_ACTION_FROM_TEMPLATE"

export const CLEAR_CHECKLISTS_PROPS = 'CLEAR_CHECKLISTS_PROPS'
export const SAVE_MULT_LAYOUTS = 'SAVE_MULT_LAYOUTS'

export const GET_LOCAL_CHECKLIST_COMMENTS = 'GET_LOCAL_CHECKLIST_COMMENTS'
export const GET_LOCAL_CHECKLIST_COMMENTS_DONE = 'GET_LOCAL_CHECKLIST_COMMENTS_DONE'
export const GET_ALL_LOCAL_CHECKLIST_COMMENTS = 'GET_ALL_LOCAL_CHECKLIST_COMMENTS'
export const GET_ALL_LOCAL_CHECKLIST_COMMENTS_DONE = 'GET_ALL_LOCAL_CHECKLIST_COMMENTS_DONE'
export const UPDATE_LOCAL_CHECKLIST_COMMENT = 'UPDATE_LOCAL_CHECKLIST_COMMENT'
export const DELETE_LOCAL_CHECKLIST_COMMENT = 'DELETE_LOCAL_CHECKLIST_COMMENT'
export const CREATE_LOCAL_CHECKLIST_COMMENT = 'CREATE_LOCAL_CHECKLIST_COMMENT'

export const GET_CHECKLIST_ITEM_DATA = 'GET_CHECKLIST_ITEM_DATA'
export const GET_CHECKLIST_ITEM_DATA_DONE = 'GET_CHECKLIST_ITEM_DATA_DONE'

export const GET_ITEM_COMMENTS = 'GET_ITEM_COMMENTS'
export const GET_ITEM_COMMENTS_DONE = 'GET_ITEM_COMMENTS_DONE'

export const GET_ITEM_DATA = 'GET_ITEM_DATA'
export const GET_ITEM_DATA_NOT_FOUND = 'GET_ITEM_DATA_NOT_FOUND'
export const GET_ITEM_DATA_DONE = 'GET_ITEM_DATA_DONE'

export const GET_ITEM_DRILLDOWN = 'GET_ITEM_DRILLDOWN'
export const GET_ITEM_DRILLDOWN_DONE = 'GET_ITEM_DRILLDOWN_DONE'

export const DOWNLOAD_TRANSACTION_DATA = 'DOWNLOAD_TRANSACTION_DATA'

export const GET_ITEM_CATEGORY_HISTORY = 'GET_ITEM_CATEGORY_HISTORY'
export const GET_ITEM_CATEGORY_HISTORY_DONE = 'GET_ITEM_CATEGORY_HISTORY_DONE'

export const GET_ITEM_CATEGORY_HISTORY_GRAPH = 'GET_ITEM_CATEGORY_HISTORY_GRAPH'
export const GET_ITEM_CATEGORY_HISTORY_GRAPH_DONE = 'GET_ITEM_CATEGORY_HISTORY_GRAPH_DONE'

export const GET_ITEM_CATEGORY_HISTORY_GRAPH_ALL = 'GET_ITEM_CATEGORY_HISTORY_GRAPH_ALL'
export const GET_ITEM_CATEGORY_HISTORY_GRAPH_ALL_DONE = 'GET_ITEM_CATEGORY_HISTORY_GRAPH_ALL_DONE'

export const GET_ITEM_LINKS = 'GET_ITEM_LINKS'
export const GET_ITEM_LINKS_DONE = 'GET_ITEM_LINKS_DONE'

export const ADD_ITEM_COMMENT = 'ADD_ITEM_COMMENT'
export const UPDATE_ITEM_COMMENT = 'UPDATE_ITEM_COMMENT'
export const DELETE_ITEM_COMMENT = 'DELETE_ITEM_COMMENT'

export const GET_BUCKET_INFO = 'GET_BUCKET_INFO'
export const GET_BUCKET_INFO_DONE = 'GET_BUCKET_INFO_DONE'
export const GET_ALL_BUCKETS = 'GET_ALL_BUCKETS'
export const GET_ALL_BUCKETS_DONE = 'GET_ALL_BUCKETS_DONE'
export const GET_BUCKETS_INFO = 'GET_BUCKETS_INFO'
export const GET_BUCKETS_INFO_DONE = 'GET_BUCKETS_INFO_DONE'
export const GET_DOWNLOAD_TOKEN = 'GET_DOWNLOAD_TOKEN'
export const GET_DOWNLOAD_TOKEN_DONE = 'GET_DOWNLOAD_TOKEN_DONE' 
export const GET_BUCKET_USERS = 'GET_BUCKET_USERS'
export const GET_BUCKET_USERS_DONE = 'GET_BUCKET_USERS_DONE'
export const GET_BUCKET_SETUP = 'UPDATE_BUCKET_SETUP'
export const UPDATE_BUCKET_SETUP = 'UPDATE_BUCKET_SETUP'

export const GET_PROFIT_DATA = 'GET_PROFIT_DATA'
export const GET_PROFIT_DATA_DONE = 'GET_PROFIT_DATA_DONE' 

export const GET_STOCK_BALANCE_DATA = 'GET_STOCK_BALANCE_DATA'
export const GET_STOCK_BALANCE_DATA_DONE = 'GET_STOCK_BALANCE_DATA_DONE' 

export const GET_STOCK_BALANCE_COLUMN_DATA = 'GET_STOCK_BALANCE_COLUMN_DATA'
export const GET_STOCK_BALANCE_COLUMN_DATA_DONE = 'GET_STOCK_BALANCE_COLUMN_DATA_DONE' 


export const SET_NEW_REPORT_BUCKET = 'SET_NEW_REPORT_BUCKET'

export const UPLOAD_LOCAL_CHECKLIST = 'UPLOAD_LOCAL_CHECKLIST'
export const UPLOAD_LOCAL_CHECKLIST_DONE = 'UPLOAD_LOCAL_CHECKLIST_DONE'
export const SAVE_LAYOUT = 'SAVE_LAYOUT'
export const UPDATE_LAYOUT = 'UPDATE_LAYOUT'
export const SAVE_LAYOUT_DONE = 'SAVE_LAYOUT_DONE'
export const DELETE_LAYOUT = 'DELETE_LAYOUT'
export const GET_LAYOUTS = 'GET_LAYOUTS'
export const GET_LAYOUTS_DONE = 'GET_LAYOUTS_DONE'

export const FORGOT_PASSWORD = 'FORGOT_PASSWORD'
export const RESET_PASSWORD = 'RESET_PASSWORD'
export const SELECT_PASSWORD = 'SELECT_PASSWORD'

export const REPORT_ERROR = 'REPORT_ERROR'
export const REPORT_ERROR_DONE = 'REPORT_ERROR_DONE'
export const COMMENT_ERROR_REPORT = 'COMMENT_ERROR_REPORT'
export const COMMENT_ERROR_REPORT_DONE = 'COMMENT_ERROR_REPORT_DONE'

export const FIX_REPORT = 'FIX_REPORT'

export const GET_DASHBOARD_DATA = 'GET_DASHBOARD_DATA'
export const GET_DASHBOARD_DATA_DONE = 'GET_DASHBOARD_DATA_DONE'
export const GET_DASHBOARD_OVERVIEW = 'GET_DASHBOARD_OVERVIEW'
export const GET_DASHBOARD_OVERVIEW_DONE = 'GET_DASHBOARD_OVERVIEW_DONE'
export const SET_HOME_DASHBOARD = 'SET_HOME_DASHBOARD'
export const SET_HOME_DASHBOARD_FOR_USER = 'SET_HOME_DASHBOARD_FOR_USER'
export const UPDATE_HOME_DASHBOARD_DONE = 'UPDATE_HOME_DASHBOARD_DONE'
export const GET_HOME_DASHBOARD = 'GET_HOME_DASHBOARD'
export const GET_HOME_DASHBOARD_DONE = 'GET_HOME_DASHBOARD_DONE'
export const GET_DEFAULT_DASHBOARD = 'GET_DEFAULT_DASHBOARD'
export const GET_DEFAULT_DASHBOARD_DONE = 'GET_DEFAULT_DASHBOARD_DONE'
export const DASHBOARD_NOT_ACCESSIBLE = 'DASHBOARD_NOT_ACCESSIBLE'

export const CREATE_DASHBOARD = 'CREATE_DASHBOARD'
export const COPY_DASHBOARD = 'COPY_DASHBOARD'
export const UPDATE_DASHBOARD = 'UPDATE_DASHBOARD'
export const UPDATE_DASHBOARD_DONE = 'UPDATE_DASHBOARD_DONE'
export const GET_DASHBOARD_REPORTS = 'GET_DASHBOARD_REPORTS'
export const GET_DASHBOARD_REPORTS_DONE = 'GET_DASHBOARD_REPORTS_DONE'
export const CALCULATE_DASHBOARD_TABLE = 'CALCULATE_DASHBOARD_TABLE'
export const CALCULATE_TMP_DASHBOARD = 'CALCULATE_TMP_DASHBOARD'
export const DELETE_DASHBOARD = 'DELETE_DASHBOARD'

export const MAKE_REPORT_PUBLIC = 'MAKE_REPORT_PUBLIC'
export const MAKE_REPORT_PUBLIC_DONE = 'MAKE_REPORT_PUBLIC_DONE'

export const CREATE_PENDING_USER = 'CREATE_PENDING_USER'

export const GET_DOWNLOAD_INFO = 'GET_DOWNLOAD_INFO'
export const GET_DOWNLOAD_INFO_DONE = 'GET_DOWNLOAD_INFO_DONE'
export const REQUEST_DOWNLOAD_FILE = 'REQUEST_DOWNLOAD_FILE'

export const GET_PASSWORD_POLICY_DONE = 'GET_PASSWORD_POLICY_DONE'
export const GET_PASSWORD_POLICY = 'GET_PASSWORD_POLICY'
export const GET_PASSWORD_POLICY_FROM_TOKEN = 'GET_PASSWORD_POLICY_FROM_TOKEN'

export const GET_NPS_ANSWER = 'GET_NPS_ANSWER'
export const GET_NPS_ANSWER_DONE = 'GET_NPS_ANSWER_DONE'
export const POSTPONE_NPS_ANSWER = 'POSTPONE_NPS_ANSWER'
export const ANSWER_NPS = 'ANSWER_NPS'

export const GET_FOLLOWINGS_DONE = 'GET_FOLLOWINGS_DONE'
export const GET_FOLLOWINGS = 'GET_FOLLOWINGS'
export const FOLLOW_ITEM = 'FOLLOW_ITEM'

export const GET_ORGANIZATION_USERS = "GET_ORGANIZATION_USERS"
export const GET_ORGANIZATION_USERS_DONE = "GET_ORGANIZATION_USERS_DONE"

export const GET_ORGANIZATION_USER = "GET_ORGANIZATION_USER"
export const GET_ORGANIZATION_USER_DONE = "GET_ORGANIZATION_USER_DONE"

export const GET_ORGANIZATION_ITEMS = "GET_ORGANIZATION_ITEMS"
export const GET_ORGANIZATION_ITEMS_DONE = "GET_ORGANIZATION_ITEMS_DONE"

export const GET_ORGANIZATION_SUBSCRIPTION_SETTINGS = "GET_ORGANIZATION_SUBSCRIPTION_SETTINGS"
export const GET_ORGANIZATION_SUBSCRIPTION_SETTINGS_DONE = "GET_ORGANIZATION_SUBSCRIPTION_SETTINGS_DONE"

export const GET_REPORT_TEMPLATES = "GET_REPORT_TEMPLATES"
export const GOT_REPORT_TEMPLATES = "GOT_REPORT_TEMPLATES"
export const CREATE_REPORT_FROM_TEMPLATE = "CREATE_REPORT_FROM_TEMPLATE"

export const GET_TEST_E2E = "GET_TEST_E2E"
export const GOT_TEST_E2E = "GOT_TEST_E2E"
export const GET_CXM_SETUPS = "GET_CXM_SETUPS"
export const GOT_CXM_SETUPS = "GOT_CXM_SETUPS"
export const GET_E2E_SETUPS = "GET_E2E_SETUPS"
export const GOT_E2E_SETUPS = "GOT_E2E_SETUPS"
export const QUERY_E2E = "QUERY_E2E"
export const GOT_E2E_DATA = "GOT_E2E_DATA"
export const QUERY_E2E_GRID = "QUERY_E2E_GRID"
export const GOT_E2E_GRID_DATA = "GOT_E2E_GRID_DATA"
export const GET_ORG_MAILBUNDLES = "GET_ORG_MAILBUNDLES"
export const GET_ORG_MAILBUNDLES_DONE = "GET_ORG_MAILBUNDLES_DONE"
export const CREATE_MAILBUNDLE = "CREATE_MAILBUNDLE"
export const CREATE_MAILBUNDLE_DONE = "CREATE_MAILBUNDLE_DONE"
export const UPDATE_MAILBUNDLE = "UPDATE_MAILBUNDLE"
export const UPDATE_MAILBUNDLE_DONE = "UPDATE_MAILBUNDLE_DONE"
export const DELETE_MAILBUNDLE = "DELETE_MAILBUNDLE"
export const DELETE_MAILBUNDLE_DONE = "DELETE_MAILBUNDLE_DONE"
export const TOGGLE_MAILBUNDLE_ACTIVE = "TOGGLE_MAILBUNDLE_ACTIVE"
export const TOGGLE_MAILBUNDLE_ACTIVE_DONE = "TOGGLE_MAILBUNDLE_ACTIVE_DONE"
export const ACTIVATE_MULTIPLE_MAILBUNDLES = "ACTIVATE_MULTIPLE_MAILBUNDLES"
export const ACTIVATE_MULTIPLE_MAILBUNDLES_DONE = "ACTIVATE_MULTIPLE_MAILBUNDLES_DONE"
export const DEACTIVATE_MULTIPLE_MAILBUNDLES = "DEACTIVATE_MULTIPLE_MAILBUNDLES"
export const DEACTIVATE_MULTIPLE_MAILBUNDLES_DONE = "DEACTIVATE_MULTIPLE_MAILBUNDLES_DONE"
export const BATCH_DELETE_MAILBUNDLES = "BATCH_DELETE_MAILBUNDLES"
export const BATCH_DELETE_MAILBUNDLES_DONE = "BATCH_DELETE_MAILBUNDLES_DONE"
export const GET_ALL_REPORTING_PLANS = "GET_ALL_REPORTING_PLANS"
export const GET_ALL_REPORTING_PLANS_DONE = "GET_ALL_REPORTING_PLANS_DONE"

export const GET_ORGANIZATION_SERVICES = "GET_ORGANIZATION_SERVICES"
export const GOT_ORGANIZATION_SERVICES = "GOT_ORGANIZATION_SERVICES"


export const packAction = (action, data, cb = (v) => {}, errCb = (e) => {}) => {
    return {
        type: action,
        payload: data,
        cb: cb,
        errCb: errCb
    }
}
