import React, { useState } from "react";
import { CxmColumn, End2EndColumnSettings } from "../../types/cxmtypes";
import { DataBucket, QueryCriterion, Column } from "../../types/transfertypes";
import useConfirmDialog from "../../hooks/useConfirmDialog";
import AddSlicerDialog2 from "../Dialogs/AddSlicerDialog2";
import { CompareType } from "../../types/slicertypes";
import { getSlicerValues } from "../../actions/ReportActions";
import { Dispatch, AnyAction } from "redux";
import styles from "../../css/End2EndColumn.module.css";
import ShowIf from "../Generic/ShowIf";
import { Arrow } from "../Report/Slicer2";
import Drawer from "../Animation/Drawer";

interface Props extends React.HTMLAttributes<HTMLDivElement> {
    c: CxmColumn;
    bucket: DataBucket;
    settings: End2EndColumnSettings;
    updateSettings: (updateFunc: (setting: End2EndColumnSettings) => void) => void;
    dispatch: Dispatch<AnyAction>;
    idColumn: Column
}

const End2EndGridColumn = ({ c, bucket, settings, updateSettings: updateSettingsProps, dispatch, ...props }: Props) => {
    const updateSettings = (updateFunc: (column: End2EndColumnSettings) => void) => {
        updateSettingsProps((s) => {
            updateFunc(s);
            //Pruning empty filters
            s.filter = s.filter.filter((f) => f.target_values.length > 0);
        });
    };
    const [showAddFilter, setShowAddFilter] = useState(false);
    const [chosenFilter, setChosenFilter] = useState<QueryCriterion>();
    const [addSlicerSearchStr, setAddSlicerSearchStr] = useState("");
    const [showFilterList, setShowFilterList] = useState(false);
    
    const { confirm, ConfirmDialog } = useConfirmDialog();

    const saveFilter = (filter: QueryCriterion) => {
        updateSettings((s) => {
            const filterIndex = s.filter.findIndex((f) => f.column === filter.column);

            if (filterIndex != -1) {
                s.filter[filterIndex] = filter;
            } else {
                s.filter.push(filter);
            }
        });
        setShowAddFilter(false);
        setChosenFilter(undefined);
    };

    const getSlicerColumns = () => {
        let columns: string[] = bucket.info.model.columns.map((c) => c.name);
        columns.sort(function (a, b) {
            return a.toLowerCase().localeCompare(b.toLowerCase());
        });

        return columns;
    };

    const columnClick = (column: string) => {
        const filter = settings.filter.find((f) => f.column == column);
        if (filter != undefined) {
            setChosenFilter(filter);
        } else {
            const f: QueryCriterion = {
                column: column,
                compare_type: CompareType.EQ,
                target_values: [],
                is_column: false,
            };
            setChosenFilter(f);
        }
        dispatch(getSlicerValues(bucket.info.id, { column: column, filter: [] }));
        setShowAddFilter(true);
    };

    const removeFilter = async (filter: QueryCriterion) => {
        const answer = await confirm(
            <span>
                This will remove the filter on <i>{filter.column}</i>, are you sure?
            </span>,
        );
        if (answer) {
            updateSettings((s) => {
                const filterIndex = s.filter.findIndex((f) => f.column == filter.column);
                if (filterIndex < 0) return;
                s.filter.splice(filterIndex, 1);
            });
        }
    };

    const filterList = settings.filter.map((f, i) => {
        return (
            <div className={styles.filterBox} key={i} onClick={() => columnClick(f.column)}>
                <span title={f.column} className={"line-clamp-1"}>
                    {f.column}
                </span>
                <span> ({f.target_values.length})</span>
                <i
                    onClick={(e) => {
                        e.stopPropagation();
                        removeFilter(f);
                    }}
                    className={`fa fa-times ${styles.closeButton}`}
                    aria-hidden="true"
                ></i>
            </div>
        );
        // return <SingleSlicer2 
        //     key={i}
        //     slicer={f}
        //     index={i}
        //     openSlicer={() => columnClick(f.column)}
        //     model={bucket.info.model}
        //     removeSlicer={removeFilter}
        //     idColumn={props.idColumn.name}
        //     noMargin
        // />
    });
    let columns: string[] = getSlicerColumns();

    return <>
            <div className={`d-flex flex-column align-items-center ${props.className ?? ""}`}>
                <div className={styles.title}>
                    <span>{bucket.info.name}</span>
                </div>
                <div className="d-flex align-items-center flex-column flex-wrap" style={{ gap: 5 }}>
                    {/* Start of filter button */}
                    <div className="dropdown inline-block d-flex vertical-align-top" style={{ gap: 5 }}>
                        <button
                            className="btn btn-sm btn-primary"
                            style={{height: 33, width: 33}}
                            type="button"
                            id="dropdownMenu2"
                            data-toggle="dropdown"
                            aria-expanded="false"
                        >
                            <i className="fa fa-plus"></i>
                        </button>

                        {/* Button to add filters if no filters are applied. */}
                        {/* Placed here to make the dropdown be placed correctly */}
                        <ShowIf if={settings.filter.length == 0}>
                            <button
                                className={styles.filterButton}
                                style={{ justifyContent: "start", gap: 5, flexGrow: 1, width: 150 }}
                                onClick={() => setShowFilterList((v) => !v)}
                                id="dropdownMenu2"
                                data-toggle="dropdown"
                            >
                                <i style={{ opacity: 0.5 }} className="fa fa-filter" aria-hidden="true"></i>
                                <span style={{ opacity: 0.5 }}>No filters</span>
                            </button>
                        </ShowIf>
                        <ShowIf if={settings.filter.length == 1}>{filterList}</ShowIf>
                        <ShowIf if={settings.filter.length > 1}>
                            <button className={styles.filterButton} style={{width: 150}} onClick={() => setShowFilterList((v) => !v)}>
                                <i className="fa fa-filter" aria-hidden="true"></i>
                                <span>Filters ({settings.filter.length})</span>

                                <div onFocus={(e) => e.target.blur()} className={styles.filterDrawerButton}>
                                    <Arrow pose={showFilterList ? "visible" : "hidden"} className={"fa fa-angle-up"} />
                                </div>
                            </button>
                        </ShowIf>
                        <div
                            className="dropdown-menu scrollable-dropdown-menu"
                            aria-labelledby="dropdownMenu2"
                            style={{ paddingTop: 0 }}
                        >
                            <div className="text-center px-2 sticky-modal-header" style={{ paddingTop: "6px" }}>
                                <input
                                    type="text"
                                    value={addSlicerSearchStr}
                                    placeholder="Search columns"
                                    className="form-control form-control-sm"
                                    onChange={(e) => setAddSlicerSearchStr(e.target.value)}
                                />
                                <div className="dropdown-divider"></div>
                            </div>
                            {columns
                                .filter(
                                    (c) =>
                                        addSlicerSearchStr === "" ||
                                        c.toLowerCase().includes(addSlicerSearchStr.toLowerCase()),
                                )
                                .map((col, index) => {
                                    const filter = settings.filter.find((f) => f.column == col);
                                    return (
                                        <button
                                            className="hover-cursor dropdown-item"
                                            type="button"
                                            onClick={() => columnClick(col)}
                                            key={index}
                                        >
                                            <div className="d-flex flex-row align-items-center justify-content-between">
                                                <span>{col}</span>
                                                {filter != undefined && filter.target_values.length > 0 && (
                                                    <div style={{ gap: 5 }} className="d-flex align-items-center">
                                                        <span style={{ marginLeft: 5 }}>
                                                            ({filter.target_values.length})
                                                        </span>
                                                        <i
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                removeFilter(filter);
                                                            }}
                                                            className={`fa fa-times ${styles.closeButton}`}
                                                            aria-hidden="true"
                                                        ></i>
                                                    </div>
                                                )}
                                            </div>
                                        </button>
                                    );
                                })}
                        </div>
                    </div>
                <ShowIf if={settings.filter.length > 0}>
                    {settings.filter.length > 1 && (
                        <>
                            <div style={{width: 200}}>
                                <Drawer expand={showFilterList}>
                                    <div className={styles.filterContainer}>{filterList}</div>
                                </Drawer>
                            </div>
                        </>
                    )}
                </ShowIf>

                </div>
            </div>
            {chosenFilter != undefined && (
                <AddSlicerDialog2
                    show={showAddFilter}
                    slicer={chosenFilter}
                    slicers={settings.filter}
                    saveSlicer={saveFilter}
                    onCancel={() => {
                        setChosenFilter(undefined);
                        setShowAddFilter(false);
                    }}
                    bucket={bucket.info}
                />
            )}
        <ConfirmDialog />
    </>;
};

export default End2EndGridColumn
