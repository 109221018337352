import * as ActionTypes from "../actions/ActionTypes";
import { End2EndAction } from "../actions/End2EndActions";
import { CxmMultipleAggregationQueryResult, CxmSetup, End2EndSetup } from "../types/cxmtypes";
import { Reducer } from "redux";

interface End2EndState {
    results?: CxmMultipleAggregationQueryResult[];
    cxmSetups?: CxmSetup[];
    setups?: End2EndSetup[];
}

const defaultState: End2EndState = {
    results: undefined,
    cxmSetups: undefined,
    setups: undefined,
};

const End2End: Reducer<End2EndState> = (state = defaultState, action: End2EndAction) => {
    switch (action.type) {
        case ActionTypes.GOT_TEST_E2E:
            return { ...state, results: action.payload };
        case ActionTypes.GOT_CXM_SETUPS:
            return { ...state, cxmSetups: action.payload };
        case ActionTypes.GOT_E2E_SETUPS:
            return { ...state, setups: action.payload };
        case ActionTypes.GOT_E2E_DATA:
            return { ...state, results: action.payload };
    }

    return state;
};

export default End2End;
