import { RootState } from "..";
import { connect, ConnectedProps } from "react-redux";
import React, { useEffect, useState, useMemo, useRef } from "react";
import { getCxmSetups, queryE2E, getE2ESetups } from "../actions/End2EndActions";
import { useRouteMatch, useLocation } from "react-router";
import LoadingAnimation from "../components/LoadingAnimation";
import { getBucketsDataInfo } from "../actions/BucketActions";
import { SingleKeyMap } from "../helpers/Collections";
import { DataBucket } from "../types/transfertypes";
import Drawer from "../components/Animation/Drawer";
import { Arrow } from "../components/Report/Slicer2";
import {
    CxmQuery,
    CxmColumnQuery,
    End2EndFullSetup,
    End2EndSettingsMap,
    getCatName,
    CxmColumn,
    End2EndSettings,
} from "../types/cxmtypes";
import End2EndColumn from "../components/End2End/End2EndColumn";
import ShowIf from "../components/Generic/ShowIf";
import styles from "../css/SingleEnd2End.module.css";
import End2EndDrawer from "../components/End2End/End2EndDrawer";
import { AggregationType, compareObjects } from "../helpers/TypeHelpers";
import useConfirmDialog from "../hooks/useConfirmDialog";
import { notifySuccess } from "../helpers/NotificationManager";
import { apiPost, apiPut } from "../helpers/ApiHelpers";
import { notifyApiFailure } from "../helpers/ErrorHelpers";
import GenericDialog from "../components/Dialogs/GenericDialog";
import RequirePermission from "../components/RequirePermission";
import { is } from "../helpers/PermissionHelpers";
import { End2EndTransferSettings } from "./End2EndGridView";
import { getUniqueDescription } from "../helpers/end2endHelpers";

let mapStateToProps = (state: RootState) => {
    return {
        results: state.End2End.results,
        cxmSetups: state.End2End.cxmSetups,
        setups: state.End2End.setups,
        buckets: state.Bucket.bucketDetails as SingleKeyMap<DataBucket>,
    };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface Props extends PropsFromRedux {
    history: any;
}

const SingleEnd2End = ({ dispatch, cxmSetups, results, buckets, setups, ...props }: Props) => {
    const { confirm, ConfirmDialog } = useConfirmDialog();
    const [showSettings, setShowSettings] = useState(false);
    const [settings, setSettings] = useState<End2EndSettings>();
    const updateSettings = (updateFunc: (q: End2EndSettings) => void) => {
        if (settings == undefined) return;
        let tmp = structuredClone(settings);
        updateFunc(tmp);
        setSettings(tmp);
    };
    const [loading, setLoading] = useState(false);
    const match = useRouteMatch<{ id: string }>("/end2end/:id");
    const setup: End2EndFullSetup | undefined = useMemo(() => {
        const s1 = setups?.find((s) => s.id === Number(match?.params.id));
        if (s1 == undefined) return undefined;
        const s2 = cxmSetups?.find((s) => s.id == s1.cxm_id);
        if (s2 == undefined) return undefined;
        return {
            id: s1.id,
            cxm_id: s2.id,
            setup: s1,
            cxm: s2,
        };
    }, [cxmSetups, setups]);
    const [tmpName, setTmpName] = useState("");
    const [showSaveAs, setShowSaveAs] = useState(false);
    const [showChangeName, setShowChangeName] = useState(false);

    useEffect(() => {
        if (cxmSetups === undefined) {
            dispatch(getCxmSetups());
        }
        if (buckets.keys.length == 0) {
            dispatch(getBucketsDataInfo());
        }
        if (setups === undefined) {
            dispatch(getE2ESetups());
        }
    }, []);

    useEffect(() => {
        setLoading(false);
    }, [results]);

    useEffect(() => {
        if (setup == undefined) {
            setSettings(undefined);
            return;
        }
        setSettings(setup.setup.settings);
    }, [setup]);

    const location = useLocation<End2EndTransferSettings>();
    useEffect(() => {
        if (location.state == undefined) {
            if (setup == undefined) {
                setSettings(undefined);
            } else {
                setSettings(setup.setup.settings);
            }
            return;
        }
        setSettings(location.state.settings);
    }, [location]);


    const getSetting = (c: CxmColumn, map?: End2EndSettings) => {
        let setting = map != undefined ? map.buckets[c.bucket] : (settings?.buckets ? settings?.buckets[c.bucket]: undefined) ;
        if (setting == undefined) {
            setting = {
                aggregation_column: c.id_column,
                aggregation_type: AggregationType.COUNT,
                filter: [],
                show_decimals: false,
                show_percentages: false,
                use_second_category: false,
                grid_columns: [],
                hide_column: false,
            };
        }
        return setting;
    };

    const generateQuery = () => {
        if (setup == undefined || settings == undefined) return;
        const queries: CxmColumnQuery[] = setup.cxm.columns.map((c) => {
            //const s = settings[c.bucket];
            const s = getSetting(c);

            return {
                bucket: c.bucket,
                column: s.aggregation_column,
                categorization: getCatName(s.use_second_category, c),
                filter: s.filter,
            };
        });
        const query: CxmQuery = { setup_id: setup.cxm_id, queries: queries, unique: settings.unique };
        return query;
    };

    const queryTimeout = useRef<NodeJS.Timeout>();
    const prevQuery = useRef<CxmQuery>();
    useEffect(() => {
        if (queryTimeout.current != undefined) {
            clearTimeout(queryTimeout.current);
            queryTimeout.current = undefined;
        }
        if (setup == undefined || settings == undefined) return;
        const query = generateQuery();
        if (query == undefined) return;

        if (compareObjects(query, prevQuery.current)) return;
        queryTimeout.current = setTimeout(() => {
            setLoading(true);
            dispatch(queryE2E(query));
            prevQuery.current = query;
        }, 1000);
    }, [settings]);

    const hasChanges = !compareObjects(settings, setup?.setup.settings);

    const onSave = async () => {
        if (setup == undefined || settings == undefined) return;
        if (!(await confirm("Are you sure you want to save? This will save this setup for everyone."))) return;
        const data = {
            setup_id: setup.id,
            settings: settings,
        };
        apiPost(
            "end2end/settings",
            data,
            () => {
                notifySuccess("Successfully saved");
                dispatch(getE2ESetups());
            },
            (e) => {
                notifyApiFailure("Failed to save settings", "POST", "end2end/settings", data, e.status);
            },
        );
    };

    const onSaveAs = () => {
        if (settings == undefined || setup == undefined) return;
        setTmpName(`${setup.setup.name} - Copy`);
        setShowSaveAs(true);
    };

    const saveAs = () => {
        if (settings == undefined || tmpName == "" || setup == undefined) return;
        const url = "end2end/settings/new";
        const data = {
            name: tmpName,
            cxm_id: setup.cxm_id,
            settings: settings,
        };
        setLoading(true);
        apiPost(
            url,
            data,
            (id: number) => {
                dispatch(getE2ESetups());
                props.history.push("/end2end/" + id);
                setLoading(false);
            },
            (e) => {
                notifyApiFailure("error creating new end2end setup from settings", "POST", url, data, e.status);
                setLoading(false);
            },
        );
        setShowSaveAs(false);
    };

    const onChangeName = () => {
        if (setup == undefined) return
        setTmpName(setup.setup.name)
        setShowChangeName(true)
    }

    const changeName = () => {
        if (tmpName == "" || setup == undefined) return
        const url ="end2end/name"
        const data = {
            setup_id: setup.id,
            name: tmpName
        }
        setLoading(true)
        apiPut(
            url,
            data,
            () => {
                dispatch(getE2ESetups())
                notifySuccess("Changed name")
                setLoading(false)
            },
            (e) => {
                notifyApiFailure("error saving name of end2end setup", "PUT", url, data, e.status)
                setLoading(false)
            }
        )
        setShowChangeName(false)
    }

    const toggleUnique = () => {
        updateSettings((s) => {
            s.unique = !s.unique
        })
    }

    if (cxmSetups === undefined || results == undefined)
        return (
            <div className="d-flex flex-column align-items-center w-full">
                <LoadingAnimation />
                <h1>Loading...</h1>
            </div>
        );

    if (setup === undefined)
        return (
            <div className="d-flex flex-column align-items-center w-full">
                <h1>Could not find the chosen End2End setup</h1>
            </div>
        );

    if (settings == undefined) {
        return <h1>Loading settings</h1>
    }

    if (!settings.buckets) {
        settings.buckets = {}
        setSettings(settings)
    }

    const hasFilters = settings.buckets ? Object.values(settings.buckets).some(c => c.filter.length > 0) : false

    return (
        <div
            style={{
                padding: 20,
                paddingLeft: 15,
                paddingRight: 15,
                width: "100%",
            }}
        >
            <div className="d-flex align-items-center">
                <h1>{setup.setup.name}</h1>
                <RequirePermission perms={is.e2eadmin}>
                    <button onClick={onChangeName} className="btn btn-default ml-2 d-flex align-items-center" style={{border: "none", padding: 5, height: "fit-content"}} title="Change name"><i className="fa fa-pencil-square-o" aria-hidden="true"></i></button>
                </RequirePermission>
            </div>
            <Drawer expand={showSettings}>
                <div className={styles.drawerColumns}>
                    {setup.cxm.columns.map((c, i) => {
                        const bucket = buckets.get(c.bucket);
                        const data = results.at(i);
                        const setting = getSetting(c);
                        const maxX = !setting?.use_second_category
                            ? 0
                            : (bucket?.info.model.categorizations
                                  .find((x) => x.name == getCatName(setting.use_second_category, c))
                                  ?.categorization.reduce((a, b) => Math.max(a, b.position.x), 0) ?? 0);
                        // Width of items + gaps
                        // This makes it so that it is centered above the column it controls
                        const itemWidth = setting.use_second_category ? 120 : 200;
                        const width = (maxX + 1) * itemWidth + maxX * 10;
                        return (
                            <div
                                key={c.bucket}
                                className="d-flex flex-column align-items-center"
                                style={{ gap: 10, width: width }}
                            >
                                {bucket == undefined || data == undefined || setting == undefined ? (
                                    <h3>Datasource could not be found</h3>
                                ) : (
                                    <End2EndDrawer
                                        c={c}
                                        bucket={bucket}
                                        data={data}
                                        settings={setting}
                                        updateSettings={(f) => {
                                            updateSettings((s) => {
                                                let s2 = getSetting(c, s);
                                                f(s2);
                                                s.buckets[c.bucket] = s2;
                                            });
                                        }}
                                        dispatch={dispatch}
                                    />
                                )}
                            </div>
                        );
                    })}
                </div>
                <RequirePermission perms={is.e2eadmin}>
                <div className={styles.drawerButtons}>
                    <button onClick={onSave} disabled={!hasChanges} className="btn btn-primary btn-sm">
                        Save
                    </button>
                    <button onClick={onSaveAs} disabled={!hasChanges} className="btn btn-primary btn-sm">
                        Save as
                    </button>
                </div>
                </RequirePermission>
            </Drawer>
            <hr className="hr-color full-width-hr " />
            <div className="width-100-p text-align-center">
                <button
                    onFocus={(e) => e.target.blur()}
                    onClick={() => setShowSettings((s) => !s)}
                    className={`${styles.expandButton} btn btn-default btn-xs`}
                >
                    <Arrow pose={showSettings ? "visible" : "hidden"} className={"fa fa-angle-up"} />
                </button>

            </div>
            <div className={`${styles.uniqueBanner} ${settings.unique ? styles.enabled : ''}`}>
                <div>
                    <span style={!settings.unique ? {display: "none"} : undefined}>{getUniqueDescription(settings, buckets, setup.cxm)}</span>
                </div>
                <button style={!settings.unique && !hasFilters ? {opacity: 0} : undefined } className={`btn ${settings.unique ? 'btn-primary' : 'btn-light'}`} onClick={toggleUnique}>Unique Relation: {settings.unique ? "ON" : "OFF"}</button>
            </div>

            <div
                className="d-flex justify-content-around w-full"
                style={loading ? { pointerEvents: "none" } : undefined}
            >
                {setup.cxm.columns.map((c, i) => {
                    const bucket = buckets.get(c.bucket);
                    const data = results.at(i);
                    const setting = getSetting(c);
                    return (
                        <div key={c.bucket} className="d-flex flex-column" style={{ gap: 10 }}>
                            {bucket == undefined ||
                            data == undefined ||
                            setting == undefined ||
                            settings == undefined ? (
                                <h3>
                                    Datasource could not be found
                                </h3>
                            ) : (
                                <End2EndColumn
                                    c={c}
                                    bucket={bucket}
                                    data={data}
                                    settings={setting}
                                    updateSettings={(f) => {
                                        updateSettings((s) => {
                                            let s2 = getSetting(c, s);
                                            f(s2);
                                            s.buckets[c.bucket] = s2;
                                        });
                                    }}
                                    showGrid={() => {}}
                                    dispatch={dispatch}
                                    fullSettings={settings}
                                />
                            )}
                        </div>
                    );
                })}
            </div>
            <ShowIf if={loading}>
                <div
                    className="d-flex flex-column align-items-center justify-content-center"
                    style={{
                        width: "100vw",
                        height: "100vh",
                        position: "fixed",
                        left: 0,
                        top: 0,
                        zIndex: 99
                    }}
                >
                    <div
                        style={{
                            borderRadius: 4,
                            backgroundColor: "rgba(255,255,255,0.5)",
                            padding: 10,
                            zIndex: 1000,
                        }}
                    >
                        <LoadingAnimation />
                    </div>
                </div>
            </ShowIf>
            <ConfirmDialog confirmText="Save" title="Save" />
            <GenericDialog
                show={showSaveAs}
                title="Save as"
                onClose={() => setShowSaveAs(false)}
                getButtons={() => {
                    return (
                        <div>
                            <button
                                style={{ marginRight: 7 }}
                                onClick={saveAs}
                                disabled={tmpName == ""}
                                className="btn btn-success"
                            >
                                Create
                            </button>
                            <button onClick={() => setShowSaveAs(false)} className="btn btn-default">
                                Cancel
                            </button>
                        </div>
                    );
                }}
            >
                <label htmlFor="e2eName" className="select-label">
                    Name
                </label>
                <input
                    id="e2eName"
                    type="text"
                    placeholder="Name of setup"
                    className="form-control"
                    value={tmpName}
                    onChange={(e) => setTmpName(e.target.value)}
                />
            </GenericDialog>
            <GenericDialog
                show={showChangeName}
                title="Change name"
                onClose={() => setShowChangeName(false)}
                getButtons={() => {
                    return (
                        <div>
                            <button
                                style={{ marginRight: 7 }}
                                onClick={changeName}
                                disabled={tmpName == ""}
                                className="btn btn-success"
                            >
                                Create
                            </button>
                            <button onClick={() => setShowChangeName(false)} className="btn btn-default">
                                Cancel
                            </button>
                        </div>
                    );
                }}
            >
                <label htmlFor="e2eName" className="select-label">
                    Name
                </label>
                <input
                    id="e2eName"
                    type="text"
                    placeholder="Name of setup"
                    className="form-control"
                    value={tmpName}
                    onChange={(e) => setTmpName(e.target.value)}
                />
            </GenericDialog>
        </div>
    );
};

export default connector(SingleEnd2End);
