import React, { useEffect, useState } from "react";
// @ts-ignore
import Select from "react-select";
import { connect, ConnectedProps } from "react-redux";
import { RootState } from "..";
import { getCxmSetups, getE2ESetups } from "../actions/End2EndActions";
import LoadingAnimation from "../components/LoadingAnimation";
// @ts-ignore
import { Link } from "react-router-dom";
import GenericDialog from "../components/Dialogs/GenericDialog";
import { setPageTitle } from "../helpers/DocumentHelpers";
import { apiPost, apiDelete } from "../helpers/ApiHelpers";
import { notifyApiFailure } from "../helpers/ErrorHelpers";
import ShowIf from "../components/Generic/ShowIf";
import RequirePermission from "../components/RequirePermission";
import { is } from "../helpers/PermissionHelpers";

import "../css/home.css";
import "../css/sidebar.css";
import "../css/dimensions.css";
import "../css/misc.css";
import "../css/margin.css";
import "../css/library.css";
import { End2EndSetup } from "../types/cxmtypes";
import useConfirmDialog from "../hooks/useConfirmDialog";
import { notifySuccess } from "../helpers/NotificationManager";

let mapStateToProps = (state: RootState) => {
    return {
        cxmSetups: state.End2End.cxmSetups,
        setups: state.End2End.setups,
    };
};

const connector = connect(mapStateToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

interface End2EndProps extends PropsFromRedux {
    history: any;
}

let End2End = ({ dispatch, cxmSetups, setups, ...props }: End2EndProps) => {
    const [showCreateSetup, setShowCreateSetup] = useState(false);
    const [tmpName, setTmpName] = useState("");
    const [chosenCxm, setChosenCxm] = useState<{ label: string; value: string }>();
    const [search, setSearch] = useState("");

    const { confirm, ConfirmDialog } = useConfirmDialog();
    const [toCopy, setToCopy] = useState<End2EndSetup>()

    useEffect(() => {
        if (cxmSetups == undefined) {
            dispatch(getCxmSetups());
        }
        if (setups == undefined) {
            dispatch(getE2ESetups());
        }
        setPageTitle("End 2 End");
    }, []);

    if (cxmSetups === undefined || setups == undefined)
        return (
            <div style={{ width: "100%", display: "flex", flexDirection: "column", alignItems: "center" }}>
                <LoadingAnimation />
                <h1>Loading...</h1>
            </div>
        );

    const create = () => {
        if (tmpName == "" || chosenCxm == undefined) return;
        const data = { name: tmpName, cxm_id: chosenCxm.value };
        apiPost(
            "end2end",
            data,
            (id: number) => {
                dispatch(getE2ESetups());
                props.history.push("/end2end/" + id);
            },
            (e) => {
                notifyApiFailure("error creating new end2end setup", "POST", "end2end", data, e.status);
            },
        );
    };

    const deleteSetup = async (s: End2EndSetup) => {
        const ans = await confirm(`Are you sure you want to delete ${s.name}? This is permanent and can not be undone`);
        if (!ans) return;
        const url = `end2end/${s.id}`;
        apiDelete(
            url,
            () => {
                dispatch(getE2ESetups());
                notifySuccess("Deleted setup");
            },
            (e) => {
                notifyApiFailure("error deleting end2end setup", "DELETE", url, {}, e.status);
            },
        );
    };

    const onCopy = (s: End2EndSetup) => {
        setToCopy(s)
        setTmpName(`${s.name} - Copy`)
    }

    const copy = () => {
        if (toCopy == undefined || tmpName == "") return
        const url = "end2end/settings/new"
        const data = {
            name: tmpName,
            cxm_id: toCopy.cxm_id,
            settings: toCopy.settings
        }
        
        apiPost(
            url,
            data,
            (id: number) => {
                dispatch(getE2ESetups());
                props.history.push("/end2end/" + id);
            },
            (e) => {
                notifyApiFailure("error copying end2end setup", "POST", url, data, e.status);
            },
        );
        setToCopy(undefined)
    }

    const filteredSetups = setups?.filter((s) => s.name.toLowerCase().includes(search.toLowerCase()));

    return (
        <>
            <div className="home-settings">
                <div className="library-title">
                    <h1 className="pt-3 margin-bottom-5px">End2End</h1>
                    <RequirePermission perms={is.e2eadmin}>
                        <button
                            className="btn btn-primary btn-sm"
                            onClick={() => {
                                setShowCreateSetup(true);
                                setTmpName("");
                            }}
                        >
                            <i className="fa fa-plus"></i> New Setup
                        </button>
                    </RequirePermission>
                </div>
                <div className="library-toolbar">
                    <div></div>
                    <div className="library-search">
                        <div style={{ whiteSpace: "nowrap", display: "flex", alignItems: "center" }}>
                            <span
                                className="vertical-align-middle"
                                style={{ marginRight: 8 }}
                            >{`Showing ${filteredSetups.length} of ${setups.length}`}</span>
                        </div>
                        <div className="input-group margin-top-0" id="searchBarContainer">
                            <input
                                id="searchIdInput"
                                className={`form-control form-control-sm zero-border-right ${search ? "alert-warning" : ""}`}
                                placeholder={`Search`}
                                onChange={(e) => setSearch(e.target.value)}
                                value={search}
                            />
                            <span
                                title="Search beginning"
                                className={`input-group-append ${search ? "alert-warning" : ""}`}
                            >
                                <button className="btn btn-sm btn-default" type="button">
                                    <i className="fa fa-search"></i>
                                </button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>
            <div className="d-flex justify-content-center p-5">
                <div
                    className="dashboard-overview-container"
                    style={{ maxWidth: 800, width: "100%", textDecoration: "none" }}
                >
                    {filteredSetups &&
                        filteredSetups.map((s, i) => {
                            return (
                                <Link key={i} to={`end2end/${s.id}`}>
                                    <div
                                        className={`dashboard-overview-item ${i < setups.length - 1 ? `dashboard-overview-item-divider` : ``}`}
                                        style={{ textDecoration: "none" }}
                                        key={i}
                                    >
                                        <span>{s.name}</span>
                                        <div>
                                            <RequirePermission perms={is.e2eadmin}>
                                                <button
                                                    className="btn btn-sm btn-default dropdown-toggle dropdown-toggle-split ml-2"
                                                    data-toggle="dropdown"
                                                />
                                                <div className="dropdown-menu" onClick={e => e.preventDefault()}>
                                                    <button className="dropdown-item" onClick={() => onCopy(s)}>
                                                        Copy
                                                    </button>
                                                    <div className="dropdown-divider" />
                                                    <button
                                                        className="dropdown-item"
                                                        style={{ color: "var(--danger)" }}
                                                        onClick={() => {deleteSetup(s)}}
                                                    >
                                                        Delete
                                                    </button>
                                                </div>
                                            </RequirePermission>
                                        </div>
                                    </div>
                                </Link>
                            );
                        })}
                    <ShowIf if={setups.length === 0}>
                        <div style={{padding: 5, textAlign: "center"}}>
                                There are no End2End views available to you.
                        </div>
                    </ShowIf>
                </div>
            </div>

            <GenericDialog
                show={showCreateSetup}
                title="New Setup"
                onClose={() => setShowCreateSetup(false)}
                getButtons={() => {
                    return (
                        <div>
                            <button
                                style={{ marginRight: 7 }}
                                onClick={create}
                                disabled={tmpName == "" || chosenCxm == undefined}
                                className="btn btn-success"
                            >
                                Create
                            </button>
                            <button onClick={() => setShowCreateSetup(false)} className="btn btn-default">
                                Cancel
                            </button>
                        </div>
                    );
                }}
            >
                <label htmlFor="e2eName" className="select-label">
                    Name
                </label>
                <input
                    id="e2eName"
                    type="text"
                    placeholder="Name of setup"
                    className="form-control"
                    value={tmpName}
                    onChange={(e) => setTmpName(e.target.value)}
                />
                <label className="select-label mt-3">Basis Setup</label>
                <Select
                    options={cxmSetups.map((c) => ({ label: c.name, value: c.id }))}
                    value={chosenCxm}
                    onChange={(v) => setChosenCxm(v ?? undefined)}
                    isClearable
                    isSearchable
                />
            </GenericDialog>

            <ConfirmDialog title="Delete setup" confirmText="Delete" declineText="Cancel" confirmColor="btn-danger" />
                        <GenericDialog
                show={toCopy != undefined}
                title="Save as"
                onClose={() => setToCopy(undefined)}
                getButtons={() => {
                    return (
                        <div>
                            <button
                                style={{ marginRight: 7 }}
                                onClick={copy}
                                disabled={tmpName == ""}
                                className="btn btn-success"
                            >
                                Create
                            </button>
                            <button onClick={() => setToCopy(undefined)} className="btn btn-default">
                                Cancel
                            </button>
                        </div>
                    );
                }}
            >
                <label htmlFor="e2eName" className="select-label">
                    Name
                </label>
                <input
                    id="e2eName"
                    type="text"
                    placeholder="Name of setup"
                    className="form-control"
                    value={tmpName}
                    onChange={(e) => setTmpName(e.target.value)}
                />
            </GenericDialog>

        </>
    );
};

export default connector(End2End);
