import * as ActionTypes from './ActionTypes'
import { packAction } from './ActionTypes'

export const gotChecklistsStatus = checklists => {
    return packAction(ActionTypes.GET_CHECKLISTS_STATUS_DONE, checklists)
}

/**
 * 
 * @param {{maxcount?: number, search?: string, author_id?: number, assignee_id?: number, bucket?: string} | undefined} [options] query param options for getting Action status
 * @returns 
 */
export const getChecklistsStatus = (options) => {
    return packAction(ActionTypes.GET_CHECKLISTS_STATUS, options)
}

export const updatedChecklists = () => {
    return getChecklistsStatus()
}

export const clearChecklistsProps = (checklists) => {
    return packAction(ActionTypes.CLEAR_CHECKLISTS_PROPS, checklists)
}

export const tooManyActions = (count) => {
    return packAction(ActionTypes.TOO_MANY_ACTIONS, count)
}

export const setActionStatusLoading = (bool) => {
    return packAction(ActionTypes.ACTION_STATUS_LOADING, bool)
}