import * as ActionTypes from '../actions/ActionTypes'

const Status = (state = {}, action) => {
    switch (action.type) {
        case ActionTypes.ACTION_STATUS_LOADING:
            return Object.assign({}, state, {loading: action.payload})
        case ActionTypes.GET_CHECKLISTS_STATUS_DONE:
            return Object.assign({}, state, { checklists: action.payload})
        case ActionTypes.TOO_MANY_ACTIONS:
            return Object.assign({}, state, {checklists: [], too_many_actions: true, action_count: action.payload})
        default:
            return state
    }
}

export default Status