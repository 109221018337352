/* REACT */
import React from 'react'
import moment from 'moment'
import { connect } from 'react-redux'
import { getHomeInfo } from '../actions/InfoActions'
import { getUsers } from '../actions/SystemActions'
import { setPageTitle } from '../helpers/DocumentHelpers'
import { is, isReportCreate } from '../helpers/PermissionHelpers'
import DashboardWidgets from '../components/Dashboard/DashboardWidgets'
import RequirePermission from '../components/RequirePermission'
import { withSettingsStorage } from '../helpers/SettingsService'
import { formatDate } from '../helpers/TimeHelpers'
import { sortByNameProp } from '../helpers/ReportHelpers'
import ShowIf from '../components/Generic/ShowIf'
import { updateChecklistItemsMultiple } from '../actions/ChecklistActions'
import ChecklistItemCountLabel from '../components/ChecklistItemCountLabel'
import { Line } from 'react-chartjs-2'
import { homeAddWidgetOptions } from '../helpers/DashboardHelpers'
import HomeDashboardDialog from '../components/Dialogs/HomeDashboardDialog'
import { getHomeData } from '../helpers/GraphHelpers'
import HomeReportDialog from '../components/Dialogs/HomeReportDialog'
import { Link } from 'react-router-dom'
import { getChecklistStatus } from '../helpers/ChecklistHelpers'
import CreateReportDialog from '../components/Report/CreateReportDialog'
import { getReportTemplates } from '../actions/TemplateActions'
import "../css/dashboard.css"
import ReactTooltip from 'react-tooltip'

const mapStateToProps = (state, ownProps) => {
    return {
        info: state.Info.info,
        users: state.System.users,
        userInfo: state.User.info,
        checklistItemsBeingUpdated: state.Info.checklistItemsBeingUpdated,
        loadingHomeInfoData: state.Loading.loadingHomeInfoData,
        templates: state.Report.reportTemplateGroups,
        dashboard: state.Dashboard.homeDashboard,
        dashboardOverview: state.Dashboard.overview
    }
}

class Home extends React.Component {
    state = {
        showFavs: true,
        showChecklists: true,
        showAllChecklists: false,
        showDashboardDialog: false,
        showCreateReport: false,
    }

    homeDialogRef = React.createRef()
    homeReportDialogRef = React.createRef()

    componentDidMount() {
        setPageTitle("Home")

        let { dispatch } = this.props
        dispatch(getHomeInfo())
        if(!this.props.users)
            dispatch(getUsers())
        if (isReportCreate(this.props.userInfo.access) && !this.props.templates.fetched) {
            dispatch(getReportTemplates())
        }
    }

    getDate(deadline) {
        const now = moment()

        if (now.isSame(deadline, 'day')) return { val: 'today' }
        else if (now.diff(deadline, 'days') === 1) return { val: 'yesterday', color: "red" }
        else if (now.diff(deadline, 'days') === 0 && !now.isSame(deadline, 'day')) return { val: 'tomorrow' }
        else return { val: formatDate(deadline), color: now.isAfter(deadline) ? 'red' : '' }
    }

    updateActiveChecklists = () => {
        if (!this.props.checklistItemsBeingUpdated) {
            let checklistIds = this.props.info.checklists.filter(c => !c.list.completed && !c.list.archived).map(c => c.id)
            this.props.dispatch(updateChecklistItemsMultiple(checklistIds))
        }
    }

    getDashboardTitle = () => {
        if(this.props.dashboard && this.props.dashboardOverview){
            const dashboard = this.props.dashboardOverview.find(d => d.id === this.props.dashboard)
            if(dashboard) return dashboard.title
        }
    }

    getDashboardFirstRowWidgetClass = () => {
        if(!this.props.info || !this.props.info.widgets || this.props.info.widgets.length == 0) return;
        return this.props.info.widgets[0].type === "large" ? "dashboard-large" : "dashboard-small";

    }
    // Make empty divs to make title align with top left widget
    getDashboardTitleSpacerDivs = () => {
        if(!this.props.info || !this.props.info.widgets || this.props.info.widgets.length == 0) return;
        // amount of widgets in first row of dashboard
        const amount = this.props.info.widgets[0].widgets.filter(w => w.column !== "").length
        if(amount > 1){
            return Array.from(Array(amount - 1).keys())
                .map(n => (
                    <div 
                        key={n} 
                        className={this.getDashboardFirstRowWidgetClass() + " padding-left-right-4px"} 
                        style={{height: 0, margin: "0 4px"}}
                    ></div>
                ))

        } else{
            return null
        }
    }

    render() {
        let checklistMessage
        let checklists = this.props.info && this.props.info.checklists ? this.props.info.checklists : []
        checklists.sort((a, b) => b.list.upload_time - a.list.upload_time)
        let { hiddenChecklists, unfinishedChecklists } = checklists.reduce((acc, c) => {
            let status = getChecklistStatus(c)
            if (c.list.completed || status.undone === 0) acc.hiddenChecklists.push(c)
            else acc.unfinishedChecklists.push(c)
            return acc
        }, { hiddenChecklists: [], unfinishedChecklists: [] })
        hiddenChecklists.sort((a, b) => a.list.completed - b.list.completed || a.list.name.localeCompare(b.list.name)) //completed checklists go last, otherwise sort by name
        unfinishedChecklists.sort((a, b) => sortByNameProp(a.list, b.list))

        checklists = !this.state.showAllChecklists ? unfinishedChecklists : unfinishedChecklists.concat(hiddenChecklists)

        if (unfinishedChecklists.length === 0 && hiddenChecklists.length > 0 && !this.state.showAllChecklists) {
            checklistMessage = (
                <p className={"font-16 zero-margin"} ><i>All done! Nothing requires your attention.</i></p>
            )
        }

        let favouriteReports = this.props.info && this.props.info.favouriteReports ? this.props.info.favouriteReports : []
        if (favouriteReports) favouriteReports.sort((a, b) => a.report.name.localeCompare(b.report.name))
        const widgetData = this.props.info && this.props.info.widgets ? this.props.info.widgets : []
        const reports = this.props.info?.reports ?? []
        
        let baseUrl = window.location.href.substring(0, window.location.href.indexOf("#"))

        return (
            <div className="col-md-12 margin-bottom-20px zero-padding-imp">
                <div className="col-md-12 zero-padding-imp">
                    {widgetData.length > 0 ?
                        <div className="d-flex flex-row justify-content-between align-items-center pt-3">
                            <div style={{ opacity: 0 }}><button style={{ cursor: "default" }} className='btn btn-default btn-sm'><i className="fa fa-edit"></i></button></div>
                            <div className=''><h1 className="mb-0" style={{color:"#304642"}}>Inact Now Home</h1></div>
                            <div className='mr-2'>
                            <RequirePermission perms={is.dashboardUser}>
                                <button onClick={_ => this.homeDialogRef.current?.show()} className='btn btn-default btn-sm'><i className="fa fa-edit"></i></button>
                            </RequirePermission>
                            </div>
                        </div> : <div className="center">
                            <h1 className="mb-0 pt-3" style={{color:"#304642"}}>Inact Now Home</h1>
                        </div>
                    }
                    <hr className={"margin-bottom-30px margin-top-5px"} style={{borderColor:"white"}}/>

                    <RequirePermission perms={is.dashboardUser}>
                        <div className="col-lg-10 col-md-10 col-sm-12 col-12 offset-lg-1 offset-md-1" style={{color:"#304642"}}>
                            <div className='text-align-center'>
                                <div className='d-flex justify-content-center align-items-center flex-wrap'>
                                    <div className={'font-weight-bold text-align-left padding-left-right-4px ' + this.getDashboardFirstRowWidgetClass()} style={{height: "20px", margin: "0 4px"}}>
                                        {this.getDashboardTitle()}
                                    </div>
                                    {this.getDashboardTitleSpacerDivs()}
                                </div>
                                <DashboardWidgets fromHome={true} widgetData={widgetData} />
                            </div>
                        </div>
                    </RequirePermission>
                </div>
                <div className="col-md-12">
                    <RequirePermission perms={is.dashboardUser}>
                    {
                       this.props.info && this.props.info && widgetData.length === 0 && <div className="col-lg-6 col-md-10 col-sm-12 col-12 offset-lg-3 offset-md-1">
                            <h5 style={{textAlign: "center"}}>The dashboard you have chosen does not contain any widgets. Click below to choose another dashboard.</h5>
                            <div onClick={_ => { }} className='d-flex flex-column'>
                                <div onClick={_ => this.homeDialogRef.current?.show()} style={{ height: 150 }} className="w-100 add-dashboard-background d-flex flex-column abc-click">
                                    <div style={{ fontSize: 20 }} className='text-center pt-2'>Add your dashboard</div>
                                    <div className='h-100'>
                                        <Line
                                            className="margin-right-10px"
                                            height={52}
                                            redraw={true}
                                            data={getHomeData}
                                            options={homeAddWidgetOptions}
                                        />
                                    </div>
                                </div>
                                <div className='no-target mt-1' style={{ height: 14 }}></div>
                            </div>
                        </div>
                    }
                    </RequirePermission>
                    <div className="col-lg-6 col-md-10 col-sm-12 col-12 offset-lg-3 offset-md-1">
                        <ShowIf if={unfinishedChecklists.length > 0 || hiddenChecklists.length > 0}>
                            <RequirePermission perms={is.checklistsUser}>
                                {
                                    <div>
                                        <div className="margin-top-30px"></div>
                                        <p onClick={() => this.setState({ showChecklists: !this.state.showChecklists })} style={{color:"#304642"}}className="home-header">
                                            Actions&nbsp;
                                            {/* <ShowIf if={notCompletedChecklistCount > 0}>
                                                <button className={`btn btn-xs btn-primary float-right`} onClick={this.updateActiveChecklists} disabled={this.props.checklistItemsBeingUpdated}>Update your Action lists</button>
                                            </ShowIf> */}
                                            <ShowIf if={hiddenChecklists.length > 0}>
                                                <button className={`btn btn-xs ${this.state.showAllChecklists ? 'btn-default' : "btn-default"} float-right margin-right-10px`} onClick={e => { e.stopPropagation(); this.setState({ showAllChecklists: !this.state.showAllChecklists }) }}>{!this.state.showAllChecklists ? "Show inactive" : "Hide inactive"}</button>
                                            </ShowIf>
                                        </p>
                                        <div className="home-list">
                                            {
                                                checklists.map((c, i) => {
                                                    const deadline = this.getDate(c.list.deadline)
                                                    const creationDate = this.getDate(moment.unix(c.list.upload_time).format("YYYY-MM-DD"))
                                                    const hasMissingColumns = !!c.missing_columns && c.missing_columns.length > 0
                                                    return (
                                                        <div key={i} className={`padding-10px ${c.list.completed ? "checklist-completed" : ""}`}>

                                                            <a className='abc-click' style={{textDecoration:"none", color: "#304642"}} href={baseUrl + `#/checklist/${c.list.id}`}>
                                                                
                                                                <div style={{width:"100%", verticalAlign:"middle"}}>
                                                                {
                                                                    !c.list.completed ? <ChecklistItemCountLabel count={getChecklistStatus(c).undone} loading={this.props.checklistItemsBeingUpdated} /> : null
                                                                }
                                                                
                                                                <span className="vertical-align-middle "  >
                                                                    <div style={{display:"inline-block", verticalAlign:"middle"}}>
                                                                        {c.list.name}
                                                                        {
                                                                            c.list.completed ?
                                                                                <i className="margin-left-5px">Completed</i>
                                                                                : null
                                                                        }
                                                                    {/* <div style={{ marginTop:"1px", display:"block"}}>
                                                                        <ShowIf if={c.list.selected_kpi !=="" && !hiddenChecklists.includes(c)}>
                                                                            <i className=' fa fa-clock-o vertical-align-middle ' style={{width:"16px"}}></i>
                                                                            <span style={{marginLeft:"5px", fontFamily:"Poppins, Arial, sans-serif", fontSize:"10px"}} > 
                                                                            {c.list.selected_kpi} : <strong >{Math.round(c.list.kpi_total).toLocaleString("da")}</strong>  </span> 
                                                                        </ShowIf>
                                                                    </div> */}
                                                                    </div>
                                                                    {/* <div style={{marginTop: c.list.selected_kpi !=="" && !hiddenChecklists.includes(c) ? "13px" : "", display:"inline-block"}} className='float-right vertical-align-middle'>  */}
                                                                    <div style={{display:"inline-block"}} className='float-right vertical-align-middle'> 
                                                                    <ShowIf if={!hasMissingColumns}>
                                                                        { c.list.deadline !== "" ?
                                                                            <span className={`margin-left-50px ${deadline.color ? deadline.color : ''}`} title={'Deadline ' + deadline.val}>{creationDate.val}</span>
                                                                            :
                                                                            <span className="margin-left-50px">{creationDate.val}</span>
                                                                        }
                                                                    </ShowIf>
                                                                    <ShowIf if={hasMissingColumns}>
                                                                        <i 
                                                                            className='d-inline-block fa fa-warning text-danger ml-2' 
                                                                            aria-hidden="true" 
                                                                            data-tip data-for={`warning-tooltip-${c.list.id}`}
                                                                        ></i>
                                                                        <ReactTooltip className='checklist-overview-tooltip' id={`warning-tooltip-${c.list.id}`} effect='solid' place='bottom'>
                                                                            This Action contains columns that are no longer available.
                                                                        </ReactTooltip>
                                                                    </ShowIf>
                                                                    <ShowIf if={c.list.automatic_update && !hasMissingColumns}>
                                                                        <img className="refresh_data_image vertical-align-middle margin-left-5px" alt="Automatic update" title='Automatically updated' src="./img/data_refresh_black.svg" />
                                                                    </ShowIf>

                                                                    <ShowIf if={c.list.completed}>
                                                                        <div className= {`${c.list.completed ? ' home-kpi-box home-kpi-box-green ' : 'home-kpi-box'} text-align-center margin-left-10px`} style={{color:"#304642", display:"inline-block", verticalAlign:"middle"}}>
                                                                            {
                                                                                c.list.completed ?
                                                                                    <i className="  fa fa-check white  "></i>
                                                                                    : null
                                                                            }
                                                                        </div>
                                                                    </ShowIf>
                                                                    </div>
                                                                    </span>
                                                                </div>
                                                            </a>
                                                        </div>
                                                    )
                                                })
                                            }
                                        </div>
                                        <div>
                                            {checklistMessage}
                                        </div>
                                    </div>
                                }
                            </RequirePermission>
                        </ShowIf>
                        <RequirePermission perms={is.reportUser}>
                            <div className="margin-top-20px"></div>
                            <div className='d-flex flex-row justify-content-between align-items-center pb-3'>
                                <div>
                                    <div onClick={() => this.setState({ showFavs: !this.state.showFavs })} className="home-header" style={{color:"#304642"}}>Insights</div>
                                </div>
                                <div>
                                    <RequirePermission perms={is.reportCreator}>
                                        {
                                            this.props.templates.enabled ?
                                                <button onClick={() => this.setState({showCreateReport: true})} style={{marginRight: 5}} className="btn btn-primary btn-xs"><i style={{fontSize: 10}} className="fa fa-plus"></i> New Insight</button>
                                                :
                                                <Link to="/newreport">
                                                    <button style={{marginRight: 5}} className="btn btn-primary btn-xs"><i style={{fontSize: 10}} className="fa fa-plus"></i> New Insight</button>
                                                </Link>
                                        }
                                    </RequirePermission>
                                    <button onClick={_ => this.homeReportDialogRef.current?.show()} className='btn btn-xs btn-default'>{favouriteReports.length === 0 ? "Add" : "Edit"}</button>
                                </div>
                            </div>
                            <div className="home-list">
                                {
                                    favouriteReports.map((r, i) => {
                                        return (
                                            <div className="padding-10px" key={i}>
                                                <a className='abc-click' style={{textDecoration:"none", color: "#304642"}} href={baseUrl + `#/report/${r.report.report_id}`}><div style={{width:"100%"}}>
                                                <img src="./img/report-icon-15px.png" alt="" className="vertical-align-middle" />
                                                <span className="vertical-align-middle margin-left-5px">
                                                    {r.report.name}
                                                </span>
                                                </div></a>
                                            </div>
                                        )
                                    })
                                }
                            </div>
                            <div>
                                {favouriteReports.length === 0 && <div>Add your favourite Insights</div>}
                            </div>
                        </RequirePermission>
                    </div>
                </div>
                <RequirePermission perms={is.dashboardUser}>
                    <HomeDashboardDialog ref={this.homeDialogRef} onSave={_ => this.props.dispatch(getHomeInfo())} />
                </RequirePermission>
                <RequirePermission perms={is.reportUser}>
                    <HomeReportDialog ref={this.homeReportDialogRef} onSave={_ => this.props.dispatch(getHomeInfo())} favourites={favouriteReports} reports={reports} />
                </RequirePermission>
                <RequirePermission perms={is.reportCreator}>
                    {this.state.showCreateReport && <CreateReportDialog 
                        show={this.state.showCreateReport}
                        onClose={() => this.setState({showCreateReport: false})}
                        history={this.props.history}
                        users={this.props.users}
                        user={this.props.userInfo} 
                    />}
                </RequirePermission>

            </div>
        )
    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return (
        JSON.stringify(curState.showFavs) !== JSON.stringify(prevState.showFavs) ||
        JSON.stringify(curState.showChecklists) !== JSON.stringify(prevState.showChecklists)
    )
}

const getSettingsKeys = (state) => {
    return ["showFavs", "showChecklists", "showAllChecklists"]
}

const getSessionStoreKey = _this => {
    return "home"
}

Home = withSettingsStorage(Home, getSettingsKeys, shouldComponentUpdateSettings, null, null, getSessionStoreKey, null, null, "home", { withSessionStore: true })

Home = connect(mapStateToProps)(Home)

export default Home
