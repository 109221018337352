import React, { useEffect, useState } from 'react'
import GenericDialog from '../Dialogs/GenericDialog'
import { ReportTemplate, ReportTemplateGroupWrapper, ReportTemplateBucket } from '../../types/types'
// @ts-ignore
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { getReportTemplates, createReportFromTemplate } from '../../actions/TemplateActions'
import ShowIf from '../Generic/ShowIf'

import '../../css/CreateReportDialog.css'
import InfoTooltip from '../Generic/InfoTooltip'
import { notifyFailure } from '../../helpers/NotificationManager'
// @ts-ignore
import Select from 'react-select'
import { SelectOption } from '../../helpers/TypeHelpers'
import { LibraryGroup, LibraryReport, LoginSession } from '../../types/transfertypes'
import RequirePermission from '../RequirePermission'
import { is } from '../../helpers/PermissionHelpers'
import SmallTabs, { SmallTabOption } from '../SmallTabs'
import { getLibraryInfo } from '../../actions/InfoActions'
import { OrgUser } from '../../types/organizationtypes'
import FixReportDialog from '../Dialogs/FixReportDialog'
import { deleteReport, fixReport } from '../../actions/ReportActions'

interface CreateReportDialogProps {
    show: boolean,
    onClose: () => void,
    templates?: ReportTemplateGroupWrapper,
    users: OrgUser[],
    user: LoginSession,
    dispatch?: (msg: any) => void,
    history: any,
    info?: LibraryGroup[],
}

let mapStateToProps = (state: any, ownProps: CreateReportDialogProps) => {
    return {
        templates: state.Report.reportTemplateGroups,
        users: state.System.users,
        user: state.User.info,
        info: state.Info.libraryInfo,
    }
}

let CreateReportDialog = ({show, users = [], templates = {fetched: false, buckets: [], groups: [], enabled_groups: [], enabled: false}, dispatch = (() => {}), info = [], ...props }: CreateReportDialogProps) => {

    const [stage, setStage] = useState<"initial" | "template" | "creating" | "missing_columns">("initial")
    const [template, setTemplate] = useState<ReportTemplate>()
    const [possibleBuckets, setPossibleBuckets] = useState<ReportTemplateBucket[]>([])
    const [tmpName, setTmpName] = useState("")
    const [tmpBucketId, setTmpBucketId] = useState<SelectOption<string>>({label: "", value: ""})
    const [tmpPrivate, setTmpPrivate] = useState(false)
    const [tmpUserId, setTmpUserId] = useState<SelectOption<number>>({label: "", value: 0})
    // extra = should create group
    const [tmpGroup, setTmpGroup] = useState<{label: string, value: number, createNew: boolean}>({label: "Unsorted", value: 0, createNew: false})
    const [enableGroups, setEnableGroups] = useState(false)
    const [fixReportData, setFixReportData] = useState<LibraryReport | null>(null)

    const [tab, setTab] = useState(0)

    useEffect(() => {
        if(!templates.fetched) {
            dispatch(getReportTemplates())
        }
        dispatch(getLibraryInfo())
    }, [])

    useEffect(() => {
        if (template == undefined) {
            setTmpBucketId({label: "", value: ""})
            setPossibleBuckets([])
            return
        }
        let buckets = templates.buckets.filter(b => b.bucket_template_id == template.bucket_id)
        setPossibleBuckets(buckets)
        if (buckets.length < 1) {
            setTmpBucketId({label: "", value: ""})
            notifyFailure(`An error has occured getting datasources for the ${template.name} template that should not have happened. Please notify support of this error.`, template)
            return
        }
        setTmpBucketId({value: buckets[0].id, label: buckets[0].name})
    }, [template])

    useEffect(() => {
        setStage("initial")
    }, [show])

    if (!templates.enabled) return <></>

    const pickTemplate = (t: ReportTemplate) => {
        setTemplate(t)
        setTmpName(t.name)
        setTmpPrivate(false)
        setTmpUserId({label: `${props.user.firstname} ${props.user.lastname}`, value: props.user.user_id})
        if (t.default_group != "") {
            const group = availableGroupOptions.find(g => g.label == t.default_group)
            if (group != undefined) {
                setTmpGroup(group)
            } else {
                setTmpGroup({label: t.default_group, value: -5, createNew: true})
            }
        } else {
            setTmpGroup({label: "Unsorted", value: 0, createNew: false})
        }
        setStage("template")
    }

    const disableSave = () => {
        return stage != "template" || tmpName == "" || template == undefined || tmpBucketId.value == ""
    }

    const createReport = () => {
        if (disableSave() || template == undefined) return
        setStage("creating")
        dispatch(createReportFromTemplate({
            template_id: template.id,
            name: tmpName,
            private: tmpPrivate,
            owner: tmpUserId.value,
            bucket_id: tmpBucketId.value,
            group_id: tmpPrivate ? 0 : tmpGroup.value,
            createNewGroup: tmpGroup.createNew
        }, 
            // On success
            (res) => {
                if(res.missing_columns.length === 0) {
                    props.history.push("/report/" + res.report.report_id)
                } else {
                    setStage("missing_columns")
                    setFixReportData({
                        missing: res.missing_columns, 
                        report: res.report, 
                        info: res.info, 
                        // default vals, are not used, but required in interface
                        count: -1, 
                        inDashboard: false, 
                        isFav: false
                    })
                }
            }, 
            // On error
            () => {
                setStage("template")
            }))
    }

    const getButtons = () => {
        return <div>
            <ShowIf if={stage == "template"}>
                <button style={{marginRight: 7}} onClick={createReport} disabled={disableSave()} className='btn btn-success'>Create</button>
            </ShowIf>
            <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
        </div>
    }

    const getPopupTitle = () => {
        if (stage == "template" && template != undefined) {
            return `Template - ${template.name}`
        }

        return "Choose template"
    }
    
    const onEnableGroupsChange = (value: boolean) => {
        if (template == undefined) return
        setEnableGroups(value)
        if (!value) {

            if (template.default_group != "") {
                const group = availableGroupOptions.find(g => g.label == template.default_group)
                if (group != undefined) {
                    setTmpGroup(group)
                } else {
                    setTmpGroup({label: template.default_group, value: -5, createNew: true})
                }
            } else {
                setTmpGroup({label: "Unsorted", value: 0, createNew: false})
            }
        }
    }

    const filteredGroups = templates.groups.filter(tg => tg.report_templates.length > 0)
    const tabs: SmallTabOption<number>[] = filteredGroups.map((tg, i) => {
        const isEnabled = templates.enabled_groups.some(g => g == tg.id)
        return {
            label: <span>
            <ShowIf if={!isEnabled}><i style={{marginRight: 5}} className="fa fa-lock" aria-hidden="true"></i></ShowIf>
            {tg.name}
            </span>, value: i}
    })
    const g = filteredGroups.at(tab)
    const isEnabled = g == undefined ? false : templates.enabled_groups.some(g2 => g2 == g.id)
    

    const availableGroups = info.filter(x => x.group.group_id >= 0).sort((a, b) => a.group.name.localeCompare(b.group.name))
    const availableGroupOptions = availableGroups.map(u => ({label: u.group.name, value: u.group.group_id, createNew: false}))
    if (template != undefined && template.default_group != "") {
        if (!availableGroupOptions.some(g => g.label == template.default_group)) {
            availableGroupOptions.push({label: template.default_group, value: -5, createNew: true})
        }
    }
    return <> 
        <GenericDialog show={show} title={getPopupTitle()} onClose={props.onClose} getButtons={getButtons} style={{minWidth: 400}}>
            <ShowIf if={stage == "initial"}>

                <div className="d-flex justify-content-center align-items-center" style={{marginBottom: 16}}>
                    <Link to={"/newreport"}>
                        <button className="btn btn-default btn-sm">
                            Create without template
                        </button>
                    </Link>
                </div>

                <SmallTabs
                    options={tabs}
                    value={tab}
                    onChange={setTab}
                />

                {
                    
                g != undefined ? <div className={`templates-content ${!isEnabled ? 'disabled' : ''}`}>
                        <ShowIf if={g != undefined && !isEnabled}>
                            <div className="alert alert-warning">
                                You do not have access to these templates, contact <a href="mailto:support@inact.io"><b>support@inact.io</b></a> to gain access.
                            </div>
                        </ShowIf>
                    <h3 className={`report-template-group-title ${!isEnabled ? 'disabled' : ''}`}>{g.name}</h3>
                    <div className={`templates-grid ${!isEnabled ? 'disabled' : ''}`}>
                        {
                            g.report_templates.map((r) => {
                                return <div key={r.id} className={`report-template-card ${!isEnabled ? 'disabled' : ''}`} onClick={() => { if (isEnabled) pickTemplate(r)}}>
                                    <img className={!isEnabled ? 'disabled' : ''} src="./img/report-template-icon.svg" alt="" />
                                    <div className="d-flex flex-column">
                                        <span className={`report-template-name ${!isEnabled ? 'disabled': ''}`}>
                                                {r.name}
                                                <ShowIf if={r.description != ""}>
                                                    <InfoTooltip text={r.description} style={{marginLeft: 5}} />
                                                </ShowIf>

                                            </span>
                                            <div style={{opacity: 0.5, fontSize: 12}}>
                                                <span style={{fontWeight: "700"}}>Datasource</span> {templates.buckets.find(b => b.bucket_template_id == r.bucket_id)?.name ?? "None"}
                                            </div>

                                    </div>
                                </div>
                            })
                        }
                    </div>
                </div>
                : <h3>Loading...</h3>
                }
            </ShowIf>
            <ShowIf if={stage == "template"}>
                <div>
                    <span className="abc-click report-template-back" onClick={() => setStage("initial")}><i className="fa fa-arrow-left mr-2" aria-hidden="true"></i>Back</span>
                </div>
                <div>
                    <label htmlFor='kpi-label' className='select-label mt-3'>Name</label>
                    <input id='kpi-label' type="text" placeholder="Input name of report" className="form-control" value={tmpName} onChange={(e) => setTmpName(e.target.value)} />
                    <ShowIf if={possibleBuckets.length > 1}>
                        <label className='select-label mt-3' >Select datasource</label>
                        <Select
                            isSearchable
                            options={possibleBuckets.map(b => ({label: b.name, value: b.id}))}
                            value={tmpBucketId}
                            onChange={(v: any) => setTmpBucketId(v)}
                            placeholder={"Select data source"}
                        />
                    </ShowIf>
                    <RequirePermission perms={is.reportAdmin}>
                        <div className="mt-3">
                            <input className='mr-1' type='checkbox' id='private-check' checked={tmpPrivate} onChange={() => {setTmpPrivate(v => !v); setTmpUserId({label: `${props.user.firstname} ${props.user.lastname}`, value: props.user.user_id})}} />
                            <label htmlFor="private-check" className=''>Private report</label>
                        </div>
                        <label className='select-label ' >Owner</label>
                        <Select
                            isSearchable
                            options={users.map(u => ({label: `${u.firstname} ${u.lastname}`, value: u.id})).sort((a, b) => a.label.localeCompare(b.label))}
                            value={tmpUserId}
                            //@ts-ignore
                            onChange={(v: SelectOption<number>) => setTmpUserId(v)}
                            placeholder={"Owner"}
                        />
                        <label className='select-label mt-3' >Group</label>
                        <ShowIf if={template != undefined && template.default_group != ""}>
                        <div className="">
                            <input disabled={tmpPrivate} className='mr-1' type='checkbox' id='custom-check' checked={enableGroups} onChange={(e) => onEnableGroupsChange(e.target.checked)} />
                            <label htmlFor="custom-check" className={`${tmpPrivate ? 'half-opacity' : ''}`}>Enable other groups</label>
                        </div>
                        </ShowIf>
                        <Select
                            isSearchable
                            options={availableGroupOptions}
                            value={tmpGroup}
                            //@ts-ignore
                            onChange={(v: {label: string, value: number, createNew: boolean}) => setTmpGroup(v)}
                            isDisabled={tmpPrivate || (!enableGroups && template != undefined && template.default_group != "")}
                            placeholder={"Group"}
                        />
                    </RequirePermission>

                </div>
            </ShowIf>
            <ShowIf if={stage == "creating"}>
                <h3>Creating report...</h3>
            </ShowIf>
            <ShowIf if={stage === "missing_columns"}>
                {!!fixReportData && <FixReportDialog
                    data={fixReportData}
                    dispatch={dispatch}
                    onClose={() => {
                        dispatch(deleteReport(fixReportData.report.report_id, true))
                        props.onClose();
                    }}
                    onSave={(report) => {
                        dispatch(fixReport(report.report_id, report, "single"))
                        props.history.push("/report/" + fixReportData.report.report_id)
                    }}
                    show={stage === "missing_columns"}
                    allColumns={fixReportData.info.model.columns}
                    duringCreation={true}
                />}
            </ShowIf>
        </GenericDialog>
    </>
}

// @ts-ignore
CreateReportDialog = connect(mapStateToProps)(CreateReportDialog)

export default CreateReportDialog
