import GenericDialog from "../components/Dialogs/GenericDialog"
import React, { ReactNode, useState, useRef, useCallback } from "react"

interface DialogProps {
    title?: string,
    confirmText?: string,
    confirmColor?: "btn-primary" | "btn-danger" | "btn-default"
    declineText?: string,
}

const useConfirmDialog = () => {

    const [show, setShow] = useState(false)
    const [text, setText] = useState<ReactNode>()
    const resolve = useRef<(value?: boolean | PromiseLike<boolean> | undefined) => void>()

    const onAccept = () => {
        setShow(false)
        if (resolve.current != undefined){
            resolve.current(true)
            resolve.current = undefined
        } 
    }

    const onDecline = () => {
        setShow(false)
        if (resolve.current != undefined){
            resolve.current(false)
            resolve.current = undefined
        } 
    }

    const confirm = useCallback((message: ReactNode): Promise<boolean> => {
        return new Promise((newResolve) => {
            resolve.current = newResolve
            setText(message)
            setShow(true)
        })
        }, [])
    
    const ConfirmDialog = ({title = "Confirm", confirmText="Confirm", declineText="Decline", confirmColor = "btn-primary",...props}: DialogProps) => {

        const getButtons = () => {
            
            return <div>
                <button style={{ marginRight: 7 }} onClick={onAccept} className={`btn ${confirmColor}`}>{confirmText}</button>
                <button onClick={onDecline} className='btn btn-default'>{declineText}</button>
            </div>
        }

        return <GenericDialog 
            show={show}
            title={title}
            onClose={onDecline}
            getButtons={getButtons}
        >
            {text}
        </GenericDialog>
    }

    return {confirm, ConfirmDialog}
}

export default useConfirmDialog
