import { combineReducers } from 'redux'

import System from './SystemReducer'
import User from './UserReducer'
import Status from './StatusReducer'
import Info from './InfoReducer'
import Bucket from './BucketReducer'
import Item from './ItemReducer'
import Report from './ReportReducer'
import Checklist from './ChecklistReducer'
import Loading from './LoadingReducer'
import Dashboard from './DashboardReducer'
import Notification from './NotificationReducer'
import Download from './DownloadReducer'
import Organization from './OrganizationReducer'
import End2End from './End2EndReducer'

import * as ActionTypes from '../actions/ActionTypes'
import Reporting from './ReportingReducer'

const AppStore = combineReducers({
    System,
    User,
    Status,
    Info,
    Bucket,
    Item,
    Report,
    Checklist,
    Loading,
    Dashboard,
    Notification,
    Download,
    Organization,
    End2End,
    Reporting
})

// eslint-disable-next-line import/no-anonymous-default-export
export default (state, action) => AppStore(action.type === ActionTypes.LOGOUT_DONE ? undefined : state, action);
