import type { Permission } from '../types/types'

const hasOne = (permissions: Permission[], ...wanted: Permission[]) => {
    if (!permissions || !wanted) {
        return false
    }

    let found = false

    wanted.forEach(perm => {
        permissions.forEach(given => {
            if (given === perm) {
                found = true
            }
        })
    })

    return found
}

export const is = {
    dataAdmin: ['dataadmin'],
    reportAdmin: ['reportadmin'],
    checklistsAdmin: ['checklistsadmin'],
    reportUser: ['report', 'reportcreate', 'reportadmin'],
    reportCreator: ['reportadmin', 'reportcreate'],
    reportCreateOnly: ['reportcreate'],
    checklistsUser: ['checklists', 'checklistsadmin', 'checklistscreate'],
    checklistsCreator: ['checklistsadmin', 'checklistscreate'],
    itemAccessor: ['itemaccess'],
    reportOnly: ['report'],
    dashboardUser: ['dashboard'],
    dashboardAdmin: ['dashboardadmin'],
    downloadUser: ['download'],
    e2eadmin: ['e2eadmin'],
    e2e: ['e2e'],
    orgAdmin: ['orgadmin']
}

export const canUseReports = (permissions: Permission[]) => {
    return hasOne(permissions, 'report', 'reportcreate', 'reportadmin')
}

export const canCreateReports = (permissions: Permission[]) => {
    return hasOne(permissions, 'reportadmin', 'reportcreate')
}

export const isChecklistAdmin = (permissions: Permission[]) => {
    return hasOne(permissions, 'checklistsadmin')
}

export const canUseChecklists = (permissions: Permission[]) => {
    return hasOne(permissions, 'checklists', 'checklistsadmin', 'checklistscreate')
}

export const canCreateChecklists = (permissions: Permission[]) => {
    return hasOne(permissions, 'checklistsadmin', 'checklistscreate')
}

export const canUseDownload = (permissions: Permission[]) => {
    return hasOne(permissions, 'download')
}

export const isDataAdmin = (permissions: Permission[]) => {
    return hasOne(permissions, 'dataadmin')
}

export const isReportCreateOnly = (permissions: Permission[]) => {
    return hasOne(permissions, 'reportcreate') && !hasOne(permissions, 'reportadmin')
}

export const isReportCreate = (permissions: Permission[]) => {
    return hasOne(permissions, 'reportcreate')
}

export const isReportAdmin = (permissions: Permission[]) => {
    return hasOne(permissions, 'reportadmin')
}

export const isReportUserOnly = (permissions: Permission[]) => {
    return hasOne(permissions, 'report') && !hasOne(permissions, 'reportadmin')
}

export const hasItemAccess = (permissions: Permission[]) => {
    return hasOne(permissions, 'itemaccess')
}

export const canUseDashboards = (permissions: Permission[]) => {
    return hasOne(permissions, "dashboard")
}

export const isDashboardAdmin = (permissions: Permissions[]) => {
    return hasOne(permissions, 'dashboardadmin')
}

export const simplifyMenu = (permissions: Permission[]) => {
    return hasOne(permissions, 'simple_menu')
}

export const isOrgAdmin = (permissions: Permission[]) => {
    return hasOne(permissions, "orgadmin")

}

// Roles and permissions for admin panel

export enum PermissionCheckbox {
    UNCHECKED = "UNCHECKED",
    CHECKED = "CHECKED",
    DISABLED = "DISABLED",
    PRECHECKED = "PRECHECKED"
}

export enum Permissions {
    REPORT_ADMIN = "reportadmin",
    REPORT_CREATE = "reportcreate",
    REPORT = "report",
    CHECKLISTS_ADMIN = "checklistsadmin",
    CHECKLISTS_CREATE = "checklistscreate",
    CHECKLISTS = "checklists",
    DASHBOARD = "dashboard",
    DASHBOARDADMIN = "dashboardadmin",
    E2EADMIN = "e2eadmin",
    E2E = "e2e",
    DOWNLOAD = "download",
}
export interface IPermissions {
    [Permissions.REPORT_ADMIN]: PermissionCheckbox,
    [Permissions.REPORT_CREATE]: PermissionCheckbox,
    [Permissions.REPORT]: PermissionCheckbox,
    [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox,
    [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox,
    [Permissions.CHECKLISTS]: PermissionCheckbox,
    [Permissions.DASHBOARD]: PermissionCheckbox,
    [Permissions.DASHBOARDADMIN]: PermissionCheckbox,
    [Permissions.DOWNLOAD]: PermissionCheckbox,
    [Permissions.E2EADMIN]: PermissionCheckbox,
    [Permissions.E2E]: PermissionCheckbox
}

export enum PermissionActions {
    MODIFY = "modify",
    VIEW = "view",
    CREATE = "create"
}

const permissionChecked = new Map()
const permissionUnchecked = new Map()

// report mappings
permissionChecked.set(
    Permissions.REPORT_ADMIN,
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.CHECKED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.PRECHECKED,
        [Permissions.REPORT]: PermissionCheckbox.PRECHECKED
    })

permissionChecked.set(
    Permissions.REPORT_CREATE,
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.CHECKED,
        [Permissions.REPORT]: PermissionCheckbox.PRECHECKED
    })

permissionChecked.set(
    Permissions.REPORT,
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT]: PermissionCheckbox.CHECKED
    })

permissionUnchecked.set(
    Permissions.REPORT,
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.UNCHECKED,
        [Permissions.REPORT]: PermissionCheckbox.UNCHECKED
    })

permissionUnchecked.set(
    Permissions.REPORT_ADMIN,
    { ...permissionChecked.get(Permissions.REPORT_CREATE) })

permissionUnchecked.set(
    Permissions.REPORT_CREATE,
    { ...permissionChecked.get(Permissions.REPORT) })

// checklist mappings
permissionChecked.set(
    Permissions.CHECKLISTS_ADMIN,
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.CHECKED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.PRECHECKED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.PRECHECKED
    })

permissionChecked.set(
    Permissions.CHECKLISTS_CREATE,
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.CHECKED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.PRECHECKED
    })

permissionChecked.set(
    Permissions.CHECKLISTS,
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.CHECKED
    })

permissionUnchecked.set(
    Permissions.CHECKLISTS_ADMIN,
    { ...permissionChecked.get(Permissions.CHECKLISTS_CREATE) })
permissionUnchecked.set(
    Permissions.CHECKLISTS_CREATE,
    { ...permissionChecked.get(Permissions.CHECKLISTS) })
permissionUnchecked.set(
    Permissions.CHECKLISTS,
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.UNCHECKED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.UNCHECKED
    })

permissionChecked.set(
    "simple_report",
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT]: PermissionCheckbox.CHECKED
    })
permissionUnchecked.set(
    "simple_report",
    {
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT]: PermissionCheckbox.UNCHECKED
    })

permissionChecked.set(
    "simple_checklists",
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.CHECKED
    }
)
permissionUnchecked.set(
    "simple_checklists",
    {
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS]: PermissionCheckbox.UNCHECKED
    }
)
permissionChecked.set(
    "simple_dashboard",
    {
        [Permissions.DASHBOARDADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.DASHBOARD]: PermissionCheckbox.CHECKED
    }
)
permissionUnchecked.set(
    "simple_dashboard",
    {
        [Permissions.DASHBOARDADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.DASHBOARD]: PermissionCheckbox.UNCHECKED
    }
)
permissionChecked.set(
    "simple_e2e",
    {
        [Permissions.E2EADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.E2E]: PermissionCheckbox.DISABLED
    }
)
permissionUnchecked.set(
    "simple_download",
    {
        [Permissions.DOWNLOAD]: PermissionCheckbox.DISABLED,

    }
)
permissionUnchecked.set(
    "reporting_service",
    {
        [Permissions.CHECKLISTS]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.CHECKLISTS_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.DASHBOARD]: PermissionCheckbox.DISABLED,
        [Permissions.DASHBOARDADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.DOWNLOAD]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT_ADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.REPORT_CREATE]: PermissionCheckbox.DISABLED,
        [Permissions.E2EADMIN]: PermissionCheckbox.DISABLED,
        [Permissions.E2E]: PermissionCheckbox.DISABLED
    }
)

// download mappings

permissionChecked.set(
    Permissions.DOWNLOAD,
    {
        [Permissions.DOWNLOAD]: PermissionCheckbox.CHECKED,
    })
permissionUnchecked.set(
    Permissions.DOWNLOAD,
    {
        [Permissions.DOWNLOAD]: PermissionCheckbox.UNCHECKED,
    })
permissionChecked.set(
    Permissions.DASHBOARD,
    {
        [Permissions.DASHBOARDADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.DASHBOARD]: PermissionCheckbox.CHECKED,
    })
permissionChecked.set(
    Permissions.DASHBOARDADMIN,
    {
        [Permissions.DASHBOARDADMIN]: PermissionCheckbox.CHECKED,
        [Permissions.DASHBOARD]: PermissionCheckbox.PRECHECKED,
    })

permissionUnchecked.set(
    Permissions.DASHBOARDADMIN,
    { ...permissionChecked.get(Permissions.DASHBOARD) })
permissionUnchecked.set(
    Permissions.DASHBOARD,
    {
        [Permissions.DASHBOARDADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.DASHBOARD]: PermissionCheckbox.UNCHECKED
    })

permissionChecked.set(
    Permissions.E2E,
    {
        [Permissions.E2EADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.E2E]: PermissionCheckbox.CHECKED
    }
)
permissionChecked.set(
    Permissions.E2EADMIN,
    {
        [Permissions.E2EADMIN]: PermissionCheckbox.CHECKED,
        [Permissions.E2E]: PermissionCheckbox.PRECHECKED
    }
)
permissionUnchecked.set(
    Permissions.E2EADMIN,
    {...permissionChecked.get(Permissions.E2E)}
)
permissionUnchecked.set(
    Permissions.E2E,
    {
        [Permissions.E2EADMIN]: PermissionCheckbox.UNCHECKED,
        [Permissions.E2E]: PermissionCheckbox.UNCHECKED
    }
)

export interface IPermissionSettings {
    [index: string]: PermissionActions[] | boolean,
    report: PermissionActions[],
    checklists: PermissionActions[],
    download: boolean,
    dashboard: PermissionActions[]
}

const admin: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT_ADMIN),
    ...permissionChecked.get(Permissions.CHECKLISTS_ADMIN),
    ...permissionChecked.get(Permissions.DASHBOARDADMIN),
    ...permissionChecked.get(Permissions.DOWNLOAD),
    ...permissionChecked.get(Permissions.E2EADMIN)
}


const super_user: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT_CREATE),
    ...permissionChecked.get(Permissions.CHECKLISTS_CREATE),
    ...permissionChecked.get(Permissions.DASHBOARD),
    ...permissionChecked.get(Permissions.DOWNLOAD),
    ...permissionChecked.get(Permissions.E2E)
}
const user: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT),
    ...permissionChecked.get(Permissions.CHECKLISTS),
    ...permissionChecked.get(Permissions.DASHBOARD),
    ...permissionUnchecked.get(Permissions.DOWNLOAD),
    ...permissionChecked.get(Permissions.E2E)
}

const empty: IPermissions = {
    ...permissionUnchecked.get(Permissions.REPORT),
    ...permissionUnchecked.get(Permissions.CHECKLISTS),
    ...permissionUnchecked.get(Permissions.DASHBOARD),
    ...permissionUnchecked.get(Permissions.DOWNLOAD),
    ...permissionUnchecked.get(Permissions.E2E)
}

const simple_menu: IPermissions = {
    ...permissionChecked.get("simple_report"),
    ...permissionChecked.get("simple_checklists"),
    ...permissionChecked.get("simple_dashboard"),
    ...permissionChecked.get("simple_e2e"),
    ...permissionUnchecked.get("simple_download"),
}
// eslint-disable-next-line @typescript-eslint/no-unused-vars
const default_view: IPermissions = {
    ...permissionChecked.get(Permissions.REPORT),
    ...permissionChecked.get(Permissions.CHECKLISTS),
    ...permissionChecked.get(Permissions.DASHBOARD),
    ...permissionUnchecked.get(Permissions.DOWNLOAD),
}

const reporting_service: IPermissions = {
    ...permissionUnchecked.get("reporting_service")
}


export interface IRoleSettings {
    [key: string]: IPermissions,
}
const defaultRoles = ["admin", "super_user", "user", "reporting_service"]
export const defaultRolesSettings: IRoleSettings[] = [
    { "admin": admin },
    { "super_user": super_user },
    { "user": user },
    {"reporting_service": reporting_service}
]

export const mapRoletoPermissions = (role: string, permissionSettings?: IRoleSettings[]) => {

    let mappedRole;
    if (defaultRoles.includes(role)) {
        switch (role) {
            case "admin":
                mappedRole = admin;
                break;
            case "user":
                mappedRole = user
                break;
            case "super_user":
                mappedRole = super_user
                break;
            case "simple_menu":
                mappedRole = simple_menu
                break;
            case "reporting_service":
                mappedRole = reporting_service
                break;
            default:
                mappedRole = user
                break;
        }
    }
    return mappedRole;
}


export const comparePermissionsToRoles = (permissions: IPermissions, customRoles: IRoleSettings[] = []) => {
    let roles: IRoleSettings[] = [];
    let role: string = "custom";
    if (!!customRoles.length) {
        roles = defaultRolesSettings.concat(customRoles);
    } else {
        roles = defaultRolesSettings;
    }
    

    if (areAllUnchecked(permissions))
    {
        return {"reporting_service": reporting_service }
    }

    for (const r of roles) {

        const key = Object.keys(r)[0];
        const existingRole = r[key];

        const isSame = isPermissionsTheSame(existingRole, permissions)
        if (isSame) {
            role = Object.keys(r)[0];
        }
    }

    return { [role]: permissions };
}

function areAllUnchecked(object: IPermissions){
    if(!object) return true
    const permissions = Object.entries(object)
    let result = true
    for (let perm of permissions) {
        result = result && perm[1] === PermissionCheckbox.UNCHECKED
    }
    return result
}

function isPermissionsTheSame(object1: IPermissions, object2: IPermissions) {
    const entries1 = Object.entries(object1);
    const entries2 = Object.entries(object2);
    if (entries1.length !== entries2.length) {
        return false;
    }
    for (let [key, value] of entries1) {

        const index = entries1.findIndex((e) => {
            return e[0] === key && e[1] === value;
        })

        let entries2Key = entries2[index][0];
        let entries2Value = entries2[index][1];

        if(value === PermissionCheckbox.DISABLED){
            value = PermissionCheckbox.UNCHECKED;
        }

        if (entries2Value === PermissionCheckbox.DISABLED) {
            entries2Value = PermissionCheckbox.UNCHECKED;
        }
        if (key !== entries2Key || value !== entries2Value) {
            return false;
        }
    }
    return true;
}


export const getConditionalSimpleView = (perms: IPermissions) => {
    let newPerms = { ...simple_menu };

    let check = PermissionCheckbox;
    if (perms[Permissions.REPORT] === check.UNCHECKED) {
        newPerms = Object.assign(newPerms, permissionUnchecked.get("simple_report"));
    }

    if (perms[Permissions.CHECKLISTS] === check.UNCHECKED) {
        newPerms = Object.assign(newPerms, permissionUnchecked.get("simple_checklists"));
    }

    if (perms[Permissions.DASHBOARD] === check.UNCHECKED) {
        newPerms = Object.assign(newPerms, permissionUnchecked.get("simple_dashboard"));
    }

    return newPerms;
}

// Ensures that when a permission that allows 
export const getConditionalPermissions = (prevPerms: IPermissions, changedValue: Permissions, simple_view: boolean = false) => {
    const prevState = prevPerms[changedValue]

    let value = changedValue;

    if (prevState === PermissionCheckbox.DISABLED) {
        return prevPerms;
    }
    // what the state will be when the user has clicked
    const checked = prevState === PermissionCheckbox.CHECKED ? false : true;


    switch (true) {
        case checked && simple_view:
            if (value === "report") {
                return Object.assign({}, prevPerms, permissionChecked.get("simple_report"))
            } else if (value === "checklists") {
                return Object.assign({}, prevPerms, permissionChecked.get("simple_checklists"))
            } else if (value === "dashboard") {
                return Object.assign({}, prevPerms, permissionChecked.get("simple_dashboard"))
            } else {
                return Object.assign({}, prevPerms, permissionChecked.get(value))
            }
        case !checked && simple_view:
            if (value === "report") {
                return Object.assign({}, prevPerms, permissionUnchecked.get("simple_report"))
            } else if (value === "checklists") {
                return Object.assign({}, prevPerms, permissionUnchecked.get("simple_checklists"))
            } else if (value === "dashboard") {
                return Object.assign({}, prevPerms, permissionUnchecked.get("simple_dashboard"))
            }else {
                return Object.assign({}, prevPerms, permissionUnchecked.get(value))
            }
        case checked && !simple_view:
            return Object.assign({}, prevPerms, permissionChecked.get(value))
        case !checked && !simple_view:
            return Object.assign({}, prevPerms, permissionUnchecked.get(value))

    }


}

export const mapPermChecksToPermList = (permissions: IPermissions): string[] => {
    const perms: [string, PermissionCheckbox][] = Object.entries(permissions)



    const permList: string[] = [];

    for (const perm of perms) {

        if (perm[1] === PermissionCheckbox.CHECKED || perm[1] === PermissionCheckbox.PRECHECKED) {
            permList.push(perm[0])
        }
    }
    return permList;
}

export const mapPermListToPermChecks = (permissions: string[]|null): IPermissions => {
    let permChecks: IPermissions = empty;
    const isSimpleView = permissions?.includes("simple_menu")
    if (!permissions) {
        return reporting_service 
    }
    if (isSimpleView) {

        if (permissions.includes(Permissions.REPORT)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get("simple_report")
            } } else {
                permChecks = {
                    ...permChecks,
                    ...permissionUnchecked.get("simple_report")
            }
        }

        if (permissions.includes(Permissions.CHECKLISTS)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get("simple_checklists")
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get("simple_checklists")
            }
        }
        if (permissions.includes(Permissions.DASHBOARD)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get("simple_dashboard")
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get("simple_dashboard")
            }
        }

        permChecks = {
            ...permChecks,
            ...permissionUnchecked.get("simple_download")
        }

    } else {
        if (permissions?.includes(Permissions.REPORT_ADMIN)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.REPORT_ADMIN)
            }

        } else if (permissions.includes(Permissions.REPORT_CREATE)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.REPORT_CREATE)
            }

        } else if (permissions.includes(Permissions.REPORT)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.REPORT)
            }
        }

        if (permissions.includes(Permissions.CHECKLISTS_ADMIN)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.CHECKLISTS_ADMIN)
            }

        } else if (permissions.includes(Permissions.CHECKLISTS_CREATE)) {

            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.CHECKLISTS_CREATE)
            }
        } else if (permissions.includes(Permissions.CHECKLISTS)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.CHECKLISTS)
            }
        }


        if (permissions.includes(Permissions.DASHBOARDADMIN)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.DASHBOARDADMIN)
            }
        }else if (permissions.includes(Permissions.DASHBOARD)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.DASHBOARD)
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get(Permissions.DASHBOARD),
            }
        }
        if (permissions.includes(Permissions.E2EADMIN)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.E2EADMIN)
            }
        } else if (permissions.includes(Permissions.E2E)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.E2E)
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get(Permissions.E2E)
            }
        }

        if (permissions.includes(Permissions.DOWNLOAD)) {
            permChecks = {
                ...permChecks,
                ...permissionChecked.get(Permissions.DOWNLOAD)
            }
        } else {
            permChecks = {
                ...permChecks,
                ...permissionUnchecked.get(Permissions.DOWNLOAD)
            }
        }

    }

    if (areAllUnchecked(permChecks)) {
        return reporting_service 
    }

    return permChecks

}

export const mapPermListToCheckboxTitles = (permList: string[] | null) => {
    if(!permList) return ["-"]
    return permList.map(p => {
        switch(p){
            case Permissions.DASHBOARDADMIN:
                return "Administrate Dashboards"
            case Permissions.DASHBOARD:
                return "View Dashboards"
            case Permissions.REPORT_ADMIN:
                return "Administrate Insights"
            case Permissions.REPORT_CREATE:
                return "Create Insights"
            case Permissions.REPORT:
                return "View Insights"
            case Permissions.CHECKLISTS_ADMIN:
                return "Administrate Actions"
            case Permissions.CHECKLISTS_CREATE:
                return "Create Actions"
            case Permissions.CHECKLISTS:
                return "View Actions"
            case Permissions.DOWNLOAD:
                return "Download Data"
            default:
                return undefined
        }
    })
}

export const compareRoles = (aPerms:string[]|null, bPerms: string[]|null) => {
    const aRole = Object.keys(comparePermissionsToRoles(mapPermListToPermChecks(aPerms)))[0]
    const bRole = Object.keys(comparePermissionsToRoles(mapPermListToPermChecks(bPerms)))[0]

    const sortingHierarchy : {[key:string] : number} = {
        "reporting_service": 5,
        "custom": 4,
        "user": 3,
        "super_user" : 2,
        "admin": 1
    }
    return sortingHierarchy[aRole] - sortingHierarchy[bRole]
}
