import { QueryCriterion, AggregationSegmentMap } from "./transfertypes";
import { AggregationType } from "../helpers/TypeHelpers";

export interface CxmSetup {
    id: string;
    name: string;
    columns: CxmColumn[];
}

export interface CxmColumn {
    bucket: string;
    categorizationColumn: string;
    categorizationColumn2: string;
    id_column: string;
    foreign_bucket: string;
    foreign_key: string;
}

export interface CxmQuery {
    setup_id: string;
    queries: CxmColumnQuery[];
    unique: boolean;
}

export interface CxmGridQuery {
    setup_id: string;
    bucket: string;
    queries: CxmColumnQuery[];
    columns: string[];
    sort_column: string;
    ascending: boolean;
    limit: number;
    offset: number;
    unique: boolean;
}

export interface CxmColumnQuery {
    bucket: string;
    column: string;
    categorization: string;
    filter: QueryCriterion[];
}

export interface CxmAggregationQueryResult {
    segments: AggregationSegmentMap;
}

export interface CxmMultipleAggregationQueryResult {
    aggregation: CxmAggregationQueryResult;
    total: number;
    aggregation_column: string;
}

export type CxmGridQueryResult = {
    [column: string]: any;
}[];

export interface CxmGridQueryFullResult {
    total: number;
    total_shown: number;
    results: CxmGridQueryResult;
}

export interface End2EndSetup {
    id: number;
    org_id: number;
    name: string;
    cxm_id: string;
    settings: End2EndSettings;
}

export interface End2EndSettings {
    buckets: End2EndSettingsMap
    unique: boolean
}

export interface End2EndSettingsMap {
    [bucketId: string]: End2EndColumnSettings;
}

export interface End2EndColumnSettings {
    aggregation_column: string;
    aggregation_type: AggregationType;
    filter: QueryCriterion[];
    show_decimals: boolean;
    show_percentages: boolean;
    use_second_category: boolean;
    grid_columns: string[]
    hide_column: boolean
}

export interface End2EndFullSetup {
    id: number;
    cxm_id: string;
    setup: End2EndSetup;
    cxm: CxmSetup;
}

export const getCatName = (useSecond: boolean, cxm: CxmColumn) => {
    return useSecond && cxm.categorizationColumn2 != "" ? cxm.categorizationColumn2 : cxm.categorizationColumn;
};
