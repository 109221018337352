
import React, { useState, useEffect, useMemo } from 'react'
import { DashboardFull, ParsedDashboardData, User, DashboardTargetNew, LibraryReport } from '../../types/transfertypes'
import GenericDialog from './GenericDialog'
import ClickableDashboardTable from '../DashboardAdmin/ClickableDashboardTable'
import MultiToggle, { MultiToggleOption } from '../Generic/MultiToggle'
import ShowIf from '../Generic/ShowIf'
import InfoTooltip from '../Generic/InfoTooltip'
import { TbMathAvg } from "react-icons/tb";

export interface EditWidgetTargetDialogProps {
    show: boolean,
    target?: DashboardTargetNew,
    saveTarget: (target: DashboardTargetNew) => void,
    onClose: () => void,
    dashboard: DashboardFull,
    parsedDashboard: ParsedDashboardData,
    user: User,
    reports: LibraryReport[],
    kpiKey: string,
    rowId: string
}

export const getDefaultTarget = (): DashboardTargetNew => {return {enabled: false, is_kpi_target: false, target_type: "value", value: 0, kpi_key: "", row_id: "", trend: 0, has_deadline: false, deadline: ""}}

const EditTargetDialog = (props: EditWidgetTargetDialogProps) => {

    const [targetType, setTargetType] = useState<"table" | "value" | "average">("value")
    const tmpIsKpiTarget = useMemo(() => targetType === "table",[targetType])
    const [tmpValue, setTmpValue] = useState<string | undefined>()
    const [tmpKpiKey, setTmpKpiKey] = useState("")
    const [tmpRowId, setTmpRowId] = useState("")
    const [tmpTrend, setTmpTrend] = useState(0)

    useEffect(() => {
        if (!props.show) {
            setTargetType("value")
            setTmpValue(undefined)
            setTmpKpiKey("")
            setTmpRowId("")
            setTmpTrend(0)
            return
        } 

        if (!!props.target && props.target.enabled){
            setTargetType(!!props.target.target_type ? props.target.target_type : props.target.is_kpi_target ? "table" : "value")
            setTmpValue(`${props.target.value}`)
            setTmpKpiKey(props.target.kpi_key)
            setTmpRowId(props.target.row_id)
            setTmpTrend(props.target.trend)
        }
    }, [props.show, props.target])

    useEffect(() => {
        setTmpKpiKey(targetType === "value" ? "" : props.kpiKey)
        setTmpRowId(targetType === "value" ? "" : props.rowId)
    }, [targetType])
    
    if (props.target == undefined) return <></>


    const isEnabled = () => {
        if (tmpIsKpiTarget || targetType === "average" ) {
            if  (tmpKpiKey == "" || tmpRowId == ""){
                return false
            }
            return true
        }
        if (tmpValue == undefined || tmpValue == "") {
            return false
        }
        return true
    }
    const saveTarget = () => {
        if(props.target == undefined) return
        
        if(!isEnabled()){
            let tmp: DashboardTargetNew = structuredClone(props.target)
            tmp = getDefaultTarget()
            props.saveTarget(tmp)
            return
        } 

        if((tmpIsKpiTarget || targetType === "average") && (tmpKpiKey == "" || tmpRowId == "")) {
            return
        }

        let tmpTarget: DashboardTargetNew = {
            enabled: true,
            is_kpi_target: tmpIsKpiTarget,
            target_type: targetType,
            value: isNaN(Number(tmpValue)) ? 0 : Number(tmpValue),
            kpi_key: tmpKpiKey,
            row_id: tmpRowId,
            trend: tmpTrend,
            has_deadline: props.target?.has_deadline ?? false,
            deadline: props.target?.deadline ?? ""
        }
        props.saveTarget(tmpTarget)
    }
    
    const removeTarget = () => {
        props.saveTarget(getDefaultTarget())
    }

    const disableSave = () => {
        if((tmpIsKpiTarget || targetType === "average") && (tmpKpiKey === "" || tmpRowId === "")) return true
        if(targetType === "value" && (tmpValue === undefined || tmpValue === "")) return true
        if(tmpTrend > 1 || tmpTrend < -1) return true
        return false
    }

    const onCellClick = (row: string, kpi: string) => {
        setTmpRowId(row)
        setTmpKpiKey(kpi)
        
    }

    const onValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const regex = /^-?\d+\.?\d*$/gm;
        if (regex.test(e.target.value)) {
            setTmpValue(e.target.value)
        }
        if (e.target.value == ''){
            setTmpValue(undefined)
        }
    }

    const getButtons = () => {
        return <div>
            <button style={{ marginRight: 7 }} onClick={removeTarget} disabled={!props.target?.enabled} className='btn btn-danger'>Remove target</button>
            <button style={{ marginRight: 7 }} onClick={saveTarget} disabled={disableSave()} className='btn btn-primary'>Save</button>
            <button onClick={() => props.onClose()} className='btn btn-default'>Cancel</button>
        </div>
    }

    const toggleOptions: MultiToggleOption[] = [
        {
            value: 'value',
            label: <div className="multi-label"><i className="fa fa-pencil ml-1 mb-1" aria-hidden="true"></i> Number</div>
        },
        {
            value: 'table',
            label: <div className="multi-label"><i className="fa fa-table ml-1 mb-1" aria-hidden="true"></i> Table</div>
        },
        {
            value: 'average',
            label: <div className="multi-label"><TbMathAvg className='ml-1 mb-1' /> Average</div>
        }
    ]

    return <div>
        <GenericDialog style={{minWidth: 690}} onClose={props.onClose} title='Select target' show={props.show} getButtons={getButtons} >
            <label htmlFor='widget-trend' className='mt-3'>
                Above target is: <InfoTooltip text="When the value is above the target, it should be seen as..." /> 
            </label>
            <div className={`target-color-container`}>
                <div className={`target-color-option mr-2 ${tmpTrend == 0 ? 'target-color-selected' : ''}`} onClick={() => setTmpTrend(0)}>
                    <div className="target-color-label"><span>Neutral</span></div>
                    <div className="target-color-color" style={{backgroundColor: "#263753"}}></div>
                </div>
                <div className={`target-color-option mr-2 ${tmpTrend == -1 ? 'target-color-selected' : ''}`} onClick={() => setTmpTrend(-1)}>
                    <div className="target-color-label"><span>Negative</span></div>
                    <div className="target-color-color" style={{backgroundImage: "linear-gradient(#c83c5e, #b33455)"}}></div>
                </div>
                <div className={`target-color-option mr-2 ${tmpTrend == 1 ? 'target-color-selected' : ''}`} onClick={() => setTmpTrend(1)}>
                    <div className="target-color-label"><span>Positive</span></div>
                    <div className="target-color-color" style={{backgroundImage: "linear-gradient(#389956 , #268f53)"}}></div>
                </div>
                
            </div>

            
            <br/>
            <label>Target type:</label>
            <MultiToggle 
                value={targetType}
                onClick={(value) => setTargetType(value)}
                options={toggleOptions}
                style={{marginBottom: 10}}
            />

            <ShowIf if={targetType === "value"}>
                <label htmlFor="widget-value">
                    Value: 
                </label>
                <input id="widget-value" type="text" className="form-control" placeholder="Insert number" value={tmpValue ?? ''} onChange={onValueChange} />
            </ShowIf>

            <ShowIf if={targetType === "table"}>
                <div className='kpi-table-wrapper'>
                    <ClickableDashboardTable
                        dashboard={props.dashboard}
                        parsedDashboard={props.parsedDashboard}
                        user={props.user}
                        selectedRow={tmpRowId}
                        selectedKpi={tmpKpiKey}
                        onCellClick={onCellClick}
                        reports={props.reports}
                    />
                </div>
            </ShowIf>
        </GenericDialog>

    </div>
}

export default EditTargetDialog
