/* REACT */
import React from 'react'
import { connect } from 'react-redux'
import { getCateoryValueKpiView, getFilter, getIdColumn, getCategoryValue, shouldReportInfoChangeUpdateData, getMaxX } from '../../../helpers/ReportHelpers'
import { withSettingsPropagation } from '../../../helpers/SettingsService'
import KPIReportAddElement from '../ReportComponents/KPIReportAddElement'
import { getMultipleAggregationsData, getAggregationData } from '../../../actions/ReportActions'
import { hashNValues } from '../../../helpers/GeneralHelpers'
import { SortableContainer, SortableElement, SortableHandle } from 'react-sortable-hoc'
import ShowIf from '../../Generic/ShowIf'
import WordWrap from '../../Generic/WordWrap'

const mapStateToProps = (state, ownProps) => {
    return {
        aggregationdata: state.Report.aggregationdata,
    }
}

const SortableKpiOverview = SortableContainer(({children, className}) => {
    return <div className={className}>{children}</div>
})

const SortableKpiTable = SortableElement((props) => <KpiTable {...props} />)

const SortableOptional = SortableElement(({margin, onClick}) => (
    <div className={`col-md-4 center-this no-padding margin-bottom-20px`} onClick={onClick}>
        <DragHandle className="float-left optional-kpi-drag-handle"/>
        <div className={`emptyKpiView ${margin} center-this abc-click min-height-187`}>
            <p className="kpiElementFontAndColorP2 padding-top-75px">Add KPI</p>
            <p className="kpiElementFontAndColorP1"><i className="font-size-12px">Optional</i></p>
        </div>
    </div>
))

class KpiView extends React.Component {
    state = {
        selectedCategory: -1,
        hoverPosition: '',
        kpiToEdit: -1,
        initialLoad: true,
        selectedPosition: -1,
    }
    
    componentDidUpdate(prevProps, prevState, snapshot) {
        if (((shouldReportInfoChangeUpdateData(prevProps.reportdata, this.props.reportdata) || JSON.stringify(this.props.slicers) !== JSON.stringify(prevProps.slicers)) && this.props.show) || (this.props.show && !prevProps.show) || this.state.initialLoad) {
            this.setState({initialLoad: false})
            this.getAllAggrData()
        }
    }

    getKPIKey() {
        let reportdata = this.props.reportdata
        let limit = this.props.limit !== -1 ? this.props.limit : reportdata.report.limit
        let filter = getFilter(reportdata.report, this.props.slicers)
        return hashNValues(limit, filter)
    }

    getAllAggrData() {
        let { dispatch } = this.props
        let queries = []
        let limit = this.props.limit !== -1 ? this.props.limit : this.props.reportdata.report.limit
        let data = {
            group_column: this.props.reportdata.info.model.categorization_name,
            filter: getFilter(this.props.reportdata.report, this.props.slicers),
            sort_column: this.props.reportdata.report.sort_column,
            sort_direction: this.props.reportdata.report.sort_direction,
            limit: limit,
            kpis: "[]",
        }

        this.props.kpiItems.forEach((item, index) => {
            if (item.shown) {
                let _data = Object.assign({}, data, { aggregate_column: item.column })
                queries.push(_data)
            }
        })

        if(this.props.enabled && !this.props.forPrint){ // we use the data fetched by secret component instead, when used for generating pdf
            dispatch(getMultipleAggregationsData(this.props.reportdata.info.id, this.props.reportdata.report.report_id, { queries: queries }, "kpiView-", this.getKPIKey()))
        }

        this.getIdColumnAggregation()
    }

    setPercentage(index) {
        let items = this.props.kpiItems
        items[index].showPercentages = !items[index].showPercentages
        this.setState({ kpiItems: items })
    }

    savePercentage(index) {
        let kpiConfig = this.props.kpiItems.slice()
        kpiConfig[index].showPercentages = !kpiConfig[index].showPercentages
        this.props.setData("report", "kpi_config", JSON.stringify(kpiConfig))
    }

    setCategory(index) {
        this.setState({ selectedCategory: index })
    }

    reset() {
        if (this.props.kpiItems[this.state.kpiToEdit].shown) {
            let kpiConfig = this.props.kpiItems.slice()
            kpiConfig[this.state.kpiToEdit] = { shown: false }
            this.props.setData("report", "kpi_config", JSON.stringify(kpiConfig))
            this.setState({ kpiToEdit: -1 })            
        }
    }

    cancel() {
        this.setState({ kpiToEdit: -1 })
    }

    deleteRow() {
        let kpiConfig = this.props.kpiItems.slice()
        let slice = kpiConfig.slice(0, kpiConfig.length-3)

        this.props.setData("report", "kpi_config", JSON.stringify(slice))
    }

    addAnotherRow() {
        let kpiConfig = this.props.kpiItems.slice()
        kpiConfig.push({shown: false})
        kpiConfig.push({shown: false})
        kpiConfig.push({shown: false})

        this.props.setData("report", "kpi_config", JSON.stringify(kpiConfig))
    }

    saveKpi(kpi, index) {
        let obj = {
            aggregation: kpi.aggregation,
            column: kpi.column,
            showPercentages: kpi.showPercentage,
            showDecimals: kpi.showKPIDecimals,
            shown: true
        }
        let kpiConfig = this.props.kpiItems.slice()
        kpiConfig[this.state.kpiToEdit] = obj
        this.props.setData("report", "kpi_config", JSON.stringify(kpiConfig))
        this.setState({ kpiToEdit: -1 })
    }

    getIdColumnAggregation() {
        const column = getIdColumn(this.props.reportdata.info.model.columns)
        const limit = this.props.limit !== -1 ? this.props.limit : this.props.reportdata.report.limit
        const data = { 
            aggregate_column: column, 
            group_column : this.props.reportdata.info.model.categorization_name,
            filter: getFilter(this.props.reportdata.report, this.props.slicers),
            sort_column: this.props.reportdata.report.sort_column === '-' ? null : this.props.reportdata.report.sort_column,
            sort_direction: this.props.reportdata.report.sort_direction === '-' ? null : this.props.reportdata.report.sort_direction,
            limit: limit,
            kpis: this.props.reportdata.report.kpis,
        }
        if(this.props.enabled && !this.props.forPrint){ // we use the data fetched by secret component instead, when used for generating pdf
            this.props.dispatch(getAggregationData(this.props.reportdata.info.id, this.props.reportdata.report.report_id, data, "idColumn", this.getKPIKey()))
        }

    }

    setSelectedPosition(pos) {
        let res
        if (pos === this.state.selectedPosition) res = -1
        else if (pos !== -1) res = pos
        this.setState({ selectedPosition: res })
    }

    onSortEnd = ({oldIndex, newIndex}) => {
        let kpi_config = JSON.parse(this.props.reportdata.report.kpi_config)
        kpi_config.splice(newIndex, 0, kpi_config.splice(oldIndex, 1)[0])  
        this.props.setData("report", "kpi_config", JSON.stringify(kpi_config))
    }

    render() {
        if(!this.props.show) return null

        let ys = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25, 26, 27, 28, 29, 30, 31]
        let categorizations = this.props.reportdata.info.model.categorization
        const maxX = getMaxX(categorizations)
        let xs = Array(maxX+1).fill(0).map((x,i)=>i) //fills array with 0,1,2,... until maxX is reached.

        let id = this.props.reportdata.report ? this.props.reportdata.report.report_id : -1
        let aggrData = this.props.aggregationdata.get2(this.getKPIKey(), id)
        let kpiItems = this.props.kpiItems.slice()
        if(this.props.forPrint && this.props.secretAggrData){ // use the data fetched by secret component when used for generating pdf
            aggrData = this.props.secretAggrData.get2(this.getKPIKey(), id)
        }
        
        let getCategory = (x, y) => {
            if (categorizations !== undefined) {
                for (let i = 0; i < categorizations.length; i++) {
                    const position = categorizations[i].position
                    if (position.x === x && position.y === y) {
                        return categorizations[i]
                    }
                }
            }
        }
        let columns = JSON.parse(this.props.reportdata.report.columns)
        columns.sort((a, b) => {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        })
        let clickableCategories = this.props.clickableCategories

        return (
            <div>
                <div className="col-md-12 no-padding margin-top-20px row">
                    <div className="col-md-9 no-padding" style={{overflow: 'auto'}}>

                        <SortableKpiOverview className="kpi-box-fix" onSortEnd={this.onSortEnd} axis="xy" useDragHandle lockToContainerEdges lockOffset={"0%"} useWindowAsScrollContainer={false}>
                            {
                                kpiItems.map((item, index) => {
                                    let data = aggrData.get("kpiView-" + item.column)
                                    let localitem = Object.assign({}, item) // making sure '.data' is not saved in the database
                                    localitem.data = data
                                    if (this.props.editMode) {
                                        if (localitem.shown) {
                                            if (this.state.kpiToEdit === index) {
                                                return (
                                                    <div key={index} className="col-md-4 center-this no-padding" >
                                                        <div className="emptyKpiAdd margin-left-right-10px center-this abc-click min-height-187">
                                                            <KPIReportAddElement
                                                                index={index}
                                                                columns={columns}
                                                                reportdata={this.props.reportdata}
                                                                resetKpi={() => this.reset()}
                                                                editMode={this.props.editMode}
                                                                saveKpi={(kpi, index) => this.saveKpi(kpi, index)}
                                                                kpi={localitem}
                                                                noHover={true}
                                                                cancel={() => this.cancel()}
                                                                fromKpiView={true}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                            return <SortableKpiTable 
                                                        key={index} 
                                                        index={index}
                                                        hoverPosition={this.state.hoverPosition} 
                                                        selectedPosition={this.state.selectedPosition}
                                                        setIndex={() => this.setState({ kpiToEdit: index })} 
                                                        item={localitem} 
                                                        ys={ys} 
                                                        xs={xs} 
                                                        getCategory={(x, y) => getCategory(x, y)} 
                                                        setPercentage={() => this.savePercentage(index)}
                                                        aggrData={aggrData}
                                                        addClickSlicer={(value,limit) => this.props.addClickSlicer(this.props.reportdata.info.model.categorization_name, value, limit)}
                                                        editMode={this.props.editMode}
                                                        changeView={this.props.changeView}
                                                        maxX={maxX}
                                                        />                                    
                                        } else if (!localitem.shown){
                                            if (this.state.kpiToEdit === index) {
                                                return (
                                                    <div key={index} className="col-md-4 center-this no-padding" >
                                                        <div className="emptyKpiAdd kpi-view-edit">
                                                            <KPIReportAddElement
                                                                index={index}
                                                                columns={columns}
                                                                reportdata={this.props.reportdata}
                                                                resetKpi={() => this.reset()}
                                                                editMode={this.props.editMode}
                                                                saveKpi={(kpi, index) => this.saveKpi(kpi, index)}
                                                                noHover={true}
                                                                cancel={() => this.cancel()}
                                                                fromKpiView={true}
                                                            />
                                                        </div>
                                                    </div>
                                                )
                                            }
                                        }

                                        return <SortableOptional margin={"margin-left-right-10px"} key={index} index={index} onClick={() => this.setState({ kpiToEdit: index })}/>
                                    } else {
                                        if (localitem.shown) {
                                            return <KpiTable 
                                                        key={index} 
                                                        index={index} 
                                                        setIndex={() => {}} 
                                                        hoverPosition={this.state.hoverPosition} 
                                                        selectedPosition={this.state.selectedPosition}
                                                        item={localitem} 
                                                        ys={ys} 
                                                        xs={xs} 
                                                        getCategory={(x, y) => getCategory(x, y)}
                                                        setPercentage={() => this.setPercentage(index)} 
                                                        aggrData={aggrData}
                                                        addClickSlicer={(value,limit) => this.props.addClickSlicer(this.props.reportdata.info.model.categorization_name, value, limit)}
                                                        changeView={this.props.changeView}
                                                        editMode={this.props.editMode}
                                                        maxX={maxX}
                                                    />
                                        }
                                        if (this.props.forPrint){ // dont show empty box when used for generating pdf
                                            return null
                                        }
                                        return (
                                            <div key={index} className="col-md-4 center-this no-padding">
                                                <div className="center-this ">
            
                                                </div>
                                            </div>
                                        )
                                    }
                                })
                            }
                        </SortableKpiOverview>
                    </div>
                    <div className="col-md-3 padding-left-right-10px">
                        <table className="table table-bordered abc-monitor-graph-slicer-table gray-background border-bottom-0px">
                            <thead>
                                <tr className="white bg-dark-forest">
                                    <td colSpan={maxX+1} className="abc-click padding-5px-imp">{this.props.reportdata.info.model.categorization_name}</td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={maxX+1} className="abc-click padding-5px-imp">
                                        Total
                                    </td>
                                </tr>
                                {
                                    ys.map((y) => {
                                        return (
                                            <tr key={y}>
                                                {
                                                    xs.map((x) => {
                                                        let pos = getCategory(x, y)

                                                        if (pos) {
                                                            let color =`rgb(${pos.color.r}, ${pos.color.g}, ${pos.color.b})`
                                                            let indicator = <div key={x+'indi'} style={{display: 'inline-block', marginRight: 10, width: 5, height: 5, padding: 5, backgroundColor: color}}></div>
                                                   
                                                            let clickable = clickableCategories.includes(pos.name)
                                                            return (
                                                                <td className={`padding-5px-imp ${this.state.selectedPosition === `${y}${x}` && clickable ? 'hover-categorization' : ""} ${!clickable ? "grey-striped-background half-opacity" : "hover-dark-forest abc-click"}`}
                                                                    onMouseEnter={() => {if(clickable) this.setState({ hoverPosition: y + '' + x })}}
                                                                    onMouseLeave={() => {if(clickable) this.setState({ hoverPosition: '' })}} 
                                                                    key={x}
                                                                    title={pos.name}
                                                                    onClick={() => {if(clickable) this.setSelectedPosition(`${y}${x}`)}}>
                                                                        <WordWrap>{indicator}
                                                                    {pos.name}
                                                                    </WordWrap>
                                                                </td>
                                                            )
                                                        }
                                                        return (
                                                            <td className="no-show-td" key={x}></td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                    
                </div>
                <div className="col-md-12 no-padding">
                    <hr className="custom-hr-report-2"></hr>
                    {
                        this.props.editMode ? 
                            <div>
                                <button className="btn btn-default btn-sm margin-top-10px" onClick={() => this.addAnotherRow()}>Add another row</button>
                                <button disabled={kpiItems.length === 0} className="btn btn-danger btn-sm margin-left-10px margin-top-10px" onClick={() => this.deleteRow()}>Delete latest row</button>
                            </div>
                        : null
                    }
                </div>
            </div>

        )
    }
}

export const DragHandle = SortableHandle(({className}) => <span className={`fa fa-ellipsis-v ${className}`}></span>)

class KpiTable extends React.Component {

    render() {
        let getPColor = (bool) => {
            if (bool) {
                return 'percentage-no-color'
            }
            return 'percentage-color'
        }

        let getColorHover = (pos) => {
            if (this.props.hoverPosition === pos) {
                return 'hover-kpi-cell'
            }
            return ''
        }

        const getSelectedColor = (pos) => {
            if (this.props.selectedPosition === pos) return 'hover-kpi-cell'
            return ''
        }

        return (
            <div className="col-md-4 center-this no-padding margin-bottom-20px">
                <div className={`min-height-187 margin-left-right-10px `}>
                    <div>
                        <table className="table table-striped table-bordered abc-monitor-graph-slicer-table table-custom blue-border border-bottom-0px">
                            <thead>
                                <tr className="ABC-background">
                                    <td colSpan={this.props.maxX+1} className="padding-5px-imp border-bottom-width-1px">
                                        <ShowIf if={this.props.editMode}>
                                            <DragHandle className="float-left kpi-table-drag-handle"/>
                                        </ShowIf>
                                        <p className={`zero-padding p-margin float-left abc-click unselectable ${getPColor(this.props.item.showPercentages)}`}
                                            unselectable="true" onClick={() => this.props.setPercentage()}>%</p>
                                        <b className="abc-click" onClick={() => this.props.setIndex()} >{this.props.item.column}</b>
                                        <p className="float-right margin-bottom-0px font-size-12px">
                                            {this.props.item.aggregation}
                                        </p>
                                    </td>
                                </tr>
                            </thead>
                            <tbody>
                                <tr>
                                    <td colSpan={this.props.maxX+1} className="padding-5px-imp abc-click" onClick={() => this.props.changeView("grid")}>{getCateoryValueKpiView('', this.props.item)}</td>
                                </tr>
                                {
                                    this.props.ys.map((y) => {
                                        return (
                                            <tr key={y}>
                                                {
                                                    this.props.xs.map((x) => {
                                                        let pos = this.props.getCategory(x, y);
                                                        if (pos) {
                                                            let color =`rgb(${pos.color.r}, ${pos.color.g}, ${pos.color.b})`
                                                            let indicator = <div key={x+'indi'} style={{display: 'inline-block', marginRight: 10, width: 5, height: 5, padding: 5, backgroundColor: color}}></div>
                                                            let _limit = getCategoryValue(pos.name, false, false, "count", this.props.aggrData.get("idColumn", null))
                                                            let limit = _limit && _limit !== "-" && _limit !== "..." ? _limit : 0
                                                            return (
                                                                <td className={`abc-click padding-5px-imp ${getSelectedColor(`${y}${x}`)} ${getColorHover(y + '' + x)}`} key={x} onClick={() => this.props.addClickSlicer(pos.name, limit)}>{indicator}{getCateoryValueKpiView(pos.name, this.props.item)}</td>
                                                            )
                                                        }
                                                        return (
                                                            <td className="no-show-td" key={x}></td>
                                                        )
                                                    })
                                                }
                                            </tr>
                                        )
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        )
    }
}

const shouldComponentUpdateSettings = (prevState, curState) => {
    return prevState.selectedCategory !== curState.selectedCategory
}

const getSettingsKeys = (state) => {
    let { hoverPosition, kpiItems, initialLoad, ...settings } = state
    return Object.keys(settings)
}

KpiView = withSettingsPropagation(KpiView, getSettingsKeys, shouldComponentUpdateSettings, null, null, "kpi")

KpiView = connect(mapStateToProps)(KpiView)

export default KpiView