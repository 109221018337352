import React, { useEffect, useState, Fragment} from 'react'
import { connect } from 'react-redux'
import Select from '../components/Generic/CustomSelect'
import { User, Checklist, DataBucket, ReportData, BucketSetup} from '../types/transfertypes'
import { downloadBucketDataSpecs, getBucketsDataInfo, updateBucketSetup} from '../actions/BucketActions'
import { getUsers } from '../actions/SystemActions'
import { getUserInfo } from '../actions/UserActions'
import { SingleKeyMap } from '../helpers/Collections'
import ReactTooltip from 'react-tooltip'
import ShowIf from '../components/Generic/ShowIf'
import ReactMarkdown from 'react-markdown'



/* CSS */
import '../css/DataSpecs.css'
import { getReportGridData} from '../actions/ReportActions'
import AddBucketDescription from '../components/Dialogs/AddBucketDescription'
import EditBucketDescription from '../components/Dialogs/EditBucketDescription'
import { is } from '../helpers/PermissionHelpers'
import RequirePermission from '../components/RequirePermission'
import { notifySuccess } from '../helpers/NotificationManager'



export interface DataSpecsProps {
    users: User[],
    user: User,
    checklist: Checklist,
    buckets: SingleKeyMap<DataBucket>,
    bucket: DataBucket,
    dispatch: any,
    bucketId: any,
    itemId: any,
    onClose: () => void,
    downloadToken: any,
    reportInfo: ReportData,
    griddata: any

}
let mapStateToProps = (state: any, ownProps: DataSpecsProps ) => {
    return {
        item: state.Item.item,
        bucket: state.Bucket.buckets,
        buckets: state.Bucket.bucketDetails,
        data: state.Item.data,
        user: state.User.info,
        users: state.System.users,
        download_token: state.Bucket.downloadToken,
        reportInfo: state.Info.reportInfo,
        griddata: state.Report.griddata,

    }
}
let DataSpecs = (props: DataSpecsProps) => {
    type sortOrder = "asc" | "desc" | "default";

    const [search, setSearch] = useState("")
    const [chosenBucket, setChosenBucket] = useState<  DataBucket| undefined>(undefined)
    const [editDescriptions, setEditDescriptions] = useState(false)
    const [filterType, setFilterType] = useState<string[]>([])
    const [modalEdit, setModalEdit] = useState(false)
    const [modalAdd, setModalAdd] = useState(false)
    const [chosenColumnName, setChosenColumnName] = useState("")
    const [chosenColumnDescription, setChosenColumnDescription] = useState("")
    const [sortDirection, setSortDirection] = useState<sortOrder>("default")
    const [bucketSetup, setBucketSetup] = useState< BucketSetup | undefined >(undefined)
    const [exampleMap, setExampleMap] = useState< Map<string, any>>()


    // On Mount
    useEffect(() => {
        let { dispatch } = props
        dispatch(getUsers())
        dispatch(getUserInfo())
        props.dispatch(getBucketsDataInfo())  
    }, [])

    useEffect(() => {
        if (chosenBucket === undefined) {
            let id = Object.values(props.buckets.data)[0]
            setChosenBucket(id)
        } 
    }, [props.buckets])

    useEffect(() => {
        generateExampleArray()

    }, [props.griddata])
    useEffect(() => {
        if (chosenBucket != undefined) {
            setBucketSetup(chosenBucket.info.setup)
        }
        getGridData()
    }, [chosenBucket])

    let options = new Set<string>()
    props.buckets.forEach((_, i) => {
        options.add(i.id)
    })

    let actualOptions = Array.from(options).map((o) => {
        return {value: o, label: props.buckets.get(o) ? props.buckets.get(o)?.info.name : ""}
    })

    const setBucket = (b: any) => {
        props.buckets.forEach((_, bucket) => {
            if ((b['value']) == bucket.id) {
                setChosenBucket(bucket)
            }
        })
    }

    const getGridData = () => {
        if (chosenBucket != undefined) {

            let bucketCols = chosenBucket.info.model.columns

            let cols = bucketCols.map(x => {return x.name})
            const defaultSortColumn = cols[0]
            let data = {
                sort_column:  defaultSortColumn,
                sort_direction: "desc",
                filter: [],
                column_filter: cols,
                offset: 0,
                limit: 100
            }
  
            props.dispatch(getReportGridData(chosenBucket.id, data))
     
            }   
    }


    const getTypeData = () => {
        let inactData: string[] = []
        let erpData: string[] = [] 

        let bucket = chosenBucket != undefined ? chosenBucket : props.buckets != undefined ? Object.values(props.buckets.data)[0] : null
        if (bucket == null) return [inactData, erpData] as const
        inactData = structuredClone(bucket.info.setup.inact_now_data)
        erpData = bucket.info.model.columns.filter( (item:any) => !inactData.includes(item.name)).map(c => c.name)
        erpData.filter((i) => i)

        return [inactData, erpData] as const
    }

    const [inactData, erpData] = getTypeData()
  

    const onSaveInfo = (description: any) => {
        if(chosenBucket == undefined) return
        let tmpBucketSetup;
        if (bucketSetup !== undefined) {
            tmpBucketSetup = bucketSetup
        }
        else {tmpBucketSetup = {...chosenBucket.info.setup} }

        let tmp: DataBucket = structuredClone(chosenBucket)

        tmp.info.setup.descriptions[chosenColumnName] = description
        tmpBucketSetup.descriptions = tmp.info.setup.descriptions
        setBucketSetup(tmpBucketSetup)
        props.dispatch(updateBucketSetup(chosenBucket.id, tmpBucketSetup, updateBucketSetupHandled ))
    }

    const updateBucketSetupHandled = async (data: any) => {
        if (data.success) {
            props.dispatch(getBucketsDataInfo())  
            onCloseInfo()

        } else return;
    }
    const onCloseInfo = () => {
        setModalAdd(false)
        setModalEdit(false)
    }
  

    const getEditButtons = (n: any) => {
        if (chosenBucket != undefined && bucketSetup != undefined) {
            if (bucketSetup.descriptions[n]){
                    return "edit"
            }
            else return "add"
        }
    }
    const getDescriptions = (n: any) => {
        if (chosenBucket != undefined && bucketSetup != undefined) {
            if (bucketSetup.descriptions[n]){
                    return true
            }
            else return false
        }
    }
 

    const getFilter = (c: any) => {
        if (!filterType.includes(c)) {
                setFilterType([c])           
        } else {
            const newArr = [...filterType];
            newArr.splice(newArr.findIndex(item => item === c), 1)
            setFilterType(newArr)
        }
    }
    let downloadDataSpecs = (type: string) => {
        const {dispatch } = props;
        const colHeaders: string[] = [];
        colHeaders.push("Column")
        colHeaders.push("Descriptions")
        colHeaders.push("Data Type")

        colHeaders.push("Example")
        let idRows = getShownColumns()

        const data = {
            name: chosenBucket?.info.name,
            type: type,
            query: {
                column_filter: colHeaders,
                offset: -1, 
                limit: -1, 
            },
            rows: idRows,
            examples: exampleMap !== undefined ? JSON.stringify(Object.fromEntries(exampleMap)) : null,
            include_comments: true,
        }
        let id = chosenBucket?.id ? chosenBucket.id : Object.values(props.buckets.data)[0].id

        notifySuccess("You will get a notification when your download is ready")

        dispatch(downloadBucketDataSpecs(id, data))
    }
    const getType = (c:string) => {
        if (erpData.includes(c)) {
            return "ERP"
        }
        else if (inactData.includes(c))
            return "inact"        
    }


    const toggleSortOrder = () => {
        setSortDirection(prevOrder => {
            if (prevOrder === "default") return "asc";
            if (prevOrder === "asc") return "desc";
            else return "default";
        });
    }
 
    const getSortIcons = () => {
        let className = "float-right arrow-align abc-sort-icon margin-left-5px fa fa-long-arrow-";
        if (sortDirection == "asc") {className += "up";}
        else if (sortDirection == "default") {className = ""}
        else if (sortDirection == "desc"){className += "down"}
        
        return <span className={className} />

    } 

    function randomNumber(min:number, max:number) {
        return Math.floor((Math.random() * (max - min + 1) + min) * 100) / 100;
    }
      
    function randomDate(start:Date, end:Date) {
        return new Date(start.getTime() + Math.random() * (end.getTime() - start.getTime()));
    }

    const generateExampleArray = () => {
        let res: any
        if (chosenBucket == undefined) return 
        let griddata = props.griddata.get("data", [])
        let index: number
        let bucketCols = chosenBucket.info.model.columns
        let reportCols = griddata.columns

        if (reportCols == undefined) return
        bucketCols = bucketCols.filter((item: any) => reportCols.includes(item.name))
        var map = new Map()

        bucketCols.forEach((item: any) => {
            let colName = item.name
            index = griddata.columns.indexOf(colName)
            for(let i = 0; i < griddata.data.length && !map.has(colName); i++){
                res = griddata.data[i][index]
                if (res !== 0 && res !== ""){
                    map.set(colName, res)
                }
            }
        })
        chosenBucket.info.model.columns.forEach((item: any) => {
            let colname = item.name
            if (!map.has(colname)){
                switch (item.type) {
                    case ("string"): {
                        res = "word"
                        break;
                    }
                    case ("decimal"): {
                        res = randomNumber(1, 100); //random number between 1 & 100
                        break;
                    }
                    case ("id"): {
                        res = "id"
                        break;
                    }
                    case ("categorization"): {
                        res = "categorization"
                        break;
                    }
                    case ("date"): {
                        res = randomDate(new Date(1970, 1,1), new Date()).toISOString().split('T')[0] //random date in the past
                        break;
                    }
                }
                map.set(colname, res)
            }
        })
        setExampleMap(map)
    }
    const getExample = (col: string) => {
        if (exampleMap === undefined) return
        let example = exampleMap.get(col)
        return example
    }
    const addColToInactNow = (col: string) => {
        if(chosenBucket == undefined) return
        const tmpBucketSetup = {...chosenBucket.info.setup}
        let inactData: string[] = []
        let tmp: DataBucket = structuredClone(chosenBucket)

        if (tmp.info.setup.inact_now_data == undefined) {
            inactData.push(col)
            tmp.info.setup.inact_now_data = inactData
        } else {
            if (tmp.info.setup.inact_now_data.includes(col)) {
                const newArr = [...tmp.info.setup.inact_now_data];
                newArr.splice(newArr.findIndex(item => item === col), 1)
                tmp.info.setup.inact_now_data = newArr
            } else {
                tmp.info.setup.inact_now_data.push(col)
            }
        }
        tmpBucketSetup.inact_now_data = tmp.info.setup.inact_now_data
        setChosenBucket(tmp)
        props.dispatch(updateBucketSetup(chosenBucket.id, tmpBucketSetup, updateBucketSetupHandled ))
    }
    const exitEditDescription = () => {
        if (editDescriptions == false) setEditDescriptions(true)
        else { 
            setEditDescriptions(false)
        }
    }

    const columnsFilter = (c: string) => {
        if (!c.toLowerCase().includes(search.toLowerCase())) return false
        if (filterType.includes("ERP") && getType(c) != "ERP") return false
        if (filterType.includes("inactData") && getType(c) != "inact") return false

        return true
    }

    const columnsSort = (a: string, b: string) => {
        if (sortDirection == "asc") {
            return a.toLowerCase().localeCompare(b.toLowerCase())
        } else if (sortDirection == "desc") {
            return b.toLowerCase().localeCompare(a.toLowerCase())
        }
        return 0
    }

    const getShownColumns = () => {
        if (chosenBucket == undefined) return []
        let columns = structuredClone(chosenBucket.info.model.columns).map(c => c.name)
        columns = columns.filter(columnsFilter)
        if (sortDirection != "default") {
            columns.sort(columnsSort)
        }
        return columns
    }

    const tmpShownColumns = getShownColumns()

    const otherBuckets = props.buckets
        .map((_, b) => b).filter(b => b.id != chosenBucket?.id)
        .map(b => ({b: b, val: b.info.model?.columns?.map(c => c.name).filter(columnsFilter).length ?? 0}))
        .filter(b => b.val != 0)

    const selectedValue = Object.values(actualOptions).find(o => o.value == chosenBucket?.id)

    return (
        <div className="">
            <div className="dataspecs-title">
                <div className="dataspecs-filters ">
                    <div  style={{whiteSpace: "nowrap", display: "flex", alignItems:"center", marginLeft:"10px", verticalAlign:"middle"}}>
                        <span className="vertical-align-middle" style={{marginRight: 8}}>{`Data Source: `}</span>
                    </div>
                    <Select value={selectedValue} options={actualOptions} placeholder={chosenBucket?.info.name} onChange={(e:any) => {setBucket(e)}} className={"fixed-slicer-select margin-left-10 slicer-width"} classNamePrefix="fixed-slicer-select"   />
                    <div style={{whiteSpace: "nowrap", display: "flex", alignItems:"center", marginLeft:"20px"}}>
                        <ShowIf if={inactData.length >= 1}>    
                            <span className="vertical-align-middle " style={{marginRight: 8, fontSize:"14px"}}>{`Filter by: `}</span>
                            <ShowIf if={filterType.includes("ERP") && !filterType.includes("inactData")}>
                                <button className="h6 border rounded-pill py-1 px-2 mb-0" style={{marginLeft:"5px", borderRadius:"10px", backgroundColor:"#eee0c3"}} onClick={() => getFilter("ERP")} > ERP </button> 
                            </ShowIf>
                            <ShowIf if={!filterType.includes("ERP") || filterType.includes("inactData")} >
                                <button className="h6 border rounded-pill py-1 px-2 mb-0" style={{marginLeft:"5px", borderRadius:"10px", backgroundColor:"white"}} onClick={() => getFilter("ERP")} > ERP </button> 
                            </ShowIf>
                            <ShowIf if={filterType.includes("inactData") && !filterType.includes("ERP")}>
                                <button className={`h6 border rounded-pill py-1 px-2 mb-0`} style={{marginLeft:"5px", borderRadius:"10px", backgroundColor:"#eee0c3"}} onClick={() => {getFilter("inactData")}} ><img src="./img/inact_ia_logo_dark.svg" alt="" style={{width:"25px"}} /> Inact Now </button>
                            </ShowIf>
                            <ShowIf if={!filterType.includes("inactData") || filterType.includes("ERP")}>
                                <button className={"h6 border rounded-pill py-1 px-2 mb-0"} style={{marginLeft:"5px", borderRadius:"10px", backgroundColor:"white"}} onClick={() => {getFilter("inactData")}} ><img src="./img/inact_ia_logo_dark.svg" alt="" style={{width:"25px"}} /> Inact Now</button>
                            </ShowIf>
                        </ShowIf>
                    </div>
                </div>
                <div className='dataspecs-toolbar'>

                    {/* To do  */}
                        {/* <button className="btn btn-default btn-sm" > Upload</button> */}

                    <button className="btn btn-default btn-sm abc-click" style={{ marginLeft:"8px"}} onClick={() => downloadDataSpecs("excel")}><i className="fa fa-download"></i> </button>
                    <RequirePermission perms={is.orgAdmin} >
                    <ShowIf if={!editDescriptions  && chosenBucket !== undefined }>
                        <button  className="btn btn-default btn-sm" style={{marginRight:"8px", marginLeft:"8px"}} onClick={() => exitEditDescription()}> Add/Edit  <i className="fa fa-info-circle glyphicon-info" style={{marginLeft:"2px"}}></i></button>
                    </ShowIf>
                    <ShowIf if={editDescriptions}>
                        <button  className="btn btn-sm" style={{marginRight:"8px", marginLeft:"8px", background:"#d9534f", color:"white"}} onClick={() => exitEditDescription()}> Changing Descriptions <i className="fa fa-info-circle glyphicon-info" style={{marginLeft:"2px"}}></i></button>
                    </ShowIf>
                    </RequirePermission>
                    <div className="dataspecs-search" style={ {marginLeft: chosenBucket == undefined ? "8px" : "0px"}}>
                        <div  style={{whiteSpace: "nowrap", display: "flex", alignItems:"center"}}>
                            <span className="vertical-align-middle" style={{marginRight: 8}}>{`Showing ${tmpShownColumns.length} of ${chosenBucket?.info.model.columns.length ?? tmpShownColumns.length}`}</span>
                        </div>
                        <div className="input-group margin-top-0" id="searchBarContainer">
                            <input id="searchIdInput" className={`form-control form-control-sm zero-border-right ${search ? 'alert-warning' : ''} `}
                                placeholder={`Search data specs`}  onChange={(e) => setSearch(e.target.value)} value={search} />
                            <span title="Search beginning" className={`input-group-append ${search ? 'alert-warning' : ''} `}>
                                <button className="btn btn-sm btn-default" type="button">
                                    <i className="fa fa-search"></i>
                                </button>
                            </span>
                        </div>
                    </div>  
                </div>
            </div>
            <div  style={{paddingLeft:"10px", paddingTop:"10px", paddingBottom:"10px", paddingRight:"10px"}}>
                <ShowIf if={tmpShownColumns.length > 0}>
                    <table className="table table-condensed table-bordered  table-striped margin-top-10px" style={{width:"100%"}}>
                        <thead>
                            <tr >
                                <th style={{width:"27%"}} className="abc-click" onClick={() =>{toggleSortOrder()}} > Column 
                                    {getSortIcons()}
                                </th>
                                <th style={{width:"75%"}}> Descriptions <span className='fa fa-info-circle vertical-align-middle' ></span></th>
                                <th style={{width:"10%"}}> Example</th>

                                <ShowIf if={editDescriptions && props.user.is_consultant_login}>
                                    <th title={"Add data column to Inact Now Data"} style={{width:"4%"}}> <img src="./img/inact_ia_logo_dark.svg" alt=""/> </th>
                                </ShowIf>
                            </tr>
                        </thead>
                        <tbody>
                            {    
                                tmpShownColumns.map((col) => {
                                    let example: string = getExample(col)

                                    let isDefault: boolean = false
                                    let defaultVals = ["number", "categorization", "id","word", "date"]
                                    if (defaultVals.includes(example)) {
                                        isDefault = true
                                    } else {
                                        isDefault = false
                                    }
                                    let setupDescription = bucketSetup ? bucketSetup.descriptions[col] != undefined ? bucketSetup.descriptions[col] : "" : ""

                                    return (
                                        <tr key={col}>

                                            <td style={{height:"33px"}}>

                                                {col} 
                                                <ShowIf if={getType(col) == "inact"}>
                                                    <div style={{display:"inline-block", float:"right"}}>
                                                        <img src="./img/inact_ia_logo_dark.svg" alt="" style={{width:"20px"}} />    
                                                    </div>
                                                </ShowIf>
                                            </td>

                                            <td style={{width:"200px"}} >  
                                                <div className="d-flex justify-content-between" >

                                                    <div className="w-full">
                                                        <ShowIf if={getDescriptions(col) == true}>
                                                            <ReactMarkdown>{setupDescription}</ReactMarkdown>
                                                        </ShowIf>   
                                                    </div>
                                                    <ShowIf if={editDescriptions }>
                                                        <ShowIf if={getEditButtons(col) == "add"}>
                                                            <button className="btn btn-default btn-xs edit-button" onClick={() => {setModalEdit(!modalEdit); setChosenColumnName(col); setChosenColumnDescription(setupDescription)}} > Add +</button>  
                                                        </ShowIf>
                                                        <ShowIf if={getEditButtons(col) == "edit"}>
                                                            <button className="btn btn-default btn-xs edit-button margin-left-5px" style={{height: "fit-content"}} onClick={() => {setModalEdit(!modalEdit); setChosenColumnName(col); setChosenColumnDescription(setupDescription)}} > Edit </button>  

                                                        </ShowIf>
                                                    </ShowIf>
                                                </div>

                                            </td>
                                            <td > 
                                                <ShowIf if={getExample(col) != undefined}>
                                                    <span style={{ fontStyle: isDefault==false ? 'normal' : 'italic' }} > {example} </span>
                                                </ShowIf>
                                            </td> 
                                            <td >
                                                <ShowIf if={editDescriptions && props.user.is_consultant_login}>
                                                    <div className='text-align-center'>
                                                        <input 
                                                            className='text-align-center'
                                                            style={{ verticalAlign:"middle"}}  
                                                            type="checkbox" 
                                                            checked={getType(col) == "inact"} 
                                                            onChange={() => {addColToInactNow(col); }}
                                                        />
                                                    </div>
                                                </ShowIf>

                                            </td>
                                        </tr>
                                    )
                                })
                            }
                        </tbody>
                    </table>
                </ShowIf>
                <ShowIf if={tmpShownColumns.length == 0}>
                    <div className="alert alert-warning">
                        There were no matching columns found{otherBuckets.length > 0 ? <> in the current data source, but other datasources contain matching columns.</> : <>.</>}
                    </div>
                </ShowIf>

                <ShowIf if={otherBuckets.length > 0}>
                    <div className="" style={{gap: 5}}>
                        <b>Columns found in other data sources: </b>
                        {
                            otherBuckets.map((b) => {
                                return <div key={b.b.id} className="d-flex gap-5">
                                    <span>{b.b.info.name}: {b.val}</span>
                                    <button className="link-btn" style={{color: "var(--tangerine)"}} 
                                        onClick={() => {
                                            setChosenBucket(b.b)
                                            window.scrollTo({
                                                top: 0,
                                                behavior: "smooth"
                                            })
                                        }}
                                    >Show me</button>

                                </div>
                            })
                        }
                    </div>
                </ShowIf>

            <ShowIf if={modalAdd}>
                    <AddBucketDescription 
                        show={modalAdd}
                        onClose={() => onCloseInfo()}
                        chosenColumnName={chosenColumnName}
                        chosenColumnDescription={chosenColumnDescription}
                        saveDescription={(description) => onSaveInfo(description)}
                    />
            </ShowIf>
            <ShowIf if={modalEdit}>
                    <EditBucketDescription 
                        show={modalEdit}
                        onClose={() => onCloseInfo()}
                        chosenColumnName={chosenColumnName}
                        chosenColumnDescription={chosenColumnDescription}
                        saveDescription={(description) => onSaveInfo(description)}
                    />
            </ShowIf>
            
            </div>
        </div>
    )
}
    
// @ts-ignore
DataSpecs = connect(mapStateToProps)(DataSpecs)

export default DataSpecs

