import * as ActionTypes from "./ActionTypes";
import { packAction } from "./ActionTypes";
import {
    CxmSetup,
    CxmQuery,
    CxmMultipleAggregationQueryResult,
    End2EndSetup,
    CxmGridQuery,
    CxmGridQueryFullResult,
} from "../types/cxmtypes";

export const getE2ETest = () => packAction(ActionTypes.GET_TEST_E2E);
export const gotE2ETest = (data: any) => packAction(ActionTypes.GOT_TEST_E2E, data);

export const getCxmSetups = () => packAction(ActionTypes.GET_CXM_SETUPS);
export const gotCxmSetups = (data: CxmSetup[]) => packAction(ActionTypes.GOT_CXM_SETUPS, data);

export const getE2ESetups = () => packAction(ActionTypes.GET_E2E_SETUPS);
export const gotE2ESetups = (data: End2EndSetup[]) => packAction(ActionTypes.GOT_E2E_SETUPS, data);

export const queryE2E = (query: CxmQuery) => packAction(ActionTypes.QUERY_E2E, query);
export const gotE2EData = (data: CxmMultipleAggregationQueryResult) => packAction(ActionTypes.GOT_E2E_DATA, data);

export const queryE2EGrid = (query: CxmGridQuery) => packAction(ActionTypes.QUERY_E2E_GRID, query);
export const gotE2EGridData = (data: CxmGridQueryFullResult) => packAction(ActionTypes.GOT_E2E_GRID_DATA, data);

export type End2EndAction =
    | GetTestE2EAction
    | GotTestE2EAction
    | GetCxmSetupsAction
    | GotCxmSetupsAction
    | QueryE2EAction
    | GotE2EDataAction
    | GetE2ESetupsAction
    | GotE2ESetupsAction
    | QueryE2EGridAction
    | GotE2EGridDataAction;

export type GetTestE2EAction = {
    type: typeof ActionTypes.GET_TEST_E2E;
};

export type GotTestE2EAction = {
    type: typeof ActionTypes.GOT_TEST_E2E;
    payload: any;
};

export type GetCxmSetupsAction = {
    type: typeof ActionTypes.GET_CXM_SETUPS;
};

export type GotCxmSetupsAction = {
    type: typeof ActionTypes.GOT_CXM_SETUPS;
    payload: CxmSetup[];
};

export type GetE2ESetupsAction = {
    type: typeof ActionTypes.GET_E2E_SETUPS;
};

export type GotE2ESetupsAction = {
    type: typeof ActionTypes.GOT_E2E_SETUPS;
    payload: End2EndSetup[];
};

export type QueryE2EAction = {
    type: typeof ActionTypes.QUERY_E2E;
    payload: CxmQuery;
};

export type GotE2EDataAction = {
    type: typeof ActionTypes.GOT_E2E_DATA;
    payload: CxmMultipleAggregationQueryResult[];
};

export type QueryE2EGridAction = {
    type: typeof ActionTypes.QUERY_E2E_GRID;
    payload: CxmGridQuery;
};

export type GotE2EGridDataAction = {
    type: typeof ActionTypes.GOT_E2E_GRID_DATA;
    payload: CxmGridQueryFullResult[];
};
