import 'babel-polyfill'
import React from 'react'
import { Provider } from 'react-redux'
// @ts-ignore
import ReactDOM from 'react-dom'

import { createStore } from 'redux'

import AppStore from './reducers'
import Middleware from './middleware'

import './index.css'
import App from './App'
import './css/system.css'
import { loggedIn } from './actions/UserActions'
import { getToken, setStore } from './helpers/ApiHelpers'
import './helpers/Prototype'

import './css/Fork-Awesome-1.1.7/css/fork-awesome.min.css'
import './scss/custom.scss'

// @ts-ignore
window.jQuery = window.$ = require('jquery')
// require('bootstrap/dist/css/bootstrap.min.css')
require('bootstrap/dist/js/bootstrap.min.js')

export let store = createStore(
    AppStore,
    Middleware
)
export type RootState = ReturnType<typeof AppStore>
export type AppDispatch = typeof store.dispatch
export type Store = typeof store

setStore(store)

let token = getToken()
if (null !== token) {
    store.dispatch(loggedIn(token))
}

ReactDOM.render(
    <Provider store={store}>
        <App />
    </Provider>,
    document.getElementById('app')
)
