import React from 'react'
import { User } from '../types/transfertypes'
import {SortOption} from "../types/types"
import { fromNow, isToday } from './TimeHelpers'
import { OrgUser } from '../types/organizationtypes'

export const getUser = (users: User[], id: number):User => {
    return users.filter(u => u.id === id)[0]
}

export const getFullName = (users: User[]|OrgUser[], id: number):string => {
    let user = users.find(u => u.id === id)
    if(user) {
        return user.firstname + " " + user.lastname
    }
    return 'Unknown'
}

export const getInitials = (users: User[], id: number):string => {
    return getFullName(users, id).split(' ').map(function(item){return item[0];}).join('');
}

export const getUserOptionJSX = (users: User[], predicate: ((u:User)=>boolean) = u => true, sort: SortOption | null = null):JSX.IntrinsicElements.option[] => {
    if(users === undefined) return null
    if (!!sort) {
        switch(sort.sortBy.toString().toLowerCase()) {
            case "firstname":
                users = sortUsersAlphabetically([...users], "firstname", sort.order.toString().toLowerCase())
                break;
        }
    }
    return users.filter(u => predicate(u)).map((u,i) => <option key={i} value={u.id}>{u.firstname+" "+u.lastname}</option>)
}

const symbolRegex = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/
const numberRegex = /[0-9]/
const letterRegex = /[a-zA-Z]/

export const checkPasswordPolicyViolation = (policy, password) => {
    const violations = []
    if(!policy || !policy.length) return violations
    if(password.length < policy.length) violations.push("length")
    var attributes = []
    if(symbolRegex.test(password)) attributes.push("symbols")
    if(numberRegex.test(password)) attributes.push("numbers")
    if(letterRegex.test(password)) attributes.push("letters")
    var optionsViolated = !policy.options.some(o => o.every(p => attributes.includes(p)))
    if(optionsViolated) violations.push("attributes")
    return violations
}

export const getPolicyOptionRequirements = (option) => {
    if(!option || option.length === 0) return ""
    if(option.length === 1) return `one ${option[0]}`
    if(option.length === 2) return `${option[0]}+${option[1]}`
    return `${option.slice(0,option.length-1).join(", ")} and ${option[option.length-1]}`
}

const sortUsersAlphabetically = function (list: User[], sortBy: string, order: string): User[] {
    return  list.sort((a, b) => {

        if(order === "asc") {
            let fa = a[sortBy].toLowerCase();
            let fb = b[sortBy].toLowerCase();
    
            if (fa < fb) {
                return -1;
            }
    
            if (fa > fb) {
                return 1;
            }
    
        } else if (order === "desc") {
            let fa = a[sortBy].toLowerCase();
            let fb = b[sortBy].toLowerCase();
    
            if (fa < fb) {
                return 1;
            }
    
            if (fa > fb) {
                return -1;
            }
        }
        
        return 0;
    })
}

export const isNewUser = (u:any) => {
    if(!u) return false;
    const today = new Date();
    const created_at = new Date(u.created_at*1000);
    const monthInMilliSeconds = 30 * 24 * 3600 * 1000;
    return today.getTime() - created_at.getTime() < monthInMilliSeconds && !isUnactivatedUser(u) && !isReportingServiceUser(u);
}

export const isReportingServiceUser = (u:any) => {
    if(!u) return false;
    if(u.permissions){
        return u.permissions.length === 0;
    }
    return true;
}

export const isUnactivatedUser = (u:any) => {
    if(!u) return false;
    return !u.received_welcome_mail && !isReportingServiceUser(u) && u.last_login === 0
}

export const getLastLogin = (u:any) => {
    if(!u) return "";
    if(u.last_login === 0) return "-";
    if(isToday(u.last_login)) return "Today";
    return fromNow(u.last_login);
}
