import type { Checklist, Column, ColumnHistoryMonth, DashboardReport, DashboardTarget } from "./transfertypes"
import { Order, SortBy } from "./enums"
import { AggregationType } from "../helpers/TypeHelpers"

export interface GraphDataProps {
    kpi1: kpiContainer
    kpi2: kpiContainer
    showTotal: boolean
    historydata: number[][]
    kpis: kpi[]
    selectedCategory: string
    graphOptions: { zoom: number, axisNum: number, startAtZero: boolean }
    lastAdded: number
    setHighlightedPeriod: Function
}

export interface GraphDataset {
    label: string
    type: string
    data: number[]
    lineTension: number
    borderColor: string
    borderWidth: any
    backgroundColor: string | string[]
    hoverBackgroundColor: string | string[]
    pointRadius: number
    pointBackgroundColor: string
    pointBorderColor: string
    pointHitRadius: number
    pointHoverRadius: number
    pointHoverBackgroundColor: string
    yAxisID: string
    barPercentage: number
    categoryPercentage: number,
    haveChanged?: boolean,
}

export type Permission = string

export interface DecoratedColumnHistoryMonth extends ColumnHistoryMonth {
    endLabel: string
    width: number
}

// little unsure of the structure here -> just making errors going away for now
export interface kpi {
    settings?: {
        type: string
    }
    aggregation: string,
    column: any, // can both be an object and a string, for some reason 🤦‍♂️
    showKPIDecimals?: boolean
    showPercentage: boolean
    trend?: boolean
}

export interface kpiContainer {
    kpi: kpi
    color: string
    index: number
    settings: {
        type: string
    }
}
export interface ErrorInfo {
    message?: string
    method?: string
    status?: number
    url?: string
    data?: { [key: string]: any }
    name?: string
    report_settings?: { [key: string]: any }
    stack_trace?: string
}

export interface DecoratedDashboardTarget extends DashboardTarget {
    simple?: boolean
    trend?: number
}

export interface DecoratedDashboardReport extends DashboardReport {
    mainReport?: boolean 
}

export interface historyData {
    label: string
    value: number
    found: boolean
}
export interface DecoratedChecklist extends Checklist {
    overdue?: boolean
    not_started?: boolean
    started?: boolean
}

export interface DecoratedChecklistStatus {
    id: number
    count: {[key:string]:number}
    list: DecoratedChecklist
    missing_columns: MissingColumn[]
}

export interface MissingColumn {
    name: string
    column_type: number
    in_filter: boolean
}

export interface IndexedColumn extends Column {
    index: number
}
export interface TotalSection {
    x: number
    y: number
}

export interface SortOption {
    sortBy: SortBy,
    order: Order,
}


export interface ReportTemplateGroup {
    id: number,
    name: string,
    report_templates: ReportTemplate[]
}

export interface ReportTemplate {
    id: number,
    bucket_id: number,
    group_id: number,
    name: string,
    description: string,
    columns: string[],
    shown_columns: string[],
    kpis: ReportTemplateKPI[],
    view_settings: ReportViewSettings
    fixed_slicers: string[],
    filters: ReportFilter[],
    limit: number,
    sort_column: string,
    sort_direction: "" | "desc" | "asc",

    // The name of the default group, if empty will use unsorted
    default_group: string
}

export interface ReportTemplateKPI {
    column: string,
    aggregation: AggregationType,
    showKPIDecimals: boolean,
    showPercentage: false,
    trend: false
}

export interface ReportViewSettings {
    default_view: "overview" | "grid" | "kpis" | "sum" | "trend" | "profit" | "",
    overview: boolean,
    grid: boolean,
    kpis: boolean,
    sum: boolean,
    trend: boolean,
    profit: boolean,
}

export interface ReportFilter {
    column: string,
    compare_type: CompareType,
    target_values: any[],
}

export enum CompareType {
    EQ = 1,
    NEQ = 6,
    CONTAINS = 9,
    NCONTAINS = 12,
    STARTS = 7,
    ENDS = 8,
    NSTARTS = 10,
    NENDS = 11,

    GT = 4,
    GEQ = 3,
    LT = 5,
    LEQ = 2,
}

export interface ReportTemplateBucket {name: string, id: string, bucket_template_id: number}

export interface ReportTemplateGroupWrapper {
    groups: ReportTemplateGroup[],
    buckets: ReportTemplateBucket[],
    enabled_groups: number[],
    enabled: boolean,
    fetched: boolean
}

export interface ActionTemplateGroup {
    id: number,
    name: string,
    action_templates: ActionTemplate[]
}

export interface ActionTemplate {
    id: number,
    bucket_id: number,
    group_id: number,
    name: string,
    description: string,
    columns: string[],
    shown_columns: string[],
    filters: ReportFilter[],
    limit: number,
    offset: number,
    sort_column: string,
    sort_direction: "" | "desc" | "asc",
    layout: ActionTemplateCategory[],
    column_filter: string[];
    selected_kpi: string
}

export type ActionCategoryColor = "btn-primary" | "btn-dark" | "btn-danger" | "btn-warning" | "btn-info" | "btn-success"
export const actionCategoryColors: ActionCategoryColor[] = ["btn-primary", "btn-dark", "btn-danger", "btn-warning", "btn-info", "btn-success"]
export interface ActionTemplateCategory{
    name: string;
    color: ActionCategoryColor
    donecount?: boolean;
}

export interface ActionTemplateBucket {name: string, id: string, bucket_template_id: number}

export interface ActionTemplateGroupWrapper {
    groups: ActionTemplateGroup[],
    buckets: ActionTemplateBucket[],
    enabled_groups: number[],
    enabled: boolean,
    fetched: boolean
}

export interface OrganizationServices {
	action_templates: boolean 
	report_templates: boolean 
	end2end: boolean 
}

export function getDefaultServices(): OrganizationServices  {
    return {
        action_templates: false,
        report_templates: false,
        end2end: false
    }
}
